import "react-phone-number-input/style.css";
import FollowingList from "components/Items/FollowingList";
import React from "react";
import { Row, Col, CardBody, Media } from "reactstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Avatar from "react-avatar";

const EoDiikuti = ({ FOLLOWING }) => {
  const navigation = useHistory();

  console.log("FOllowing : ", FOLLOWING);

  return (
    <div
      style={{
        padding: "10px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <button
        outline
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          flexDirection: "row",
          fontSize: "13px",
          gap: "5px",
          color: "black",
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: "10px",
        }}
        onClick={() => navigation.push("/profil")}
      >
        <MdKeyboardArrowLeft size={20} />
        Event Organizer Diikuti
      </button>
      <Row
        className="mb-3"
        style={{
          backgroundColor: "#9870C5",
          borderRadius: "10px",
          padding: "5px 10px",
        }}
      >
        <Col>
          {FOLLOWING.length > 0 ? (
            <h1
              style={{
                fontSize: "12px",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {FOLLOWING.length}
              <br />
              <span
                style={{
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                Event Organizer Diikuti
              </span>
            </h1>
          ) : (
            <h1
              style={{
                fontSize: "12px",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                Belum ada Event Organizer yang Diikuti
              </span>
            </h1>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        {FOLLOWING.map((item) => (
          <Col key={item._id}>
            <Row className="d-flex align-items-center">
              <Avatar
                size="60"
                src={item.eventOrganizerId?.logo?.url}
                name={item.eventOrganizerId?.name}
                round={true}
              />
              <Col>
                <Media>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#6468A1",
                    }}
                  >
                    {item.eventOrganizerId?.name}
                  </span>
                </Media>
                <button
                  onClick={() =>
                    navigation.push(
                      "/event-organizer/" + item.eventOrganizerId?.slug
                    )
                  }
                  className="btn btn-transparent"
                  style={{
                    color: "#C4C4C4",
                    border: "none",
                    outline: "none",
                    textAlign: "start",
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}
                >
                  Lihat Profil
                </button>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EoDiikuti);
