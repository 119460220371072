import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
import API from "utils/API";

const AllEvent = () => {
    const [eventTypes, setEventTypes] = useState([])
    const [gambar] = useState([
        { name: 'webinar', color: 'bg-warning', image: require("../../assets/img/theme/event_webinar.jpg") },
        { name: 'seminar', color: 'bg-danger', image: require("../../assets/img/theme/event_seminar.jpg") },
        { name: 'lomba', color: 'bg-info', image: require("../../assets/img/theme/event_lomba.jpg") },
        { name: 'konser', color: 'bg-success', image: require("../../assets/img/theme/event_konser.jpg") },
        { name: 'wisata', color: 'bg-purple', image: require("../../assets/img/theme/event_wisata.jpg") },
        { name: '"olahraga"', color: 'bg-blue', image: require("../../assets/img/theme/event_olahraga.jpg") },
        { name: '"party"', color: 'bg-blue', image: require("../../assets/img/theme/event_party.jpg") },
    ])

    const navigate = useHistory()

    useEffect(() => {
        getEventTypes()
    }, [])

    const getEventTypes = () => {
        API.get('eventTypes/getEventTypes', {})
            .then(res => {
                // console.log(res.data.data)
                setEventTypes(res.data.data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const getImage = (data) => {
        const dataImage = gambar.find(element => element.name.includes(data.slug))

        return dataImage ? { image: dataImage.image, color: dataImage.color } : { image: gambar[0].image, color: gambar[0].color }
    }

    return (
        <Container className="mt--5" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardBody className="px-lg-4 px-sm-2 pt-lg-4 pb-2 pb-lg-5">
                            <h2 className="text-center color-hievent">Semua Event</h2>
                            <Row className="justify-content-center px-4">
                                {eventTypes.map((element, index) =>
                                    index < 6 &&
                                    <Col onClick={() => navigate.push("/events/kategori=" + element.slug)}
                                        key={element._id} className={index > 3 ? "d-none d-lg-block mb-2 mb-xl-0 mt-2 p-1" : "mb-2 mb-xl-0 mt-2 p-1"} xl="2" lg="2" sm="4">
                                        <Card style={{ cursor: 'pointer' }} className="shadow p-0 m-0">
                                            <CardBody className="p-0 d-flex justify-content-center">
                                                <img src={getImage(element).image} style={{ height: 280, width: '100%', objectFit: 'cover' }} />
                                                <div className="pt-2 bg-dark pb-1 position-absolute bottom-0 left-0 right-0 mb-0" style={{ opacity: 0.8 }}>
                                                    <h3 className="text-white text-center">{element.name}</h3>
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* <Card style={{ cursor: 'pointer' }} className="bg-gradient-primary shadow py-5">
                                            <CardBody>
                                                <h2 className="text-white text-center">{element.name}</h2>
                                            </CardBody>
                                        </Card> */}
                                    </Col>
                                )}

                                {/* <Col className="mb-3 mb-xl-0" xl="2" lg="3" sm="5">
                                    <Card className="bg-gradient-primary shadow py-5">
                                        <CardBody>
                                            <h2 className="text-white text-center">Webinar</h2>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="mb-3 mb-xl-0" xl="2" lg="3" sm="5">
                                    <Card className="bg-gradient-warning shadow py-5">
                                        <CardBody>
                                            <h2 className="text-white text-center">Seminar</h2>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="d-none d-lg-block mb-3 mb-xl-0" xl="2" lg="3" sm="5">
                                    <Card className="bg-gradient-success shadow py-5">
                                        <CardBody>
                                            <h2 className="text-white text-center">Lomba</h2>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="d-none d-lg-block mb-3 mb-xl-0" xl="2" lg="3" sm="5">
                                    <Card className="bg-gradient-indigo shadow py-5">
                                        <CardBody>
                                            <h2 className="text-white text-center">Relawan</h2>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="d-none d-lg-block mb-3 mb-xl-0" xl="2" lg="3" sm="5">
                                    <Card className="bg-gradient-teal shadow py-5">
                                        <CardBody>
                                            <h2 className="text-white text-center">Offline</h2>
                                        </CardBody>
                                    </Card>
                                </Col> */}
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    );
};

export default AllEvent;
