import FAQ from "components/Partials/FAQ";
import React from "react";

const Index = () => {
  return (
    <>
      <FAQ />
    </>
  );
};

export default Index;
