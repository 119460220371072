const WalletIcon = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.25 7.5H18C15.5147 7.5 13.5 9.51472 13.5 12C13.5 14.4853 15.5147 16.5 18 16.5H29.25M18 12V12.015M3.91667 1.5H26.0833C27.8322 1.5 29.25 3.067 29.25 5V19C29.25 20.933 27.8322 22.5 26.0833 22.5H3.91667C2.16776 22.5 0.75 20.933 0.75 19V5C0.75 3.067 2.16777 1.5 3.91667 1.5Z"
        stroke="#9870C5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
