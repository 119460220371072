import SuccessImage from "components/Partials/SuccessImage";
import { showToast } from "components/Partials/Toast";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  Progress,
  Spinner
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const StepI = ({ props, EO, SET_EO }) => {
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const updateLogo = (logo) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('logo', logo[0])

    API.put('eventOrganizers/editLogoEventOrganizer', formData, {
      headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
    }).then(res => {
      // console.log(res.data)
      setLoading(false)
      SET_EO(res.data.data)
      showToast(res.data.message, res.data.status.toLowerCase())
    }).catch(err => {
      // console.log(err.response)
      setLoading(false)
      showToast(err.response.data.message, err.response.data.status.toLowerCase())
    })
  }

  const onChangeImage = (event) => {
    if (event.target.files.length === 1)
      if (EO._id)
        updateLogo(event.target.files)
      else
        props.setLogo(event.target.files)
  }

  return (
    <>
      <Row>
        <Col md="12">
          <label className="text-sm">Logo Event Organizer</label>
          <Row>
            <Col md="4">
              <img
                className="img-fluid w-100 h-100 rounded-lg logo-eo"
                src={typeof props.logo === 'object' ? URL.createObjectURL(props.logo[0]) : props.logo}
                alt="hievents"
              />
            </Col>

            <Col md="4">
              <div className="d-flex flex-column p-3">
                <span className="font-weight-bold">Besar file maksimum</span>
                <span>10MB</span>

                <span className="font-weight-bold mt-2">Type file</span>
                <span>PNG, JPG, JPEG</span>
              </div>

              <label htmlFor="browse-logo" className="btn btn-sm p-2 btn-primary mt-4">{loading ? <Spinner size="sm" /> : null} Browse File</label>
              <input hidden type="file" accept="image/png, image/jpeg" id="browse-logo" className="form-control" onChange={(event) => onChangeImage(event)} />
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-4">
          <FormGroup>
            <label className="text-sm">Nama Event Organizer<label className="text-sm text-danger">*</label></label>
            <Input
              placeholder="Nama Event Organizer"
              type="text"
              value={props.name} onChange={(event) => props.setName(event.target.value)} />
          </FormGroup>
        </Col>

        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">Tentang Event Organizer<label className="text-sm text-danger">*</label></label>
            <Input
              placeholder="Tentang Event Organizer"
              rows={6}
              type="textarea"
              value={props.about} onChange={(event) => props.setAbout(event.target.value)} />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

const StepII = ({ props }) => {
  return (
    <>
      <label className="text-md font-weight-bold">Media Sosial Event Organizer</label>
      <Row>
        <Col md="12" className="mt-3">
          <FormGroup>
            <label className="text-sm">Whatsapp</label>
            <Input
              placeholder="Whatsapp"
              type="number"
              value={props.whatsapp} onChange={(event) => props.setWhatsapp(event.target.value)} />
          </FormGroup>
        </Col>
        <Col md="12" className="mt-3 mt--3">
          <FormGroup>
            <label className="text-sm">Instagram</label>
            <Input
              placeholder="Instagram"
              type="text"
              value={props.instagram} onChange={(event) => props.setInstagram(event.target.value)} />
          </FormGroup>
        </Col>
        <Col md="12" className="mt-3 mt--3">
          <FormGroup>
            <label className="text-sm">Youtube</label>
            <Input
              placeholder="Youtube"
              type="text"
              value={props.youtube} onChange={(event) => props.setYoutube(event.target.value)} />
          </FormGroup>
        </Col>
        <Col md="12" className="mt-3 mt--3">
          <FormGroup>
            <label className="text-sm">Tiktok</label>
            <Input
              placeholder="Tiktok"
              type="text"
              value={props.tiktok} onChange={(event) => props.setTiktok(event.target.value)} />
          </FormGroup>
        </Col>

      </Row>
    </>
  )
}

const Register = ({ USER, EO, SET_EO }) => {
  const [name, setName] = useState('')
  const [logo, setLogo] = useState(require("../../assets/img/theme/upload.svg").default)
  const [about, setAbout] = useState('')

  const [whatsapp, setWhatsapp] = useState('')
  const [instagram, setInstagram] = useState('')
  const [youtube, setYoutube] = useState('')
  const [tiktok, setTiktok] = useState('')

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)

  const useAuth = UseAuth()
  const navigation = useHistory()

  useEffect(() => {
    if (EO._id) {
      setName(EO.name)
      setLogo(EO.logo?.url)
      setAbout(EO.about)
      setWhatsapp(EO.whatsapp)
      setInstagram(EO.instagram)
      setYoutube(EO.youtube)
      setTiktok(EO.tiktok)
    }
  }, [USER, EO])

  const onRegister = async () => {
    if (!USER._id)
      return navigation.replace('/auth')

    setLoading(true)
    if (EO._id) {
      const formData = {
        name, about, whatsapp, instagram, youtube, tiktok
      }

      API.put('eventOrganizers/editEventOrganizer', formData, {
        headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
      }).then(res => {
        // console.log(res.data)
        setLoading(false)
        SET_EO(res.data.data)
        showToast(res.data.message, res.data.status.toLowerCase())
      }).catch(err => {
        // console.log(err.response)
        setLoading(false)
        showToast(err.response.data.message, err.response.data.status.toLowerCase())
      })
    } else {
      var formData = new FormData()
      formData.append('name', name)
      if (typeof logo[0] === 'object')
        formData.append('logo', logo[0])
      formData.append('about', about)

      formData.append('whatsapp', whatsapp)
      formData.append('instagram', instagram)
      formData.append('youtube', youtube)
      formData.append('tiktok', tiktok)

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      API.post('eventOrganizers/createEventOrganizer', formData, {
        headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
      }).then(res => {
        // console.log(res.data)
        setLoading(false)
        SET_EO(res.data.data)
        setStep(3)
        showToast(res.data.message, res.data.status.toLowerCase())
      }).catch(err => {
        // console.log(err.response)
        setLoading(false)
        showToast(err.response.data.message, err.response.data.status.toLowerCase())
      })
    }
  }

  const onNext = () => {
    if (step === 1)
      if(name === '')
        return showToast('Silakan masukkan nama event organizer kamu', 'error')
      else if(about === '')
        return showToast('Silakan masukkan tentang event organizer kamu', 'error')
      else
        setStep(2)
    else
      onRegister()
  }

  if (!USER._id)
      return <Redirect from="*" to="/auth" />

  if (EO._id && window.location.pathname.includes("register") && step === 1)
    return <Redirect from="*" to="/edit-event-organizer" />

  return (
    <Container className="pt-8 px-lg-8">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          {step === 3 ?
            <div className="text-center text-muted mb-4">
              <h1>Selamat</h1>
              <div>Kamu sudah bergabung menjadi event organizer di HiEvents</div>
            </div>
            :
            <div className="text-center text-muted mb-4">
              <h1>{EO._id ? 'Ubah Event Organizer' : 'Daftar Event Organizer'}</h1>
              <div>Silakan {EO._id ? 'ubah' : 'buat'} event organizer kamu</div>
            </div>
          }

          <Progress
            className="mb-4"
            value={step === 1 ? 50 : 100}
          />

          <Form role="form">

            {step === 1 ?
              <StepI props={{ name, setName, logo, setLogo, about, setAbout }} EO={EO} SET_EO={SET_EO} />
              : step === 2 ?
                <StepII props={{ whatsapp, setWhatsapp, instagram, setInstagram, youtube, setYoutube, tiktok, setTiktok }} EO={EO} />
                : <SuccessImage />
            }

            {/* <Alert props={{ message, setMessage }} /> */}

            {step === 3 ?
              <div className="text-center">
                <Button
                  onClick={() => navigation.replace('/event-organizer')}
                  className="m-0 mt-4 w-50 text-white bg-hievent" color="bg-hievent" type="button">
                  Kunjungi Profil
                </Button>

                <Button
                  onClick={() => navigation.replace('/buat-event')}
                  className="mt-4 w-50 text-white bg-hievent" color="bg-hievent" type="button">
                  Buat Event
                </Button>
              </div>
              :
              <div className="text-center">
                <div className="mt-4 d-flex flex-row">
                  {step === 2 ?
                    <Button
                      onClick={() => setStep(1)}
                      className="w-100 text-white bg-hievent" color="bg-hievent" type="button">
                      Kembali
                    </Button>
                    : null}
                  <Button
                    disabled={loading}
                    onClick={() => onNext()}
                    className="w-100 text-white bg-hievent" color="bg-hievent" type="button">
                    {loading ? <Spinner size="sm" /> : null}
                    {' '}{step === 1 ? "Berikutnya" : EO._id ? "Simpan" : "Register"}
                  </Button>
                </div>
              </div>
            }

          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: 'SET_USER', value: value }),
    SET_EO: (value) => dispatch({ type: 'SET_EO', value: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
