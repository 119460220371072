import Avatar from "react-avatar"
import { Media } from "reactstrap"

const FollowEo = ({ item }) => {
    return (
        <Media className="align-items-center mt-1">
            <Avatar size="55" src={item.eventOrganizerId?.logo?.url} name={item.eventOrganizerId?.name} round={true} />
            <Media className="ml-2">
                <span className="mb-0 text-sm font-weight-bold">
                    {item.eventOrganizerId?.name}
                </span>
            </Media>
        </Media>
    )
}

export default FollowEo