import { showToast } from "components/Partials/Toast";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Button,
    Input,
    Modal
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const ReportEvent = ({ eventId }) => {
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const useAuth = UseAuth();
    const navigation = useHistory();

    const createReport = () => {
        if(!useAuth.auth){
            // return showToast("Silahkan masuk aplikasi", "error")
            return navigation.push('/auth')
        }
        
        const data = {
            eventId, message
        }
        API.post('reportEvents/createReportEvent', data, {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data.data)
            showToast(res.data.message, res.data.status.toLowerCase())
            setIsOpen(false);
        }).catch(err => {
            // console.log(err.response.data)
            showToast(err.response.data.message, err.response.data.status.toLowerCase())
        })
    }

    return (
        <>
            <span onClick={() => setIsOpen(!isOpen)} className="text-danger" style={{ cursor: 'pointer' }}><i className="fa fa-exclamation-circle fa-lg ml-1"/> Report</span>
            <Modal
                className="modal-dialog-centered modal-info"
                contentClassName="bg-gradient-purple"
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <div className="modal-header">
                    <h6 className="modal-title">
                        Your attention is required
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="fa fa-exclamation-triangle fa-2x" />
                        {/* <h4 className="heading mt-4">Masukkan kode event yang kamu punya</h4> */}
                        <p>Mengapa kamu melaporkan event ini ?</p>
                        <div className="d-flex flex-row">
                            <Input
                                type="textarea"
                                row={2}
                                value={message}
                                onChange={(event) => setMessage(event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        onClick={() => createReport()}
                        className="btn-white" color="default" type="button">
                        Report
                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ReportEvent;
