import SuccessImage from "components/Partials/SuccessImage";
import { showToast } from "components/Partials/Toast";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Spinner,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import API from "utils/API";

const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})

    const code = new URLSearchParams(useLocation().search).get("code")

    const onReset = () => {
        if (password !== confirmPassword) {
            showToast("Password doesn't match", 'error')
            return
        }

        setLoading(true)
        const data = {
            password, confirmPassword, code
        }

        API.post('auths/resetPassword', data)
            .then(res => {
                // console.log(res)
                setLoading(false)
                setMessage(res.data)
            })
            .catch(err => {
                console.log(err.response)
                setLoading(false)
                showToast(err.response.data.message, 'error')
                // setMessage(err.response.data)
            })
    }

    return (
        <>
            <Col xl="9" lg="10" md="11">
                <Card className="bg-secondary shadow border-0 my-0 my-lg-6">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <h1>Reset Password</h1>
                            <div>Masukkan Password baru kamu</div>
                        </div>

                        {!message.code ?
                            <>
                                <Form role="form">
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Password"
                                                type="password"
                                                autoComplete="new-password"
                                                value={password} onChange={(event) => setPassword(event.target.value)} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Konfirmasi Password"
                                                type="password"
                                                autoComplete="new-password"
                                                value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                                <Button
                                    onClick={() => onReset()}
                                    className="mt-4 w-100 text-white bg-hievent" color="bg-hievent" type="button">
                                    {loading ? <Spinner size="sm" /> : null} Reset Password
                                </Button>
                            </>
                            : null}

                        {message.code === 200 ?
                            <div className="d-flex flex-column align-items-center">
                                <SuccessImage />
                                <h3 className="text-primary">{message.message}</h3>
                            </div>
                            : null}

                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <Link to="/auth/login">
                            <small>Masuk ke akun</small>
                        </Link>
                    </Col>
                    <Col className="text-right" xs="6">
                        <Link to="/auth/register">
                            <small>Daftar akun baru</small>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default ResetPassword;
