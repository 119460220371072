import Notifikasi from "components/Items/Notifikasi";
import { useLocation } from 'react-router-dom';
import {useHistory } from "react-router-dom";
import {
    Container,
} from "reactstrap";

const Index = () => {
    const location = useLocation();
    const navigation = useHistory();
    const {notifications} = location.state || {};
    return (
        <Container className="pt-5 px-4 pt-lg-8 px-lg-7">
            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                <div style={{cursor: "pointer"}} onClick={() => navigation.push('/')} className="d-flex flex-row align-items-center">
                    <i className="fa fa-arrow-left mr-2"/>
                    <span className="font-weight-bold color-hievent">Kembali</span>
                </div>

                <div className="d-flex flex-row align-items-center">
                    <label className="custom-toggle m-0 mr-2">
                        <input type="checkbox" />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                    <span className="font-weight-bold">Baca semua</span>
                </div>
            </div>

            <div>
                {notifications ? (
                    notifications.map((item, i) => {
                        return <Notifikasi item={item} key={i}/>
                    })
                ) : (
                    <div className="d-flex flex-column">
                        <span>Not Found Notifications</span>
                    </div>
                )}
            </div>

            {/* <Notifikasi />
            <Notifikasi />
            <Notifikasi />
            <Notifikasi /> */}

        </Container>
    );
};

export default Index;
