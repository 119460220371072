import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { MdLogin } from "react-icons/md";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import { FaRegBookmark } from "react-icons/fa6";
import { IoMdNotificationsOutline } from "react-icons/io";
import Notifikasi from "components/Dropdowns/Notifikasi";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const UserNavbarMobile = ({ USER, SET_USER }) => {
  // console.log("USER NAVBAR : ", USER);
  const useAuth = UseAuth();
  const [notifications, setNotifications] = useState([]);
  const [isShowNotifications, setIsShowNotifications] = useState(false);

  useEffect(() => {
    if (useAuth.auth) {
      getProfile();
      getNotifications();
    }
  }, [isShowNotifications]);

  const getNotifications = () => {
    API.get("/notifications/getNotifications", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        console.log("Notifikasi : ", res.data.data);
        setNotifications(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProfile = () => {
    API.get("users/getProfile", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    }).then((res) => {
      // console.log(res.data.data);
      SET_USER(res.data.data);
    });
    // .catch(err => {
    //   console.log(err.response.data)
    // })
  };

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        width: "100%",
        padding: "10px 20px",
      }}
    >
      <div
        className="h4 color-hievent"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "3px",
          fontWeight: "bold",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_1899_14707)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.60417 0H5.81279C6.58474 1.20279 8.16989 2.02765 10 2.02765C11.8301 2.02765 13.4153 1.20279 14.1872 0H17.3958C18.8341 0 20 1.0316 20 2.30415V17.6959C20 18.9684 18.8341 20 17.3958 20H14.0604C13.2499 18.8981 11.735 18.1567 10 18.1567C8.26497 18.1567 6.75009 18.8981 5.9396 20H2.60417C1.16593 20 0 18.9684 0 17.6959V2.30415C0 1.0316 1.16593 0 2.60417 0Z"
              fill="#9870C5"
            />
            <path
              d="M6.09391 9.58518C7.10068 9.58518 7.91683 8.86306 7.91683 7.97228C7.91683 7.0815 7.10068 6.35938 6.09391 6.35938C5.08714 6.35938 4.271 7.0815 4.271 7.97228C4.271 8.86306 5.08714 9.58518 6.09391 9.58518Z"
              fill="white"
            />
            <path
              d="M13.9064 9.58518C14.9132 9.58518 15.7293 8.86306 15.7293 7.97228C15.7293 7.0815 14.9132 6.35938 13.9064 6.35938C12.8996 6.35938 12.0835 7.0815 12.0835 7.97228C12.0835 8.86306 12.8996 9.58518 13.9064 9.58518Z"
              fill="white"
            />
            <path
              d="M13.125 11.6592C13.125 11.9315 13.0442 12.2012 12.8871 12.4527C12.7301 12.7043 12.4999 12.933 12.2097 13.1255C11.9195 13.3181 11.575 13.4709 11.1959 13.575C10.8168 13.6793 10.4104 13.7329 10 13.7329C9.58961 13.7329 9.18326 13.6793 8.80411 13.575C8.42497 13.4709 8.08048 13.3181 7.79029 13.1255C7.5001 12.933 7.26993 12.7043 7.11287 12.4527C6.95583 12.2012 6.875 11.9315 6.875 11.6592H10H13.125Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1899_14707">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        HiEvents
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "15px",
        }}
      >
        <UncontrolledDropdown nav className="mt--1">
          <DropdownToggle className="pr-0" nav onClick={() => setIsShowNotifications(!isShowNotifications)}>
            <Media className="color-hievent align-items-center">
              <i className="ni ni-bell-55" />
            </Media>
          </DropdownToggle>
          <Notifikasi notifications={notifications} />
        </UncontrolledDropdown>
        <Link to="/event-disimpan">
          <FaRegBookmark size={20} color="#9870C5" />
        </Link>

        {/* <Link to="/notifikasi">
          <IoMdNotificationsOutline size={20} color="#9870C5" />
        </Link> */}
        {!useAuth.auth && (
          <Link
            to="/auth/login"
            className="bg-hievent"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: 5,
              color: "white",
              width: USER.name ? 0 : "100%",
              backgroundColor: "#9870C5",
              height: 40,
              borderRadius: 5,
              visibility: USER.name ? "hidden" : "visible",
              padding: "0px 10px",
            }}
          >
            <MdLogin size={20} />
            Masuk / Daftar
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbarMobile);
