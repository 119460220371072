import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { UseAuth } from "routes/useAuth";

const HeaderMobile = () => {
  const useAuth = UseAuth();

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 20px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "20px",
      }}
    >
      <Link
        to={useAuth.auth ? "/ruang-sertifikat" : "/auth/login"}
        outline
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "4px",
          color: "#9870C5",
          border: "2px solid #9870C5",
          width: "100%",
          height: "40px",
          borderRadius: "5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.41667 16.6665H4.16667C3.24619 16.6665 2.5 15.9203 2.5 14.9998V3.33317C2.5 2.4127 3.24619 1.6665 4.16667 1.6665H15.8333C16.7538 1.6665 17.5 2.4127 17.5 3.33317V14.9998C17.5 15.9203 16.7538 16.6665 15.8333 16.6665H14.5833M10 15.8332C11.3807 15.8332 12.5 14.7139 12.5 13.3332C12.5 11.9525 11.3807 10.8332 10 10.8332C8.61929 10.8332 7.5 11.9525 7.5 13.3332C7.5 14.7139 8.61929 15.8332 10 15.8332ZM10 15.8332L10.0179 15.833L7.35723 18.4936L5.0002 16.1366L7.51638 13.6204M10 15.8332L12.6607 18.4936L15.0177 16.1366L12.5015 13.6204M7.5 4.99984H12.5M5.83333 7.9165H14.1667"
            stroke="#9870C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Ruang Sertifikat
      </Link>
      <Link
        to={useAuth.auth ? "/event-tertutup" : "/auth/login"}
        className="bg-hievent"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "5px",
          color: "white",
          width: "100%",
          backgroundColor: "#9870C5",
          height: "40px",
          borderRadius: "5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM5 8H19V6H5V8Z"
            fill="white"
          />
        </svg>
        Event Tertutup
      </Link>
    </div>
  );
};

export default HeaderMobile;
