import { useHistory } from "react-router-dom"
import { Button, Container } from "reactstrap"
import img_default from "../../assets/img/theme/no_data.svg"
import img_event_eo from "../../assets/img/theme/no_data_event_eo.svg"
import img_event_certificate from "../../assets/img/theme/no_data_certificate.svg"

const NoDataImage = ({ navigate, type="default", message="Belum ada data", pt="pt-8" }) => {
    const navigation = useHistory();

    const img_types ={
        default: img_default,
        event_eo: img_event_eo,
        certificate: img_event_certificate
    }

    return (
        <Container className="py-8" fluid>
            <div className={"d-flex flex-column align-items-center justify-content-center "+pt}>
                <img className="result-image" src={img_types[type]} alt="hievents" />
                <h3 className="mt-4 text-center">{message}</h3>
                {navigate ?
                    <Button className="px-3 mt-2 text-white bg-hievent" size="sm" onClick={() => navigation.push(navigate)} color="bg-hievent">
                        Cari Event
                    </Button>
                    : null}
            </div>
        </Container>
    )
}

export default NoDataImage