import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import BuatEvent from "views/buat-event/Index";
import WalletIcon from "components/Partials/WalletIcon";
import { IoDownloadOutline } from "react-icons/io5";
import { formatToIDR } from "utils/helpers";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Avatar from "react-avatar";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import EOMedia from "components/Partials/EOMedia";
import NoDataImage from "components/Partials/NoDataImage";
import Event from "components/Items/Event";
import moment from "moment";
import Loading from "components/Partials/Loading";
import { useLocalStorage } from "routes/useLocalStorage";

const Index = () => {
  const [index, setIndex] = useState(1);
  const [eventOrganizer, setEventOrganizer] = useState({});
  const [follower, setFollower] = useState([]);
  const [eventDraft, setEventDraft] = useState([]);
  const [eventShow, setEventShow] = useState([]);
  const [eventDone, setEventDone] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);

  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  const useAuth = UseAuth();

  useEffect(() => {
    getEventOrganizer();
    getEventDraft();
    getEventShow();
    getEventDone();
    getFollower();
  }, [page]);

  const getEventOrganizer = () => {
    setLoading(true);
    API.get("eventOrganizers/getEventOrganizer", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        setLoading(false);
        setEventOrganizer(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  const getFollower = () => {
    API.get("follows/getFollower", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data)
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const [isOpen, setIsOpen] = useState(true);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const isSmallScreen = windowSize < 768;

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getEventDraft = () => {
    API.get("events/getEventsByUserId?status=draft&page=" + page, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);

        setEventDraft(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getEventShow = () => {
    API.get("events/getEventsByUserId?status=show", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data)
        setEventShow(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getEventDone = () => {
    // console.log("Page : ", page);
    API.get(`events/getEventsByUserId?status=done&&page=${page}`, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data);

        setEventDone(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  if (!useAuth.auth) {
    return <Redirect from="*" to="/auth" />;
  }

  if (loading) return <Loading />;

  return (
    <>
      {isSmallScreen ? (
        <>
          <div></div>
        </>
      ) : (
        <>
          <Container className="pt-8" fluid>
            <Card className="shadow">
              <CardBody className="p-5">
                <Row>
                  <Col md="8">
                    <Media className="align-items-center">
                      <Avatar
                        className="logo-eo"
                        name={eventOrganizer.name}
                        src={eventOrganizer.logo ? eventOrganizer.logo.url : ""}
                        round={true}
                      />
                      <Media className="ml-3 d-flex flex-column">
                        <span className="m-0 text-lg text-primary font-weight-bold">
                          {eventOrganizer.name}
                        </span>
                        <div className="d-flex flex-row">
                          <EOMedia eventOrganizer={eventOrganizer} />
                        </div>
                      </Media>
                    </Media>

                    <div className="d-flex flex-row align-items-center mt-3">
                      <Button
                        className="btn btn-sm px-3"
                        color="primary"
                        to="/edit-event-organizer"
                        tag={Link}
                      >
                        Edit Profil
                      </Button>
                      {/* <Button className="btn btn-sm px-3 text-white bg-hievent" color="bg-hievent">Ajukan Verifikasi</Button> */}
                    </div>
                  </Col>
                  <Col md="4" className="mt-4 mt-sm-0">
                    <Card className="bg-secondary">
                      <CardBody className="px-4">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <div className="w-100 d-flex align-items-center ">
                            <WalletIcon />
                            <h3
                              style={{ color: "#9870C5" }}
                              className="text-lg font-weight-bold m-0 ml-3"
                            >
                              {formatToIDR(eventOrganizer.balance)}
                            </h3>
                          </div>

                          <Link
                            to="/withdraw/event-organizer"
                            color="bg-hievent"
                            className=" w-100 text-lg text-white font-weight-bold mt-5 bg-hievent d-flex align-items-center justify-content-center py-2 rounded"
                          >
                            <IoDownloadOutline color="#ffffff" size={25} />
                            <h3 className="text-md font-weight-bold m-0 text-white">
                              Tarik Saldo
                            </h3>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="bg-secondary mt-4">
                      <CardBody className="px-6">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <i className="fa fa-users fa-lg" />
                          <span className="text-sm mt-1">Jumlah Pengikut</span>

                          <span className="text-lg font-weight-bold mt-3">
                            {follower.length}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <h3 className="color-hievent mt-4">Tentang</h3>
                <span className="text-sm">{eventOrganizer.about}</span>
              </CardBody>
            </Card>

            <div
              className="d-flex flex-row align-items-center mt-5 mx-4"
              style={{ overflowX: "auto" }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => (setIndex(1), setPage(1))}
                className={
                  index === 1
                    ? "text-md font-weight-bold color-hievent"
                    : "text-md font-weight-bold"
                }
              >
                Buat Event
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => (setIndex(2), setPage(1))}
                className={
                  index === 2
                    ? "text-md font-weight-bold color-hievent mx-4"
                    : "text-md font-weight-bold mx-4"
                }
              >
                Draft Event
              </span>

              <span
                style={{ cursor: "pointer" }}
                onClick={() => (setIndex(3), setPage(1))}
                className={
                  index === 3
                    ? "text-md font-weight-bold color-hievent mr-4"
                    : "text-md font-weight-bold mr-4"
                }
              >
                Akan diselenggarakan
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => (setIndex(4), setPage(1))}
                className={
                  index === 4
                    ? "text-md font-weight-bold color-hievent"
                    : "text-md font-weight-bold"
                }
              >
                Telah diselenggarakan
              </span>
            </div>

            {Array.isArray(eventDraft) && index === 2 && (
              <Pagination listClassName="justify-content-center">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                {pageNumbers.map((pageNumber) => (
                  <PaginationItem
                    key={pageNumber}
                    className={page === pageNumber ? "active" : ""}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            )}

            {Array.isArray(eventDone) && index === 4 && (
              <Pagination listClassName="justify-content-center">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                {pageNumbers.map((pageNumber) => (
                  <PaginationItem
                    key={pageNumber}
                    className={page === pageNumber ? "active" : ""}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            )}

            {index === 1 ? (
              <div className="mt--6">
                <BuatEvent
                  setEventDraft={setEventDraft}
                  match={{ params: {} }}
                />
              </div>
            ) : index === 2 ? (
              <Row className="mt-4">
                {eventDraft.length === 0 ? (
                  <NoDataImage
                    type="event_eo"
                    message="Saat ini kamu belum ada event yang berstatus draft"
                  />
                ) : (
                  eventDraft.map((item) => (
                    <Event
                      item={item}
                      xl="6"
                      lg="6"
                      md="6"
                      key={item._id}
                      eo={eventOrganizer.slug}
                      getEventDraft={getEventDraft}
                    />
                  ))
                )}
              </Row>
            ) : index === 3 ? (
              <Row className="mt-4">
                {eventShow.length === 0 ? (
                  <NoDataImage
                    type="event_eo"
                    message="Saat ini kamu belum ada event yang sedang berlangsung"
                  />
                ) : (
                  eventShow.map((item) => (
                    <Event
                      item={item}
                      xl="6"
                      lg="6"
                      md="6"
                      key={item._id}
                      eo={eventOrganizer.slug}
                      isOnGoing={true}
                    />
                  ))
                )}
              </Row>
            ) : (
              <Row className="mt-4">
                {eventDone.length === 0 ? (
                  <NoDataImage
                    type="event_eo"
                    message="Saat ini kamu belum ada event yang sudah berakhir"
                  />
                ) : (
                  eventDone.map((item) => (
                    <Event
                      item={item}
                      xl="6"
                      lg="6"
                      md="6"
                      key={item._id}
                      eo={eventOrganizer.slug}
                    />
                  ))
                )}
              </Row>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Index;
