import { useState } from "react";

import { Button, Modal, Spinner } from "reactstrap";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
// import axios from "axios";

const ExistSession = ({ props }) => {
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const onRevoke = () => {
    const data = {
      sessionId: props.message.data.sessionId,
    };
    API.post("auths/revokeAndLogin", data)
      .then((res) => {
        // console.log(res.data.data)
        props.setIsOpen(false);
        setLoading(false);
        props.setMessage(res.data);
        const data = {
          accessToken: res.data.data.accessToken,
          // sessionId: res.data.data.sessionId ? res.data.data.sessionId : 'xxx'
          // sessionId: res.data.data.sessionId
        };
        useAuth.login(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // axios
    //   .post("https://test.hievents.co/auths/revokeAndLogin", data)
    //   .then((res) => {
    //     // console.log(res.data.data)
    //     props.setIsOpen(false);
    //     setLoading(false);
    //     props.setMessage(res.data);
    //     const data = {
    //       accessToken: res.data.data.accessToken,
    //       // sessionId: res.data.data.sessionId ? res.data.data.sessionId : 'xxx'
    //       // sessionId: res.data.data.sessionId
    //     };
    //     useAuth.login(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    // });
  };

  // const getProfile = (auth) => {
  //     API.get('users/getProfile', {
  //         headers: { 'Authorization': "Bearer " + auth.data.accessToken }
  //     }).then(res => {
  //         console.log(res.data)
  //         props.setIsOpen(false)
  //         setLoading(false)
  //         props.setMessage(auth)
  //         const data = {
  //             accessToken: res.data.data.accessToken,
  //             sessionId: res.data.data.sessionId
  //         }
  //         useAuth.login(data)
  //     }).catch(err => {
  //         console.log(err)
  //     })
  // }

  return (
    <Modal
      className="modal-dialog-centered modal-info"
      contentClassName="bg-gradient-purple"
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setIsOpen(!props.isOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-2x" />
          <p>Akun kamu yang login di perangkat lain akan otomatis keluar!</p>
          <span>Lanjutkan ?</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          disabled={loading}
          onClick={() => onRevoke()}
          className="btn-white"
          color="default"
          type="button"
        >
          {loading ? <Spinner size="sm" /> : null} Lanjutkan
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setIsOpen(!props.isOpen)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ExistSession;
