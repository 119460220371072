import { Link } from "react-router-dom";
import moment from "moment";
import { formatToIDR } from "utils/helpers";
import SuccessIcon from "components/Partials/SuccessIcon";
import ErrorIcon from "components/Partials/ErrorIcon";
const PaidResult = ({ event, isPaidSuccess, setIsPaidSucces }) => {
  const isSmallScreen = window.innerWidth <= 640;
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        paddingTop: isSmallScreen ? "15vh" : "10vh", // Menyesuaikan padding atas untuk perangkat kecil
        zIndex: "1000",
        backgroundColor: "rgba(0,0,0,0.7)",
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "0",
        left: "0",
      }}
    >
      <div
        style={{
          width: isSmallScreen ? "85vw" : "55vw", // Lebar lebih kecil di perangkat mobile
          padding: isSmallScreen
            ? "15px 10px 40px 10px"
            : "25px 20px 50px 20px", // Padding lebih kecil di perangkat mobile
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "17px",
          borderRadius: "5px",
        }}
      >
        <div style={{ width: "100%", textAlign: "right", padding: "0px 25px" }}>
          <Link
            style={{ cursor: "pointer" }}
            to={`/event/${event.slug}`}
            onClick={() => setIsPaidSucces(null)}
          >
            <span
              aria-hidden={true}
              style={{
                color: "black",
                fontSize: "25px",
              }}
            >
              ×
            </span>
          </Link>
        </div>
        {isPaidSuccess ? <SuccessIcon /> : <ErrorIcon />}
        {isPaidSuccess ? (
          <h2
            style={{
              color: "#707070",
              fontWeight: 700,
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            Selamat Kamu Berhasil
            <br />
            Mendaftar Event ini !
          </h2>
        ) : (
          <h2
            style={{
              color: "#707070",
              fontWeight: 700,
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            Maaf, pendaftaran gagal
            <br />
            Silahkan daftar event kembali
          </h2>
        )}
        <h3
          className="color-hievent"
          style={{ fontWeight: 700, fontSize: "20px", textAlign: "center" }}
        >
          {event.title}
        </h3>
        <div
          style={{
            width: "65%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 0px",
              borderTop: "solid 1px #C4C4C4",
              borderBottom: "solid 1px #C4C4C4",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <small style={{ color: "#000000" }} className="text-sm">
                Tanggal dimulai
              </small>
            </div>
            <small style={{ color: "#737373" }} className="text-sm">
              {moment(event.startDate).format("DD MMMM YYYY")}
            </small>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 0px",
              borderBottom: "solid 1px #C4C4C4",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <small style={{ color: "#000000" }} className="text-sm">
                Harga
              </small>
            </div>
            <small style={{ color: "#737373" }} className="text-sm">
              {formatToIDR(event.cost)}
            </small>
          </div>
        </div>
        {isPaidSuccess && (
          <small style={{ color: "#000000" }} className="text-sm">
            Untuk mengakses event, silahkan masuk ke menu{" "}
            <Link
              to="/event-terdaftar"
              style={{
                color: "#8E82C0",
                fontWeight: 700,
              }}
            >
              Event Terdaftar
            </Link>
          </small>
        )}

        <Link
          onClick={() => (!isPaidSuccess ? setIsPaidSucces(null) : null)}
          to={isPaidSuccess ? "/cari-event" : `/event/${event.slug}`}
          style={{
            padding: isSmallScreen ? "8px 20px" : "9px 30px", // Padding lebih kecil untuk mobile
            backgroundColor: isPaidSuccess ? "#28DE25" : "#9870C5",
            color: "white",
            borderRadius: "5px",
            fontWeight: 700,
          }}
        >
          {isPaidSuccess ? "Kembali Mencari Event" : "Daftar kembali"}
        </Link>
      </div>
    </div>
  );
};

export default PaidResult;
