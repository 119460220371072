import SuccessRegister from "components/Modals/SuccessRegister";
import { useState } from "react";
import PaidProgress from "components/Modals/PaidProgress";
import { useHistory, Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import { showToast } from "./Toast";

const EventRegister = ({
  event,
  slug,
  prerequisite,
  isHavePrerequisite,
  accessGroupLink,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();
  const navigation = useHistory();
  const eventId = event._id;

  const registerEvent = () => {
    setLoading(true);
    if (!useAuth.auth) return navigation.push("/auth");

    const data = {
      eventId: event._id,
    };
    API.post("eventRegistrations/registerEvent", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        setIsOpen(true);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const LinkToPrerequisiteForm = () => {
    return (
      <>
        <Link
          to={{
            pathname: `/event-prasyarat/${slug}`,
            state: { prerequisite, eventId, event },
          }}
          className="btn btn-md w-100 text-white bg-hievent"
          color="bg-hievent"
        >
          {loading ? <Spinner size="sm" /> : "Daftar"}
        </Link>
      </>
    );
  };

  if (isHavePrerequisite && event.paidStatus === "paid") {
    return <LinkToPrerequisiteForm />;
  }

  if (event.paidStatus === "paid") {
    return (
      <>
        <Button
          onClick={() => setIsOpen(true)}
          className="btn btn-md w-100 text-white bg-hievent"
          color="bg-hievent"
        >
          {loading ? <Spinner size="sm" /> : null} Daftar
        </Button>
        <PaidProgress props={{ isOpen, setIsOpen, event }} />
      </>
    );
  } else if (isHavePrerequisite) {
    return <LinkToPrerequisiteForm />;
  } else {
    return (
      <>
        <Button
          onClick={registerEvent}
          className="btn btn-md w-100 text-white bg-hievent"
          color="bg-hievent"
        >
          {loading ? <Spinner size="sm" /> : "Daftar"}
        </Button>
        <SuccessRegister
          props={{ isOpen, setIsOpen, event, accessGroupLink }}
        />
      </>
    );
  }
};

export default EventRegister;
