import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Imagefaq from "../../assets/img/Image-faq.png";
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom/cjs/react-router-dom";

const FAQ = () => {
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowSize < 768;
  return (
    <>
      {isSmallScreen ? (
        <div
          className="md:pt-9 p-3 pb-10 md:pb-0 bg-[#F9F9F9]"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            className="w-100 d-flex flex-column"
            style={{
              gap: 15,
            }}
          >
            <Link
              to="/"
              className="d-flex flex-row"
              style={{
                gap: 10,
                alignItems: "center",
              }}
            >
              <IoIosArrowBack size={20} style={{ color: "black" }} />
              <div
                className="text-lg font-bold text-center"
                style={{ color: "black" }}
              >
                Pertanyaan
              </div>
            </Link>
            <h1
              className="text-xl font-bold text-center"
              style={{
                color: "black",
              }}
            >
              Pertanyaan
            </h1>
          </div>

          <Accordion className="d-flex flex-column gap-4 align-items-center justify-content-center w-100 h-100">
            <div className="bg-white bg-opacity-10 rounded-2xl w-100 w-md-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana cara daftar event di hievents.co
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan login/signup terlebih dahulu, kemudian masuk ke
                      halaman event yang ingin diikuti melalui menu cari event,
                      lalu silahkan daftar event
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg w-75"
                      style={{
                        color: "black",
                      }}
                    >
                      Saya sudah daftar tapi kok email verifikasinya tidak masuk
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan coba chek pada kotak masuk / spam didalam email
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg w-75"
                      style={{
                        color: "black",
                      }}
                    >
                      Kenapa saat mendaftar tampilannya seperti gambar dibawah
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <img
                      src={Imagefaq}
                      alt="FAQ"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Itu email / username nya sudah pernah dipakai sebelumnya,
                      Jadi tidak bisa digunakan lagi untuk mendaftar dengan
                      email / username yang sama. Silahkan daftar dengan email /
                      username yang baru atau berbeda dengan username tersebut
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg w-75"
                      style={{
                        color: "black",
                      }}
                    >
                      Sertifikat yang didapat hanya hanya ada nama saja tanpa
                      ada template sertifikatnya
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan coba buka sertifikat melalui laptop/pc
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Kenapa sertifikat tidak bisa di download
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Pastikan kamu sudah terlebih dahulu mendaftar eventnya,
                      kemudian absen pada link absensi yang sudah diberikan
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg w-75"
                      style={{
                        color: "black",
                      }}
                    >
                      Saya ada kendala saat absensi , yaitu ketika absen dan
                      download sertifikat hanya terlihat halaman kosong
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Pastikan kamu sudah terlebih dahulu mendaftar eventnya,
                      kemudian absen pada link absensi yang sudah diberikan.
                      <br />
                      Atau
                      <br />
                      Silahkan hapus cookies dan cached pada browser, kemudian
                      login kembali dan klik link absensi
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana jika saya lupa password
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      silahkan klik “lupa password” pada halaman login di
                      hievents.co
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-100 md:w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana jika saya lupa username
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan hubungi email yaitu hievents.cs@gmail.com , lalu
                      didalam email tersebut silahkan buat keterangan seperti
                      yang ada dibawah
                      <br />
                      Saya (nama lengkap) dengan email (email saat daftar di
                      hievents) dan no hp (no hp kaka) mohon dikirimkan kembali
                      username saya
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
          </Accordion>
        </div>
      ) : (
        <div
          className="pt-9 bg-[#F9F9F9]"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div className="w-100 d-flex flex-column gap-4">
            <h1
              className="text-xl font-bold text-center"
              style={{
                color: "black",
              }}
            >
              Pertanyaan
            </h1>
          </div>

          <Accordion className="d-flex flex-column gap-4 align-items-center justify-content-center w-100 h-100">
            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana cara daftar event di hievents.co
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan login/signup terlebih dahulu, kemudian masuk ke
                      halaman event yang ingin diikuti melalui menu cari event,
                      lalu silahkan daftar event
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Saya sudah daftar tapi kok email verifikasinya tidak masuk
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan coba chek pada kotak masuk / spam didalam email
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Kenapa saat mendaftar tampilannya seperti gambar dibawah
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <img
                      src={Imagefaq}
                      alt="FAQ"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Itu email / username nya sudah pernah dipakai sebelumnya,
                      Jadi tidak bisa digunakan lagi untuk mendaftar dengan
                      email / username yang sama. Silahkan daftar dengan email /
                      username yang baru atau berbeda dengan username tersebut
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Sertifikat yang didapat hanya hanya ada nama saja tanpa
                      ada template sertifikatnya
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan coba buka sertifikat melalui laptop/pc
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Kenapa sertifikat tidak bisa di download
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Pastikan kamu sudah terlebih dahulu mendaftar eventnya,
                      kemudian absen pada link absensi yang sudah diberikan
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Saya ada kendala saat absensi , yaitu ketika absen dan
                      download sertifikat hanya terlihat halaman kosong
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Pastikan kamu sudah terlebih dahulu mendaftar eventnya,
                      kemudian absen pada link absensi yang sudah diberikan.
                      <br />
                      Atau
                      <br />
                      Silahkan hapus cookies dan cached pada browser, kemudian
                      login kembali dan klik link absensi
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana jika saya lupa password
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      silahkan klik “lupa password” pada halaman login di
                      hievents.co
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>

            <div className="bg-white bg-opacity-10 rounded-2xl w-50 p-2">
              <AccordionItem className="bg-white bg-opacity-10 rounded-2xl w-100 h-100">
                <AccordionItemHeading className="text-white">
                  <AccordionItemButton
                    variant="link"
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center w-100 text-white text-left"
                  >
                    <h2
                      className="font-bold text-lg"
                      style={{
                        color: "black",
                      }}
                    >
                      Bagaimana jika saya lupa username
                    </h2>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="md-w-24 w-20 aspect-square"
                    >
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="black"
                      />
                    </svg>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel eventKey="0">
                  <div>
                    <hr className="border-[#EEEEEE33] mb-4" />
                    <p
                      className="text-base font-normal"
                      style={{
                        color: "black",
                      }}
                    >
                      Silahkan hubungi email yaitu hievents.cs@gmail.com , lalu
                      didalam email tersebut silahkan buat keterangan seperti
                      yang ada dibawah
                      <br />
                      Saya (nama lengkap) dengan email (email saat daftar di
                      hievents) dan no hp (no hp kaka) mohon dikirimkan kembali
                      username saya
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default FAQ;
