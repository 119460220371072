import { toast } from "react-toastify";

export const showToast = (message, status, position, theme) => {
  toast[status ? status : "info"](message, {
    position: position ? position : "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme ? theme : "light",
  });
};
