import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Cities from "components/Partials/Cities";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Form,
  Spinner,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import Avatar from "react-avatar";
import API from "utils/API";
import { showToast } from "components/Partials/Toast";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import FollowEo from "components/Items/FollowEo";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import { FaRegUser } from "react-icons/fa6";
import { MdKeyboardArrowRight, MdLanguage } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { PiKeyholeBold } from "react-icons/pi";
import ToggleLanguage from "components/Items/ToggleLanguage";
import { IoMdLogOut } from "react-icons/io";

const Index = ({ USER, SET_USER, FOLLOWING }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [name, setName] = useState("");
  const [cityId, setCityId] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState("");

  const [countEvent, setCountEvent] = useState([]);

  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const useAuth = UseAuth();
  const [index, setIndex] = useState(1);
  const [page, setPage] = useState(1);
  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1, 10);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  useEffect(() => {
    if (USER._id) {
      setEmail(USER.email);
      setUsername(USER.username);
      setName(USER.name);
      setCityId(USER.cityId);
      setPhone(USER.phone);
      setPhoto(USER.photo?.url);
    }
  }, [USER]);
  

  const getEventRegistrations = () => {
    setLoading(true);
    API.get(`eventRegistrations/getUserEventRegistrations?page=${page}`, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        setEvent(res.data.data);
        setCountEvent(
          res.data.data.filter((item) => item.status === "attended")
        );
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  useEffect(() => {
    if (useAuth.auth) getEventRegistrations();
  }, [page,useAuth.auth]);

  const isSmallScreen = window.innerWidth < 768;

  const navigation = useHistory();

  const onChangeFoto = (foto) => {
    setLoading(true);
    const data = new FormData();
    data.append("photo", foto[0]);

    API.put("users/updateProfilePhoto", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        SET_USER(res.data.data);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onSave = () => {
    setLoading(true);
    const data = { name, cityId, phone };

    API.put("users/updateUser", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        SET_USER(res.data.data);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onLogout = () => {
    API.get("auths/logout", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        SET_USER({});
        useAuth.logout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!USER._id) return <Redirect from="*" to="/auth" />;
  // console.log("USER : ", USER);
  // console.log("USER FOLLOWING : ", FOLLOWING);

  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            className="mt-3"
            style={{
              padding: "10px 20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h4
              style={{
                fontWeight: "normal",
                color: "black",
                fontSize: "14px",
              }}
            >
              Profil Saya
            </h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Avatar
                className="logo-eo"
                name={name}
                src={photo}
                round={true}
                size="65px"
              />
              <div>
                <h4
                  style={{
                    fontWeight: "normal",
                    fontSize: "18px",
                  }}
                >
                  {name}
                </h4>
                <h4
                  style={{
                    color: "#737373",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  {email}
                </h4>
              </div>
            </div>
            <Row
              style={{
                backgroundColor: "#9870C5",
                borderRadius: "10px",
                margin: "0px 5px",
                padding: "5px 10px",
              }}
            >
              <Col>
                {Array.isArray(countEvent) && countEvent.length >= 0 ? (
                  <h1
                    style={{
                      fontSize: "12px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {countEvent.length}
                    <br />
                    <span
                      style={{
                        color: "white",
                        fontSize: "10px",
                        fontWeight: "normal",
                      }}
                    >
                      Event Diikuti
                    </span>
                  </h1>
                ) : null}
              </Col>
            </Row>
            <div>
              <h4
                style={{
                  fontWeight: 600,
                  fontSize: "12px",
                  color: "black",
                }}
              >
                Akun Saya
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <FaRegUser size={16} />
                <div
                  style={{
                    color: "#737373",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                  onClick={() => navigation.push("/profil-edit")}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Pengaturan Profil
                  </span>
                  <MdKeyboardArrowRight size={16} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <HiOutlineUserGroup size={16} />
                <div
                  style={{
                    color: "#737373",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                  onClick={() => navigation.push("/eo-diikuti")}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Event Organizer Diikuti
                  </span>
                  <MdKeyboardArrowRight size={16} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <PiKeyholeBold size={16} />
                <div
                  style={{
                    color: "#737373",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                  onClick={() => navigation.push("/ubah-password")}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Ubah Password
                  </span>
                  <MdKeyboardArrowRight size={16} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <i className="fa fa-question-circle" />
                <div
                  style={{
                    color: "#737373",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                  onClick={() => navigation.push("/kontak-kami")}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Hubungi Kami
                  </span>
                  <MdKeyboardArrowRight size={16} />
                </div>
              </div>

              {/* Bahasa */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <MdLanguage size={16} />
                <div
                  style={{
                    color: "#737373",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Bahasa
                  </span>
                  <ToggleLanguage />
                </div>
              </div> */}
            </div>

            <div>
              <h4
                style={{
                  fontWeight: 600,
                  fontSize: "12px",
                  color: "black",
                }}
              >
                Info Lainnya
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <IoMdLogOut size={16} color="#FC0000" />
                <div
                  style={{
                    color: "#FC0000",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                    height: "100%",
                    alignItems: "center",
                    borderBottom: "1px solid #F0F0F0",
                    cursor: "pointer",
                  }}
                  onClick={onLogout}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    Keluar
                  </span>
                </div>
              </div>
            </div>
          </div>
          <UserBottomBarMobile />
        </>
      ) : (
        <Container className="pt-9 px-2">
          <Row>
            <Col md="6">
              <Card className="card-profile shadow d-flex flex-column align-items-center justify-content-center">
                <div className="mt-3">
                  <Avatar
                    className="logo-eo"
                    name={name}
                    src={photo}
                    round={true}
                  />
                </div>

                <label
                  htmlFor="browse-image"
                  className="btn btn-sm px-3 btn-primary m-0 mt-2"
                >
                  {loading ? <Spinner size="sm" /> : null} Ubah Foto
                </label>
                <input
                  hidden
                  type="file"
                  id="browse-image"
                  className="form-control"
                  onChange={(event) => onChangeFoto(event.target.files)}
                />

                <CardBody className="mt-3">
                  <Form>
                    <Row>
                      <Col md="12" className="mt--3">
                        <FormGroup>
                          <label className="text-sm">Username</label>
                          <Input
                            placeholder="Username"
                            type="text"
                            readOnly
                            value={username}
                            onChange={(event) =>
                              setUsername(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mt--3">
                        <FormGroup>
                          <label className="text-sm">Email</label>
                          <Input
                            placeholder="Email"
                            type="email"
                            autoComplete="new-email"
                            readOnly
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mt--3">
                        <FormGroup>
                          <label className="text-sm">Nama Lengkap</label>
                          <Input
                            placeholder="Nama Lengkap"
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mt--3">
                        <FormGroup>
                          <label className="text-sm">Nomor Whatsapp</label>
                          <PhoneInput
                            className="form-control"
                            defaultCountry="ID"
                            international
                            countryCallingCodeEditable={false}
                            placeholder="Nomor Whatsapp"
                            value={phone}
                            onChange={(val) => setPhone(val)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mt--3">
                        <FormGroup>
                          <label className="text-sm">Kota Domisili</label>
                          <Cities cityId={cityId} setCityId={setCityId} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      disabled={loading}
                      onClick={() => onSave()}
                      color="bg-hievent"
                      className="mt-3 w-100 text-white bg-hievent"
                    >
                      {loading ? <Spinner size="sm" /> : null} Simpan Perubahan
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="mt-4 mt-md-0">
              <Card className="shadow">
                <CardHeader className="d-flex flex-row align-items-center p-3 justify-content-center">
                  <i className="fa fa-users fa-lg" />
                  <span className="mx-2">Event Organizer yang diikuti</span>
                  <span className="text-xl text-primary font-weight-bold">
                    {FOLLOWING.length}
                  </span>
                </CardHeader>
                <CardBody className="d-flex flex-column p-3 align-items-center justify-content-center">
                  {FOLLOWING.length === 0 ? (
                    <span className="m-4 text-sm font-weight-bold">
                      Belum ada Event Organizer yang diikuti
                    </span>
                  ) : null}

                  {FOLLOWING.map((element, index) => {
                    return index <= 5 ? (
                      <FollowEo item={element} key={element._id} />
                    ) : null;
                  })}
                </CardBody>
                {FOLLOWING.length > 0 ? (
                  <CardFooter
                    className="d-flex rounded-bottom border-0 p-3 align-items-center bg-gradient-primary"
                    to="/following"
                    tag={Link}
                  >
                    <span className="w-100 text-center text-white">
                      Lihat Semua
                    </span>
                  </CardFooter>
                ) : null}
              </Card>

              <hr></hr>

              <Button
                onClick={() => navigation.push("/event-terdaftar")}
                className="btn-icon btn-md w-100 mb-3 m-0 text-white bg-hievent"
                color="bg-hievent"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-history fa-lg" />
                </span>
                <span className="btn-inner--text">History Event Terdaftar</span>
              </Button>
              <Button
              onClick={() => navigation.replace("/kontak-kami")}
                className="btn-icon btn-md w-100 mb-3 m-0 text-white bg-hievent"
                color="bg-hievent"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-question-circle fa-lg" />
                </span>
                <span className="btn-inner--text">Pusat Bantuan</span>
              </Button>
              <Button
                className="btn-icon btn-md w-100 mb-3 m-0 text-white bg-hievent"
                color="bg-hievent"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-question-circle fa-lg" />
                </span>
                <span className="btn-inner--text">Tentang HiEvents</span>
              </Button>
              <Button
                className="btn-icon btn-md w-100 mb-3 m-0 text-white bg-hievent"
                color="bg-hievent"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-exclamation-circle fa-lg" />
                </span>
                <span className="btn-inner--text">Kritik / Saran</span>
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
