import "../../assets/style/contact-us.css";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { MdOutlinePhone } from "react-icons/md";
import ContactCard from "components/Partials/contactCard";
import { useState } from "react";
import Map from "react-map-gl";
const ContactUs = () => {
    
  const [viewState, setViewState] = useState({
    longitude: 106.8180544,
    latitude: -6.2099562,
    zoom: 15,
  });
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const cardContent = [
    {
      icon: CiMail,
      subTitle: "Email",
      content: "hievents.cs@gmail.com",
    },
    {
      icon: MdOutlinePhone,
      subTitle: "Whatsapp",
      content: "+081388489680",
    },
    {
      icon: CiLocationOn,
      subTitle: "Alamat Kantor",
      content:
        "Sahid Sudirman Residence, LG 03/OFF Jalan Jend. Sudirman Kav. 86 010/011 Karet Tengsin, Tanah Abang Jakarta Pusat 10220",
    },
  ];

  return (
    <div className="container-page">
      <h1 className="header">Hubungi Kami</h1>
      <div className="container-detail">
        <div className=" container-card">
          {cardContent.map((item, i) => {
            return <ContactCard content={item} key={i} />;
          })}
        </div>
        <div className=" container-map">
          <div className="map">
            <Map
              {...viewState}
              dragRotate={false}
              doubleClickZoom={false}
              touchZoom={false}
              touchRotate={false}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxApiAccessToken={MAPBOX_TOKEN}
            >
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
