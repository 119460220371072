import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Cities from "components/Partials/Cities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  CardBody,
  FormGroup,
  Form,
  Spinner,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import Avatar from "react-avatar";
import API from "utils/API";
import { showToast } from "components/Partials/Toast";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";

const EditProfil = ({ USER, SET_USER, FOLLOWING }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [name, setName] = useState("");
  const [cityId, setCityId] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState("");

  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();
  const navigation = useHistory();

  useEffect(() => {
    if (USER._id) {
      setEmail(USER.email);
      setUsername(USER.username);
      setName(USER.name);
      setCityId(USER.cityId);
      setPhone(USER.phone);
      setPhoto(USER.photo?.url);
    }
  }, [USER]);

  const onChangeFoto = (foto) => {
    setLoading(true);
    const data = new FormData();
    data.append("photo", foto[0]);

    API.put("users/updateProfilePhoto", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        SET_USER(res.data.data);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onSave = () => {
    setLoading(true);
    const data = { name, cityId, phone };

    API.put("users/updateUser", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        SET_USER(res.data.data);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const isSmallScreen = window.innerWidth < 768;

  return (
    <>
      {isSmallScreen ? (
        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {/* Navigasi kembali */}
          <div>
            <button
              outline
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: "5px",
                outline: "none",
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => navigation.push("/profil")}
            >
              <MdKeyboardArrowLeft size={20} />
              <span
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                Pengaturan Profil
              </span>
            </button>
          </div>
          {/* Foto profil */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <Avatar
                className="logo-eo"
                name={name}
                src={photo}
                round={true}
                size="100px"
              />
              {/* Tombol Ganti Foto Profil */}
              <label
                htmlFor="file-upload"
                className="custom-file-upload"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  background: "#9870C5",
                  padding: "3px",
                  borderRadius: "50%", // Membuat border menjadi lingkaran
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px", // Ubah sesuai dengan ukuran yang diinginkan
                  height: "30px", // Ubah sesuai dengan ukuran yang diinginkan
                }}
              >
                {/* Icon ganti foto profil */}
                <FontAwesomeIcon
                  icon={faCamera}
                  style={{ marginRight: "1px", color: "white" }}
                />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={(e) => onChangeFoto(e.target.files)}
                style={{ display: "none" }}
              />
              <input
                id="file-upload"
                type="file"
                onChange={(e) => onChangeFoto(e.target.files)}
                style={{ display: "none" }}
              />
            </div>
            <div>
              <h4
                style={{
                  fontSize: "24px",
                  fontWeight: "normal",
                  color: "black",
                  lineHeight: "auto",
                }}
              >
                {name}
              </h4>
            </div>
          </div>
          {/* Formulir pengaturan profil */}
          <CardBody className="mt-3">
            <Form>
              <Row>
                <Col md="12" className="mt--3">
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "20px",
                        color: "black",
                      }}
                    >
                      Nama Lengkap
                    </label>
                    <Input
                      style={{
                        border: "1px solid #C4C4C4",
                        borderRadius: "0.25rem",
                        fontSize: "12px",
                        color: "#C4C4C4",
                        fontWeight: "normal",
                        lineHeight: "20px",
                      }}
                      placeholder="Nama Lengkap"
                      type="text"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="mt--3">
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "20px",
                        color: "black",
                      }}
                    >
                      Email
                    </label>
                    <Input
                      style={{
                        border: "1px solid #C4C4C4",
                        borderRadius: "0.25rem",
                        fontSize: "12px",
                        color: "#C4C4C4",
                        fontWeight: "normal",
                        lineHeight: "20px",
                      }}
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="mt--3">
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "20px",
                        color: "black",
                      }}
                    >
                      Kota Domisili
                    </label>
                    <Cities cityId={cityId} setCityId={setCityId} />
                  </FormGroup>
                </Col>
                <Col md="12" className="mt--3">
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "20px",
                        color: "black",
                      }}
                    >
                      No Whatsapp
                    </label>
                    <PhoneInput
                      style={{
                        border: "2px solid #ced4da",
                        borderRadius: "0.25rem",
                      }}
                      className="form-control"
                      defaultCountry="ID"
                      international
                      countryCallingCodeEditable={false}
                      placeholder="Nomor Whatsapp"
                      value={phone}
                      onChange={(val) => setPhone(val)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* Tombol Simpan */}
              <Button
                disabled={loading}
                onClick={() => onSave()}
                color="bg-hievent"
                className="mt-3 w-100 text-white bg-hievent"
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "auto",
                }}
              >
                {loading ? <Spinner size="sm" /> : null} Simpan Perubahan
              </Button>
            </Form>
          </CardBody>
        </div>
      ) : (
        <>
          {/* Tampilkan konten untuk tampilan desktop di sini jika diperlukan */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfil);
