// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";

import {
    Row,
    Col,
    Container,
    CardBody,
    Card
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const Item = ({ item, xl, lg, md }) => {
    return (
        <Col
            key={item._id}
            onClick={() => null}
            className="mb-3" xl={xl} lg={lg} md={md}>
                <Card className="shadow">
                    <CardBody className="d-flex flex-column align-items-center">
                        <Avatar className="logo-eo" name={item.user?.name} src={item.user?.photo?.url} round={true} size={70} />
                        <h4 className="mb-0 mt-1 text-center">{item.user?.name}</h4>
                        <p className="mb-0 text-center">{item.user?.city[0]?.name}</p>
                    </CardBody>
                </Card>
        </Col>
    );
};

const UserRegistered = ({ match }) => {
    const [users, setUsers] = useState({})
    const [loading, setLoading] = useState(true)

    const useAuth = UseAuth()

    useEffect(() => {
        getUsers(match.params.id)
    }, [match.params.id])

    const getUsers = (_id) => {

        API.get('eventRegistrations/getUserAttendEvents/' + _id.split('=')[0] + `?page=0&perPage=${_id.split('=')[1]}&status=registered`, {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data)
            setLoading(false)
            setUsers(res.data)
        }).catch(err => {
            setLoading(false)
            console.log(err.response.data)
        })
    }

    if (loading)
        return <Loading />;

    return (
        <>
            {/* Page content */}
            <Container className="pt-8" fluid>
                {/* <h2 className="color-hievent">Event Organizer yang diikuti</h2> */}
                {/* <Breadcumbs data={[window.location.pathname.replace("/" + match.params.id, '').replace("/", "")]} /> */}
                <Row className="p-4">
                    {users.data.map(item =>
                        <Item item={item} xl="2" lg="2" md="3" key={item._id} />
                    )}
                </Row>
            </Container>
        </>
    )
}

export default UserRegistered