import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Dashboard from "views/dashboard/Index";
import CariEvent from "views/cari-event/Index";
import Profil from "views/profil/Index";
import EditProfil from "views/profil/EditProfil";
import EoDiikuti from "views/profil/EoDiikuti";
import EventTerdaftar from "views/event-terdaftar/Index";
import RuangSertifikat from "views/ruang-sertifikat/Index";
import DetailEvent from "views/detail-event/Index";
import BuatEvent from "views/buat-event/Index";
import EventDisimpan from "views/event-disimpan/Index";
import ProfilEventOrganizer from "views/event-organizer/Profil";
import RegisterEventOrganizer from "views/event-organizer/Register";
import EventOrganizer from "views/event-organizer/Index";
import Notifikasi from "views/notifikasi/Index";
import Activation from "views/auth/Activation";
import ForgotPassword from "views/auth/ForgotPassword";
import Following from "views/following/Index";
import AllEvent from "views/all-event/AllEvent";
import NotFound from "components/Errors/NotFound";
import ResetPassword from "views/auth/ResetPassword";
import UserRegistered from "views/event-terdaftar/UserRegistered";
import Sertifikat from "views/ruang-sertifikat/Sertifikat";
import EventTertutup from "views/event-tertutup";
import UbahPassword from "views/profil/UbahPassword";
import EventKategori from "views/event-kategori/Index";
import FAQ from "views/faq/Index";
import ContactUs from "views/kontak-kami";
import Prasyarat from "views/event-prasyarat";
import UserRegisteredPaidEvent from "views/event-terdaftar/UserRegisteredPaidEvent";
import UserRegisteredPrerequisiteEvent from "views/event-terdaftar/UserRegisteredPrerequisiteEvent";
import Withdraw from "views/event-organizer/withdraw";

var routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    layout: "/",
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    layout: "/",
  },
  {
    path: "cari-event",
    name: "Cari Event",
    component: CariEvent,
    layout: "/",
    icon: "fa fa-search",
    category: "side",
  },
  {
    path: "event-terdaftar/:slug",
    name: "Event Terdaftar",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "event-selesai/:slug/:absen",
    name: "Event Selesai",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "event-terdaftar",
    name: "Event Terdaftar",
    component: EventTerdaftar,
    layout: "/",
    icon: "fa fa-check",
    category: "side",
  },
  {
    path: "ruang-sertifikat/:slug",
    name: "Ruang Sertifikat",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "ruang-sertifikat",
    name: "Ruang Sertifikat",
    component: RuangSertifikat,
    layout: "/",
    icon: "fa fa-certificate",
    category: "side",
  },
  {
    path: "sertifikat/:id",
    name: "Sertifikat",
    component: Sertifikat,
    layout: "/",
    icon: "fa fa-certificate",
    category: "side",
  },
  {
    path: "event-disimpan",
    name: "Event Disimpan",
    component: EventDisimpan,
    layout: "/",
  },
  {
    path: "event-tertutup",
    name: "Event Private",
    component: EventTertutup,
    layout: "/",
  },
  {
    path: "event-kategori/:slug",
    name: "Event Kategori",
    component: EventKategori,
    layout: "/",
  },
  {
    path: "event-tertutup/:slug",
    name: "Event Private",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "notifikasi",
    name: "Notifikasi",
    component: Notifikasi,
    layout: "/",
  },
  {
    path: "following",
    name: "Following",
    component: Following,
    layout: "/",
  },
  {
    path: "absensi/:slug",
    name: "Absensi",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "event/:slug",
    name: "Detail Event",
    component: DetailEvent,
    layout: "/",
  },
  {
    path: "event-prasyarat/:slug",
    name: "Prasyarat",
    component: Prasyarat,
    layout: "/",
  },
  {
    path: "events/:keyword_slug",
    name: "All Event",
    component: AllEvent,
    layout: "/",
  },
  {
    path: "user-terdaftar/:id",
    name: "User Terdaftar",
    component: UserRegistered,
    layout: "/",
  },
  {
    path: "user-terdaftar-event-berbayar/:id",
    name: "User Terdaftar",
    component: UserRegisteredPaidEvent,
    layout: "/",
  },
  {
    path: "user-terdaftar-event-prasyarat/:id",
    name: "User Terdaftar",
    component: UserRegisteredPrerequisiteEvent,
    layout: "/",
  },
  {
    path: "user-absensi/:id",
    name: "User Absensi",
    component: UserRegistered,
    layout: "/",
  },
  {
    path: "buat-event",
    name: "Buat Event",
    component: BuatEvent,
    layout: "/",
  },
  {
    path: "edit-event/:slug",
    name: "Edit Event",
    component: BuatEvent,
    layout: "/",
  },
  {
    path: "event-organizer/:slug",
    name: "Detail Event Organizer",
    component: ProfilEventOrganizer,
    layout: "/",
  },
  {
    path: "event-organizer",
    name: "Event Organizer",
    component: EventOrganizer,
    layout: "/",
  },
  {
    path: "withdraw/event-organizer",
    name: "Event Organizer",
    component: Withdraw,
    layout: "/",
  },
  {
    path: "register-event-organizer",
    name: "Register Event Organizer",
    component: RegisterEventOrganizer,
    layout: "/",
  },
  {
    path: "edit-event-organizer",
    name: "Edit Event Organizer",
    component: RegisterEventOrganizer,
    layout: "/",
  },
  {
    path: "profil",
    name: "Profil",
    component: Profil,
    layout: "/",
  },

  {
    path: "eo-diikuti",
    name: "Eo Diikuti",
    component: EoDiikuti,
    layout: "/",
  },

  {
    path: "profil-edit",
    name: "Profil Edit",
    component: EditProfil,
    layout: "/",
  },

  {
    path: "ubah-password",
    name: " Ubah Password",
    component: UbahPassword,
    layout: "/",
  },
  {
    path: "kontak-kami",
    name: "Kontak Kami",
    component: ContactUs,
    layout: "/",
  },
  {
    path: "not-found",
    name: "Error Not Found",
    component: NotFound,
    layout: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/confirm-email",
    name: "Aktivasi Akun",
    component: Activation,
    layout: "/auth",
  },
  {
    path: "/forgot-pasword",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/reset",
    name: "Reset Password",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    layout: "/auth",
  },
];

export default routes;
