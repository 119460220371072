import { useState } from "react";
import {
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";
import Cities from "components/Partials/Cities";
import PhoneInput from "react-phone-number-input";
import { Button, Modal } from "reactstrap";
import { connect } from "react-redux";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
import { useHistory } from "react-router-dom";
import { showToast } from "components/Partials/Toast";

const ActivationGoogle = ({ props, USER, SET_USER }) => {
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [cityId, setCityId] = useState("");
  const [phone, setPhone] = useState("");

  const useAuth = UseAuth();
  const navigation = useHistory();

  const onUpdate = () => {
    setLoading(true);
    const updateData = {
      username: username,
      name: name,
      cityId: cityId,
      phone: phone,
    };

    API.put("users/updateUser", updateData, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        console.log("update data : ", res.data.data);
        SET_USER(res.data.data);
        props.setIsOpen(false);
        setLoading(false);
        // showToast(res.data.status, res.data.message);
        navigation.replace("/");
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        props.setIsOpen(false);
      });
  };

  return (
    <Modal
      className="modal-dialog-centered modal-info"
      contentClassName="bg-white"
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
      backdrop="static"
      style={{
        top: "10%",
      }}
    >
      <div className="modal-header">
        <img
          style={{
            height: 360,
            width: "100%",
            position: "absolute",
            top: -150,
            left: 0,
          }}
          className="logo-eo"
          src={require("../../assets/img/theme/header.svg").default}
          alt="hievents"
        />
      </div>
      <div className="modal-body">
        <div className="pt-8 text-center text-dark">
          <p style={{ color: "#002BC1" }}>
            *Pastikan Nama Lengkap (Beserta GELAR Jika Ada) diisi dengan Benar,
            karena akan mempengaruhi pada saat Absensi Event. (Pastikan
            penggunaan Huruf kapital dalam nama lengkap sesuai).
          </p>
        </div>
        <Form role="form">
          <FormGroup>
            <Label
              for="namaLengkap"
              style={{ color: "black", fontWeight: 400 }}
            >
              User Name{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
            </Label>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="username kamu"
                type="text"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label
              for="namaLengkap"
              style={{ color: "black", fontWeight: 400 }}
            >
              Nama Lengkap
            </Label>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Nama Lengkap Kamu"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label
              for="namaLengkap"
              style={{ color: "black", fontWeight: 400 }}
            >
              Kota Domisili
            </Label>
            <InputGroup className="input-group-alternative mb-3 bg-white">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-square-pin" />
                </InputGroupText>
              </InputGroupAddon>
              <Cities cityId={cityId} setCityId={setCityId} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label
              for="namaLengkap"
              style={{ color: "black", fontWeight: 400 }}
            >
              Nomor Whatsapp
            </Label>
            <InputGroup className="input-group-alternative mb-3 bg-white">
              <InputGroupAddon addonType="prepend" style={{ borderRadius: 0 }}>
                <InputGroupText>
                  <i className="ni ni-mobile-button" />
                </InputGroupText>
              </InputGroupAddon>
              <PhoneInput
                className="form-control"
                defaultCountry="ID"
                international
                countryCallingCodeEditable={false}
                placeholder="Nomor Whatsapp"
                value={phone}
                onChange={(val) => setPhone(val)}
              />
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          className="text-white bg-hievent"
          color="link"
          data-dismiss="modal"
          type="button"
          style={{
            width: "266px",
          }}
          onClick={() => onUpdate()}
        >
          {loading ? <Spinner size="sm" /> : null} SIMPAN
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationGoogle);
