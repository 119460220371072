import React from "react";
import Event from "components/Items/Event";
// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import NoDataImage from "components/Partials/NoDataImage";
import { showToast } from "components/Partials/Toast";
import { useEffect, useState } from "react";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import CardMobile from "components/Partials/CardMobile";
import { AnimatePresence } from "framer-motion";
import { FaSearch, FaTimes } from "react-icons/fa";
import {
  Container,
  Row,
  Col,
  Input,
  Link,
  FormGroup,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import API from "utils/API";
import Filter from "./Filter";
import { FaFilter } from "react-icons/fa6";
import FilterMobile from "./FilterMobile";

const Index = () => {
  const [event, setEvent] = useState([]);
  const [eventTypeIds, setEventTypeIds] = useState([]);
  const [preconditionIds, setPreconditionIds] = useState([]);
  const [eventLocTypes, setEventLocTypes] = useState([]);
  const [eventContextIds, setEventContextIds] = useState([]);
  const [keyWord, setKeyWord] = useState("");

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [loading, setLoading] = useState(true);

  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1, totalPage);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  useEffect(() => {
    getEvent();
  }, [eventTypeIds, preconditionIds, eventLocTypes, eventContextIds, page]);

  const getEvent = () => {
    setEvent([]);
    setLoading(true);
    let search =
      eventTypeIds.length > 0 ? "eventTypeId=" + eventTypeIds[0] : "";
    search +=
      preconditionIds.length > 0
        ? "&preconditionIds=" + preconditionIds[0]
        : "";
    search += eventLocTypes.length > 0 ? "&location=" + eventLocTypes[0] : "";
    // search += eventContextIds.length > 0 ? "&eventContextId=" + eventContextIds[0] : ""
    search += eventContextIds ? "&eventContextId=" + eventContextIds : "";
    search += keyWord !== "" ? "&title=" + keyWord : "";
    if (search[0] === "&") search = search.substring(1);

    API.get("events/getEvents?" + search + `&page=${page}`, {})
      .then((res) => {
        // console.log(res.data.data)
        setLoading(false);
        setEvent(res.data.data);
        setTotalPage(parseInt(res.data.total / 10));
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const [isOpen, setIsOpen] = useState(true);

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowSize < 640;

  // console.log("Total Page : ", totalPage);

  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            style={{
              padding: "10px 20px",
            }}
          >
            <h4
              className="pt-4"
              style={{
                color: "black",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Cari Event
            </h4>

            <div
              style={{
                justifyContent: "space-between",
                width: "100%", // Set width to 100%
                display: "flex",
                flexDirection: "row",
                padding: "10px 20px",
              }}
            >
              <div style={{ position: "relative", width: "100%" }}>
                <Input
                  placeholder="Cari nama event disini"
                  type="text"
                  value={keyWord}
                  onChange={(event) => setKeyWord(event.target.value)}
                />
              </div>
              <Button
                className="w-30 ml-2 text-white bg-hievent"
                color="bg-hievent"
                type="button"
                onClick={() => getEvent()}
              >
                <FaSearch />
              </Button>
            </div>

            <div
              style={{
                justifyContent: "space-between",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "10px 20px",
              }}
            >
              <button
                className="color-hievent"
                style={{
                  display: "flex",
                  height: "50px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  zIndex: 100,
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                <FaFilter size={20} color="#9870C5" />
                Filter
              </button>
              {/* <div className="d-flex flex-row align-items-center">
                <span className="color-hievent font-weight mr-2">Urutkan</span>
                <FormGroup className="m-0">
                  <Input type="select">
                    <option disabled selected value="">
                      Mulai Terdekat
                    </option>
                    <option value="option1">Opsi 1</option>
                    <option value="option2">Opsi 2</option>
                    <option value="option3">Opsi 3</option>
                  </Input>
                </FormGroup>
              </div> */}
            </div>

            <Col>
              {loading ? <Loading /> : null}

              {!loading && event.length === 0 ? (
                <div
                  style={{
                    height: "60vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NoDataImage />
                </div>
              ) : null}

              <Row className="mt-4 mt-sm-0">
                {event.map((item) => (
                  <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                ))}
              </Row>
            </Col>

            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto", // Add this property for vertical scrolling
                paddingTop: "10px",
                paddingBottom: "80px",
              }}
            >
              <Pagination listClassName="justify-content-center">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                {pageNumbers.map((pageNumber) => (
                  <PaginationItem
                    key={pageNumber}
                    className={page === pageNumber ? "active" : ""}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>

            <UserBottomBarMobile />
            <AnimatePresence>
              {isFilterOpen && (
                <FilterMobile
                  props={{
                    eventTypeIds,
                    setEventTypeIds,
                    preconditionIds,
                    setPreconditionIds,
                    eventLocTypes,
                    setEventLocTypes,
                    eventContextIds,
                    setEventContextIds,
                    setIsFilterOpen,
                    isFilterOpen,
                  }}
                />
              )}
            </AnimatePresence>
          </div>
        </>
      ) : (
        //   {/* <Breadcumbs data={['Cari-Event']} /> */}

        // {/* <div className="d-flex flex-row align-items-center mb-3">
        //   <i className="fa fa-arrow-left mr-2" />
        //   <span className="font-weight-bold color-hievent">Kembali</span>
        // </div> */}

        <Container className="pt-8" fluid>
          <Row className="mt-4">
            <Col xs="8" md="10">
              <Input
                placeholder="Cari nama event disini"
                type="text"
                value={keyWord}
                onChange={(event) => setKeyWord(event.target.value)}
              />
            </Col>
            <Col xs="4" md="2">
              <Button
                className="w-100 text-white bg-hievent"
                color="bg-hievent"
                type="button"
                onClick={() => getEvent()}
              >
                Cari
              </Button>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="4" lg="3" xl="3">
              <Filter
                props={{
                  eventTypeIds,
                  setEventTypeIds,
                  preconditionIds,
                  setPreconditionIds,
                  eventLocTypes,
                  setEventLocTypes,
                  eventContextIds,
                  setEventContextIds,
                }}
              />
            </Col>

            <Col sm="8" lg="9" xl="9">
              <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                <Pagination listClassName="justify-content-center">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page - 1);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>

                  {pageNumbers.map((pageNumber) => (
                    <PaginationItem
                      key={pageNumber}
                      className={page === pageNumber ? "active" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(pageNumber);
                        }}
                      >
                        {pageNumber}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  <PaginationItem
                    disabled={page === totalPage || event.length === 0}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page + 1);
                      }}
                    >
                      <i className="fa fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                <div className="d-flex flex-row align-items-center">
                  <span className="text-primary font-weight-bold mr-2">
                    Urutkan
                  </span>
                  <FormGroup className="m-0">
                    <Input placeholder="Terbaru" type="text" />
                  </FormGroup>
                </div>
              </div>

              {loading ? <Loading /> : null}

              {!loading && event.length === 0 ? <NoDataImage /> : null}

              <Row className="mt-4 mt-sm-0">
                {event.map((item) => (
                  <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;
