import {
    Button,
    Modal,
    Spinner,
} from "reactstrap";
import img from "../../assets/img/theme/success_register_event.svg"
import { useState } from "react";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";
import API from "utils/API";

const DeleteEventDisimpan = ({ id, getEventSaved }) => {
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const useAuth = UseAuth();

    const onDelete = () => {
        setLoading(true)
        API.delete('savedEvents/deleteSavedEvent/'+id, {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data.data)
            showToast(res.data.message, res.data.status.toLowerCase())
            getEventSaved()
            setIsOpen(false)
            setLoading(false)
        }).catch(err => {
            // console.log(err.response.data)
            setLoading(false)
            showToast(err.response.data.message, err.response.data.status.toLowerCase())
        })
    }

    return (
        <>
            {getEventSaved ?
                <Button onClick={() => setIsOpen(true)} size="sm" color="danger" className="px-2 py-1 position-absolute top-2 right-4 mt-1">
                    {loading ? <Spinner size="sm" /> : <i className="fa fa-trash fa-lg" />}
                    </Button>
                : null}

            <Modal
                className="modal-dialog-centered modal-info"
                contentClassName="bg-gradient-purple"
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <div className="text-center">
                        <h3 className="mb-0 text-white">Hapus Event Disimpan</h3>
                        <h2 className="text-white">Apa kamu yakin akan menghapus event ini?</h2>
                        <div className="d-flex align-items-center justify-content-center py-4">
                            <img className="result-image" src={img} alt="hievents" />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        onClick={() => onDelete()}
                        className="btn-white w-100" color="default" type="button">
                            {loading ? <Spinner size="sm" /> : null} Hapus
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default DeleteEventDisimpan;
