import EventTerdaftar from "components/Items/EventTerdaftar";
import Sertifikat from "components/Items/Sertifikat";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import Loading from "components/Partials/Loading";
import NoDataImage from "components/Partials/NoDataImage";
import { showToast } from "components/Partials/Toast";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useLayoutEffect } from "react";
import {
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  FormGroup,
} from "reactstrap";

import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import moment from "moment";
import EventSaya from "components/Items/EventSaya";

const Index = () => {
  const [event, setEvent] = useState([]);
  const useAuth = UseAuth();
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(1);
  const [page, setPage] = useState(1);
  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1, 10);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  useEffect(() => {
    if (useAuth.auth) getEventRegistrations();
    // console.log("event", event);
  }, [page]);

  // console.log("Event: ", event);

  const getEventRegistrations = () => {
    setLoading(true);
    API.get(`eventRegistrations/getUserEventRegistrations?page=${page}`, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        setEvent(res.data.data);
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  // Fungsi untuk mendeteksi lebar layar
  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return width;
  };

  // Hook untuk mendapatkan lebar layar saat ini
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowDimensions());

    useLayoutEffect(() => {
      const handleResize = () => {
        setWindowSize(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  };

  // Gunakan hook useWindowSize untuk mendeteksi lebar layar
  const windowWidth = useWindowSize();

  // Tentukan apakah lebar layar adalah kecil atau besar
  const isSmallScreen = windowWidth <= 768;

  const paginationStyle = isSmallScreen ? "square" : "circle";

  if (!useAuth.auth) {
    showToast("Silakan login terlebih dahulu", "warning");
    return <Redirect from="*" to="/auth" />;
  }

  if (loading) return <Loading />;

  // if (!loading && event.length === 0)
  //   return (
  //     <Container className="pt-8">
  //       <NoDataImage />
  //     </Container>
  //   );

  return (
    <>
      {isSmallScreen ? (
        <Container>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h4>Event Saya</h4>

            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ gap: "20px" }}
            >
              <span
                style={{
                  cursor: "pointer",
                  borderBottom: index === 1 ? "2px solid #9870C5" : "none",
                }}
                onClick={() => setIndex(1)}
                className={
                  index === 1
                    ? "text-md color-hievent font-weight-bold"
                    : "text-md font-weight-bold"
                }
              >
                Event Terdaftar
              </span>

              <span
                style={{
                  cursor: "pointer",
                  borderBottom: index === 2 ? "2px solid #9870C5" : "none",
                }}
                onClick={() => setIndex(2)}
                className={
                  index === 2
                    ? "text-md color-hievent font-weight-bold"
                    : "text-md font-weight-bold"
                }
              >
                Event Selesai
              </span>
            </div>
            <div
              style={{
                justifyContent: "end",
                display: "flex",
                flexDirection: "row",
                padding: "10px 20px",
              }}
            >
              {/* <div className="d-flex flex-row align-items-center">
                <span className="color-hievent font-weight mr-2">Urutkan</span>
                <FormGroup className="m-0">
                  <Input type="select">
                    <option disabled selected value="">
                      Mulai Terdekat
                    </option>
                    <option value="option1">Opsi 1</option>
                    <option value="option2">Opsi 2</option>
                    <option value="option3">Opsi 3</option>
                  </Input>
                </FormGroup>
              </div> */}
            </div>

            {/* event terdaftar */}
            {index === 1 && (
              <Row>
                {event.some((item) =>
                  moment(new Date()).isBefore(moment(item.event?.endDate))
                ) ? (
                  event.map((item) =>
                    item.status === "registered" ? (
                      <EventSaya
                        item={item}
                        xl="6"
                        lg="6"
                        md="6"
                        key={item._id}
                        tab={index}
                      />
                    ) : null
                  )
                ) : (
                  <Container>
                    <NoDataImage />
                  </Container>
                )}
              </Row>
            )}

            {/* Event Selesai */}
            {index === 2 ? (
              <Row className="mb-6">
                {event.some((item) =>
                  moment(new Date()).isAfter(moment(item.event?.endDate))
                ) ? (
                  event.map((item) =>
                    item.status !== "registered" ? (
                      <EventSaya
                        item={item}
                        xl="6"
                        lg="6"
                        md="6"
                        key={item._id}
                        tab={index}
                      />
                    ) : null
                  )
                ) : (
                  <Container>
                    <NoDataImage />
                  </Container>
                )}
              </Row>
            ) : null}

            {/* <Pagination listClassName="justify-content-center">
              <PaginationItem disabled={page === 1}>
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(page - 1);
                  }}
                  tabIndex="-1"
                >
                  <i className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>

              {pageNumbers.map((pageNumber) => (
                <PaginationItem
                  key={pageNumber}
                  className={page === pageNumber ? "active" : ""}
                >
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(pageNumber);
                    }}
                    style={{ borderRadius: 0 }} // Menjadikan tombol angka persegi
                  >
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={event.length === 0}>
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(page + 1);
                  }}
                  style={{ borderRadius: 0 }}
                >
                  <i className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination> */}
          </div>

          <UserBottomBarMobile />
        </Container>
      ) : (
        <Container className="pt-8" fluid>
          {/* <Breadcumbs data={['Event-Terdaftar']} /> */}

          <Pagination listClassName="justify-content-center">
            <PaginationItem disabled={page === 1}>
              <PaginationLink
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(page - 1);
                }}
                tabIndex="-1"
              >
                <i className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>

            {pageNumbers.map((pageNumber) => (
              <PaginationItem
                key={pageNumber}
                className={page === pageNumber ? "active" : ""}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(pageNumber);
                  }}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={event.length === 0}>
              <PaginationLink
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(page + 1);
                }}
              >
                <i className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
          <Row className="mt-4">
            {event.map((item) => (
              <EventTerdaftar item={item} xl="6" lg="6" md="6" key={item._id} />
            ))}
            {!loading && event.length === 0 && (
              <Container className="">
                <NoDataImage />
              </Container>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;
