import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import API from "utils/API"

const EventPreconditions = ({ preconditionIds, setPreconditionIds, col = "4", multiple = true }) => {
    const [preconditions, setPreconditions] = useState([])

    useEffect(() => {
        getPreconditions()
    }, [])

    const getPreconditions = () => {
        API.get('preconditions/getPreconditions', {})
            .then(res => {
                // console.log(res.data.data)
                setPreconditions(res.data.data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const onChange = (value) => {
        const temp = [...preconditionIds]
        const index = temp.findIndex(val => { return val === value })
        if (index === -1)
            temp.push(value)
        else
            temp.splice(index, 1)

        if (multiple)
            setPreconditionIds(temp)
        else {
            if (index === -1)
                setPreconditionIds([value])
            else
                setPreconditionIds([])
        }
    }

    return (
        <Row>
            {preconditions.map(element =>
                <Col key={element._id} className={col === "4" ? "my-2" : ""} md={col}>
                    <div className="custom-control custom-checkbox mb-1">
                        <input
                            className="custom-control-input"
                            id={element._id}
                            type="checkbox"
                            value={element._id}
                            checked={preconditionIds.findIndex(val => { return val === element._id }) !== -1 ? true : false}
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <label className="custom-control-label" htmlFor={element._id}>
                            {element.name}
                        </label>
                    </div>
                </Col>
            )}
        </Row>
    )
}

export default EventPreconditions