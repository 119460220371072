import { Row, Col } from "reactstrap";

const UserFooter = () => {
  return (
    <div className="pt-8 bg-white bottom-0">
      <footer className="bg-hievent px-lg-7 py-5">
        <Row className="align-items-center justify-content-lg-between m-0">
          <Col lg="8">
            <div className="copyright text-center text-lg-left text-white">
              © {new Date().getFullYear()}{" "}
              <p className="font-weight-bold">PT. Karya Generasi Nusantara</p>
              <p>
                Jl. Jenderal Sudirman Kavling 86, Kel. Karet Tengsin, Kec. Tanah
                Abang, Jakarta Pusat
              </p>
            </div>
          </Col>

          <Col lg="4">
            <div className="copyright text-center text-lg-left text-white">
              <p className="font-weight-bold">Bantuan & Panduan</p>

              <p>Syarat & Ketentuan</p>
              <p>Kebijakan Privasi</p>
              <p>Disclaimer</p>
              <a className="text-white" href="mailto:hievents.cs@gmail.com">
                Hubungi Kami
              </a>
            </div>
          </Col>

          {/* <Col lg="4">
                        <div className="copyright text-center text-lg-left text-white">
                            <p
                                className="font-weight-bold"
                            >Download Aplikasi
                            </p>

                            <p>Google Play Store</p>
                            <p>App Store</p>

                        </div>
                    </Col> */}
        </Row>
      </footer>
    </div>
  );
};

export default UserFooter;
