import moment from "moment";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import "./event.css";

import { Card, CardBody, Badge, Row, Col, Media, Button } from "reactstrap";
import DeleteEventDisimpan from "components/Modals/DeleteEventDisimpan";
import ActionEventDraft from "components/Modals/ActionEventDraft";
import { capitalizeFirstLetter } from "utils/helpers";
import { formatToIDR } from "utils/helpers";

const Event = ({
  item,
  xl,
  lg,
  md,
  eo,
  getEventSaved,
  getEventDraft,
  isOnGoing,
}) => {
  const navigate = useHistory();
  let id = item._id;
  if (item.event) {
    id = item._id;
    item = item.event;
  }

  return (
    <Col className="mb-3" xl={xl} lg={lg} md={md}>
      <Card
        onClick={() =>
          navigate.push(eo ? `/event/${item.slug}=` : "/event/" + item.slug)
        }
        style={{ cursor: "pointer" }}
        className="shadow"
      >
        <CardBody className="p-0">
          <Row style={{ display: "flex" }}>
            <Col
              lg="5"
              className="align-items-center justify-content-center"
              style={{ minHeight: 200, maxHeight: 380 }}
            >
              <img
                className="img-fluid w-100 h-100 rounded-lg"
                src={item?.image?.url}
                alt="hievents"
              />
            </Col>
            <Col lg="7" className="pl-2 pr-4 py-3 mx-4 mx-lg--2">
              <Media className="align-items-center mb-1">
                <Avatar
                  className="logo-eo"
                  name={item?.eventOrganizer?.name}
                  src={item?.eventOrganizer?.logo?.url}
                  round={true}
                  size={45}
                />
                <Media className="ml-2">
                  <span className="mb-0 text-sm text-success font-weight-bold">
                    {item?.eventOrganizer?.name}
                  </span>
                </Media>
              </Media>
              <h4 className="m-0 title">{item?.title}</h4>
              <h4 className="text-success d-flex flex-column">
                <span>{capitalizeFirstLetter(item?.paidStatus) + " "} </span>
                {item?.cost > 0 && (
                  <span style={{ color: "black" }}>
                    {formatToIDR(item?.cost)}
                  </span>
                )}
              </h4>
              <Row className="mt--2">
                <Col xs="6">
                  <small className="text-xs">Batas pendaftaran</small>
                </Col>
                <Col xs="6">
                  <small className="text-xs">
                    {moment(item?.deadlineRegistration).format(
                      "DD MMM YYYY HH:mm"
                    )}
                  </small>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <small className="text-xs">Mulai Event</small>
                </Col>
                <Col xs="6">
                  <small className="text-xs">
                    {moment(item?.startDate).format("DD MMM YYYY HH:mm")}
                  </small>
                </Col>
              </Row>
              <div className="d-flex flex-row flex-wrap">
                {item?.benefitIds?.map((element, index) => {
                  return index <= 5 ? (
                    <Badge
                      key={element._id}
                      color={
                        index % 2 === 0 ? "success ml-1 mt-2" : "info ml-1 mt-2"
                      }
                      pill
                    >
                      {element.name}
                    </Badge>
                  ) : null;
                })}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {isOnGoing ? (
        <div className="px-2 py-1 position-absolute top-1 right-3">
          <Button
            onClick={() =>
              window.open("https://wa.me/+6282125503653", "_blank")
            }
            color="warning"
            className="btn-sm px-2"
          >
            Request Hapus/Edit Event
          </Button>
        </div>
      ) : null}
      <DeleteEventDisimpan id={id} getEventSaved={getEventSaved} />
      <ActionEventDraft
        id={id}
        slug={item.slug}
        getEventDraft={getEventDraft}
      />
    </Col>
  );
};

export default Event;
