import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import UserNavbar from "components/Navbars/UserNavbar.js";
import UserSidebar from "components/Sidebar/UserSidebar.js";
import UserFooter from "components/Footers/UserFooter";

import routes from "../routes/routes.js";
import UserNavbarMobile from "components/Navbars/UserNavbarMobile.js";
import FloatingButtonFAQ from "components/Partials/FloatingButtonFAQ.js";
import Faq from "views/faq/Index.js";

const User = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const isSmallScreen = windowSize < 768;
  return isSmallScreen ? (
    <>
      <div className="main-content bg-white" ref={mainContent}>
        <FloatingButtonFAQ />
        {location.pathname === "/" && (
          <UserNavbarMobile {...props} routes={routes} brandText="HiEvents" />
        )}
        {/* <UserNavbar {...props} routes={routes} brandText="HiEvents" /> */}
        <Switch>
          {getRoutes(routes)}
          <Route path="/faq" component={() => <Faq />} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>

      {/* <UserFooter /> */}
      <ToastContainer />
    </>
  ) : (
    <>
      <FloatingButtonFAQ />
      <UserSidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/favicon.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content bg-white" ref={mainContent}>
        <UserNavbar {...props} routes={routes} brandText="HiEvents" />
        <Switch>
          {getRoutes(routes)}
          <Route path="/faq" component={() => <Faq />} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>

      <UserFooter />
      <ToastContainer />
    </>
  );
};

export default User;
