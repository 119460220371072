const initialState = {
    eoState: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_EO':
            return {
                ...state,
                eoState: action.value
            }

        case 'RESET_STATE':
            return initialState

        default:
            return state;
    }
}