import { useEffect, useState } from "react";
import Event from "components/Items/Event";
import {
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
// import Breadcumbs from "components/Partials/Breadcumbs";
import NoDataImage from "components/Partials/NoDataImage";
import Loading from "components/Partials/Loading";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Redirect } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import NoSavedEvent from "components/Partials/NoSavedEvent";

const Index = () => {
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);

  const useAuth = UseAuth();
  const navigate = useHistory;

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1, totalPage);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  useEffect(() => {
    if (useAuth.auth) getEventSaved();
  }, []);

  const getEventSaved = () => {
    setLoading(true);
    API.get("savedEvents/getSavedEvents", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        setEvent(res.data.data);
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  if (!useAuth.auth) return <Redirect from="*" to="/auth" />;

  if (loading) return <Loading />;

  const isSmallScreen = window.innerWidth < 768;

  if (!loading && event.length === 0 && !isSmallScreen) return <NoDataImage />;

  // console.log("event disimpan", event);

  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            style={{
              padding: "10px 20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/* Navigasi kembali */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                padding: "5px",
              }}
            >
              <Link
                outline
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "5px",
                  outline: "none",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "black",
                }}
                to="/"
              >
                <MdKeyboardArrowLeft size={20} />
                <span
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                >
                  Event Disimpan
                </span>
              </Link>
            </div>

            {event.length === 0 ? (
              <NoSavedEvent />
            ) : (
              <Row>
                {event.map((item) => (
                  <Event
                    item={item}
                    xl="12"
                    lg="12"
                    md="12"
                    getEventSaved={getEventSaved}
                    key={item._id}
                  />
                ))}
              </Row>
            )}

            {event.length !== 0 && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowY: "auto",
                  paddingTop: "5px",
                  paddingBottom: "70px",
                }}
              >
                <Pagination listClassName="justify-content-center">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page - 1);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>

                  {pageNumbers.map((pageNumber) => (
                    <PaginationItem
                      key={pageNumber}
                      className={page === pageNumber ? "active" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(pageNumber);
                        }}
                      >
                        {pageNumber}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page + 1);
                      }}
                    >
                      <i className="fa fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            )}
          </div>

          <UserBottomBarMobile />
        </>
      ) : (
        <Container className="pt-8" fluid>
          {/* <Breadcumbs data={['Event-Disimpan']} /> */}
          <Row>
            {event.map((item) => (
              <Event
                item={item}
                xl="6"
                lg="6"
                md="6"
                getEventSaved={getEventSaved}
                key={item._id}
              />
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;
