import { useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, Spinner } from "reactstrap"
import { UseAuth } from "routes/useAuth"
import API from "utils/API"
import { showToast } from "./Toast"

const Follow = ({eventOrganizer, SET_FOLLOWING}) => {
    const [loading, setLoading] = useState(false)

    const useAuth = UseAuth();
    const navigation = useHistory();

    const createFollow = () => {
        if(!useAuth.auth){
            // return showToast("Silahkan masuk aplikasi", "error")
            return navigation.push('/auth')
        }

        setLoading(true)
        const data = {
            eventOrganizerId: eventOrganizer
        }
        API.post('follows/createFollow', data, {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data.data)
            setLoading(false)
            getFollowing()
            showToast(res.data.message, res.data.status.toLowerCase())
        }).catch(err => {
            // console.log(err.response.data)
            setLoading(false)
            showToast(err.response.data.message, err.response.data.status.toLowerCase())
        })
    }

    const getFollowing = () => {
        API.get('follows/getFollowing', {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data)
            SET_FOLLOWING(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

  return (
    <Button onClick={() => createFollow()} className="btn btn-sm px-3 text-white bg-hievent" color="bg-hievent">{loading ? <Spinner size="sm" /> : null} Ikuti</Button>
  )
}


const mapStateToProps = (state) => {
    return {
        FOLLOWING: state.followingReducer.followingState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        SET_FOLLOWING: (value) => dispatch({ type: 'SET_FOLLOWING', value: value }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Follow)