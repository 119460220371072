import { useEffect, useState } from "react";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import NoDataImage from "components/Partials/NoDataImage";
// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import { Redirect } from "react-router-dom";
import ModalImage from "react-modal-image";
import moment from "moment";

const Sertifikat = ({ match }) => {
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const getUserCertificates = () => {
    setLoading(true);
    API.get("certificates/getCertificate/" + match.params.id, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data)
        setLoading(false);
        setEvent(res.data.data);
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        // showToast(err.response.data.message, err.response.data.status.toLowerCase())
      });
  };

  useEffect(() => {
    if (useAuth.auth) getUserCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id,useAuth.auth]);

  if (!useAuth.auth) return <Redirect from="*" to="/auth" />;

  if (loading) return <Loading />;

  return (
    <Container className="pt-8" fluid>
      {/* <Breadcumbs data={['Sertifikat']} /> */}

      <Card className="shadow mt-5">
        <CardBody className="p-0 rounded-top">
          <h3 className="p-3 bg-primary rounded-top text-center text-white">
            Keaslian Sertifikat
          </h3>
          <Row className="p-4">
            {!loading && event.length === 0 ? (
              <NoDataImage
                type="certificate"
                message="Ups, Sertifikat event ini tidak dapat ditemukan"
                pt=""
              />
            ) : (
              <>
                <Col
                  lg="5"
                  className="align-items-center justify-content-center"
                >
                  <ModalImage
                    className="img-fluid w-100 h-100 rounded-lg"
                    small={event.eventId?.image?.url}
                    large={event.eventId?.image?.url}
                    alt="hievents"
                  />
                </Col>
                <Col lg="6">
                  <h4>Detail Sertifikat Peserta</h4>
                  <p>
                    Platform hievents.co dengan keterangan ini menyatakan bahwa
                    peserta event dibawah ini telah mengikuti event yang
                    diadakan pada platform hievent.co
                  </p>

                  <h4 className="mt-3">Detail Peserta</h4>
                  <Row>
                    <Col xs="4" lg="3">
                      User Name
                    </Col>
                    <Col xs="6" lg="8">
                      : {event.userId?.username}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" lg="3">
                      Nama
                    </Col>
                    <Col xs="6" lg="8">
                      : {event.userId?.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" lg="3">
                      ID Sertifikat
                    </Col>
                    <Col xs="6" lg="8">
                      : <Badge color="success px-3">{event._id}</Badge>
                    </Col>
                  </Row>

                  <h4 className="mt-3">
                    Telah mengikuti dan menghadiri event berikut
                  </h4>
                  <Row>
                    <Col xs="4" lg="3">
                      Judul Event
                    </Col>
                    <Col xs="6" lg="7">
                      : {event.eventId?.title}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" lg="3">
                      Tanggal
                    </Col>
                    <Col xs="6" lg="7">
                      : {moment(event.eventId?.startDate).format("DD MMM YYYY")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" lg="3">
                      Penyelenggara
                    </Col>
                    <Col xs="6" lg="7">
                      :{" "}
                      <Badge color="success px-3">
                        {event.eventId.userId.name}
                      </Badge>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Sertifikat;
