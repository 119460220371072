import {
  Badge,
  Button,
  Modal,
  InputGroupAddon,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { GoUpload } from "react-icons/go";
import { formatToIDR } from "utils/helpers";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { showToast } from "components/Partials/Toast";
import PaidResult from "./PaidResult";
import API from "utils/API";
import Banks from "components/Partials/Banks";
import { UseAuth } from "routes/useAuth";

const PaidProgress = ({ props }) => {
  const navigation = useHistory();
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [proofPaid, setProofPaid] = useState(null);
  const [isPaidSuccess, setIsPaidSuccess] = useState(null);
  const [prerequisiteId, setPrerequisiteId] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [image, setImage] = useState([]);
  const [paymentType, setPaymentType] = useState("transfer");
  const useAuth = UseAuth();

  const isSmallScreen = window.innerWidth <= 640;
  const copyLink = () => {
    navigator.clipboard.writeText("0700070888800");
    showToast("Berhasil menyalin nomor rekening", "success");
  };

  useEffect(() => {
    setBank("");
    setName("");
    setBankNumber("");
    setProofPaid(null);
    if (props.prerequisiteId && props.answer && props.image) {
      setPrerequisiteId(props?.prerequisiteId);
      setAnswer(props?.answer);
      setImage(props?.image);
    }
  }, [isPaidSuccess]);

  const checkout = () => {
    setLoading(true);
    if (!useAuth.auth) return navigation.push("/auth");
    const data = new FormData();
    data.append("bankId", bank);
    data.append("accountName", name);
    data.append("accountNumber", bankNumber);
    data.append("attachment", proofPaid);
    data.append("amount", props.event.cost);
    data.append("eventId", props.event._id);
    data.append("paymentType", paymentType);

    API.post("/payment/checkout", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data);
        if (props?.havePrerequisite) {
          props?.handleSubmit();
          setIsPaidSuccess(true);
          props.setIsOpen(!props.isOpen);
          showToast(res.data.message, res.data.status.toLowerCase());
        } else {
          registerEvent();
          setIsPaidSuccess(true);
          props.setIsOpen(!props.isOpen);
          showToast(res.data.message, res.data.status.toLowerCase());
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsPaidSuccess(false);
        props.setIsOpen(!props.isOpen);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const registerEvent = () => {
    if (!useAuth.auth) return navigation.push("/auth");

    const data = {
      eventId: props.event._id,
    };
    API.post("eventRegistrations/registerEvent", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  if (isPaidSuccess === null) {
    return (
      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-white"
        isOpen={props.isOpen}
        toggle={() => props.setIsOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.setIsOpen(!props.isOpen)}
          >
            <span
              aria-hidden={true}
              style={{
                color: "black",
              }}
            >
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              padding: "10px 30px 30px 30px",
            }}
          >
            <h2 className="mb-0 text-black">Pilih Metode Pembayaran</h2>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 30px",
                boxShadow: "0px 0px 5px #C4C4C4",
                borderRadius: "5px",
                textAlign: "center",
                gap: "10px",
              }}
            >
              <h2 className="mb-0 text-black">{props.event.title}</h2>
              <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                {formatToIDR(props.event.cost)}
              </h3>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.25 2.5H11.875C12.5625 2.5 13.125 3.0625 13.125 3.75V12.5C13.125 13.1875 12.5625 13.75 11.875 13.75H3.125C2.43125 13.75 1.875 13.1875 1.875 12.5L1.88125 3.75C1.88125 3.0625 2.43125 2.5 3.125 2.5H3.75V1.25H5V2.5H10V1.25H11.25V2.5ZM3.125 6.25V12.5H11.875V6.25H3.125ZM11.875 5H3.125V3.75H11.875V5ZM10.625 8.125H7.5V11.25H10.625V8.125Z"
                      fill="#737373"
                    />
                  </svg>
                  <small style={{ color: "#737373" }} className="text-sm">
                    Batas pendaftaran
                  </small>
                </div>
                <small style={{ color: "#737373" }} className="text-sm">
                  {moment(props.event.deadlineRegistration).format(
                    "DD MMMM YYYY"
                  )}
                </small>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.875 13.75C12.5625 13.75 13.125 13.1875 13.125 12.5V3.75C13.125 3.0625 12.5625 2.5 11.875 2.5H11.25V1.25H10V2.5H5V1.25H3.75V2.5H3.125C2.43125 2.5 1.88125 3.0625 1.88125 3.75L1.875 12.5C1.875 13.1875 2.43125 13.75 3.125 13.75H11.875ZM5.625 8.125V6.875H4.375V8.125H5.625ZM3.125 5H11.875V3.75H3.125V5ZM11.875 6.25V12.5H3.125V6.25H11.875ZM10.625 8.125V6.875H9.375V8.125H10.625ZM8.125 8.125H6.875V6.875H8.125V8.125Z"
                      fill="#737373"
                    />
                  </svg>
                  <small style={{ color: "#737373" }} className="text-sm">
                    Mulai Event
                  </small>
                </div>
                <small style={{ color: "#737373" }} className="text-sm">
                  {moment(props.event.startDate).format("DD MMMM YYYY")}
                </small>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.14518 1.06496L3.24329 0L0 2.66525L0.901825 3.73022L4.14518 1.06496ZM10.7566 0.000976562L13.9999 2.66623L13.098 3.73119L9.85469 1.06594L10.7566 0.000976562ZM7.35194 4.29206H6.29602V8.45262L9.63976 10.4289L10.1677 9.57597L7.35194 7.93255V4.29206ZM6.99994 1.51849C3.50133 1.51849 0.664429 4.313 0.664429 7.75933C0.664429 11.2057 3.50133 14.0002 6.99994 14.0002C10.4985 14.0002 13.3355 11.2057 13.3355 7.75933C13.3355 4.313 10.4985 1.51849 6.99994 1.51849ZM2.07239 7.7588C2.07239 10.4354 4.28278 12.6128 7.00001 12.6128C9.71724 12.6128 11.9276 10.4354 11.9276 7.7588C11.9276 5.08218 9.71724 2.90482 7.00001 2.90482C4.28278 2.90482 2.07239 5.08218 2.07239 7.7588Z"
                      fill="black"
                      fill-opacity="0.54"
                    />
                  </svg>
                  <small style={{ color: "#737373" }} className="text-sm">
                    Pukul
                  </small>
                </div>
                <small style={{ color: "#737373" }} className="text-sm">
                  {moment(props.event.startDate).format("LT") +
                    " - " +
                    moment(props.event.endDate).format("LT")}
                </small>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <Badge color="warning p-2 border border-warning" pill>
                  {props.event.eventType?.name}
                </Badge>
                <Badge color="success p-2 border border-success" pill>
                  {props.event.eventContext?.name}
                </Badge>
                <Badge color="info p-2 border border-info" pill>
                  {props.event.location}
                </Badge>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 30px",
                boxShadow: "0px 0px 5px #C4C4C4",
                borderRadius: "5px",
                gap: "10px",
              }}
            >
              <h2 style={{ fontSize: "16px" }} className="mb-0 text-black">
                Jumlah yang harus dibayar
              </h2>
              <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                {formatToIDR(props.event.cost)}
              </h3>
            </div>
            <div
              style={{
                width: "100%",
                padding: "20px 30px",
                boxShadow: "0px 0px 5px #C4C4C4",
                borderRadius: "5px",
                color: "black",
              }}
            >
              <table>
                <tr>
                  <td>
                    <h3
                      className="color-black mb-0"
                      style={{ fontWeight: 600 }}
                    >
                      Transfer ke :
                    </h3>
                  </td>
                  <td>
                    <h3
                      className="color-black mb-0"
                      style={{ fontWeight: 400, padding: "0px 13px" }}
                    >
                      Mandiri
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div
                      className="color-black mb-0"
                      style={{ fontWeight: 400, padding: "0px 13px" }}
                    >
                      070 0070 8888 00
                    </div>
                    <div
                      style={{ cursor: "pointer", color: "#1142CD" }}
                      onClick={() => copyLink()}
                    >
                      Salin
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div
                      className="color-black mb-0"
                      style={{ fontWeight: 400, padding: "0px 13px" }}
                    >
                      PT Karya Generasi Nusantara
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 30px",
                boxShadow: "0px 0px 5px #C4C4C4",
                borderRadius: "5px",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingBottom: "10px",
                  borderBottom: "solid 1px #C4C4C4",
                }}
              >
                <h2
                  style={{ fontSize: "16px", width: "100%", textAlign: "left" }}
                  className="mb-0 text-black"
                >
                  Data bank anda
                </h2>
                <small style={{ color: "#737373" }} className="text-sm">
                  Lanjutkan pembayaran dengan
                </small>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    width: "100%",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "500",
                  }}
                  className="mb-0 text-black"
                >
                  Pilih bank anda
                </p>
                <InputGroup
                  style={{ width: "150%" }}
                  className="input-group-alternative bg-white"
                >
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <Banks bankId={bank} setBankId={setBank} />
                </InputGroup>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    width: "100%",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "500",
                  }}
                  className="mb-0 text-black"
                >
                  Atas nama
                </p>
                <Input
                  style={{
                    width: "140%",
                    color: "#737373",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Masukkan nama anda"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    width: "100%",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "500",
                  }}
                  className="mb-0 text-black"
                >
                  No rekening anda
                </p>
                <Input
                  style={{
                    width: "140%",
                    color: "#737373",
                  }}
                  value={bankNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const validate = inputValue.replace(/[^0-9]/g, "");
                    setBankNumber(validate);
                  }}
                  type="text"
                  placeholder="Masukkan no rekening anda"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <h2 style={{ fontSize: "16px" }} className="mb-1 text-black">
                Upload bukti pembayaran
              </h2>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px 0px",
                  boxShadow: "0px 0px 5px #C4C4C4",
                  borderRadius: "5px",
                  gap: "15px",
                  color: "#000000",
                }}
              >
                {proofPaid === null ? (
                  <GoUpload size={35} />
                ) : (
                  <small
                    style={{ color: "#737373", textAlign: "center" }}
                    className="text-sm"
                  >
                    {proofPaid.name}
                  </small>
                )}

                <label
                  htmlFor="proof-image"
                  style={{
                    padding: "5px 35px",
                    color: "white",
                    border: "solid 1px black",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor: `${
                      proofPaid === null ? "#9870C5" : "#FECA13"
                    }`,
                  }}
                >
                  {proofPaid === null ? "Pilih file" : "Ganti file"}
                </label>
                <input
                  hidden
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  id="proof-image"
                  className="form-control"
                  onChange={(event) => setProofPaid(event.target.files[0])}
                />
              </div>
            </div>

            <Button
              className=" bg-hievent"
              color="bg-hievent"
              disabled={
                bank === "" ||
                name === "" ||
                bankNumber === "" ||
                proofPaid === null
              }
              onClick={() => checkout()}
              style={{
                width: "100%",
                border: "solid 1px black",
                color: "white",
              }}
            >
              {loading ? <Spinner size="sm" /> : "Daftar"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <PaidResult
        event={props.event}
        isPaidSuccess={isPaidSuccess}
        setIsPaidSucces={setIsPaidSuccess}
      />
    );
  }
};

export default PaidProgress;
