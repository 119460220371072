import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import API from "utils/API"

const EventLanguages = ({ languageIds, setLanguageIds }) => {
    const [languages, setLanguages] = useState([])

    useEffect(() => {
        getEventLanguages()
    }, [])

    const getEventLanguages = () => {
        API.get('languages/getLanguages', {})
            .then(res => {
                // console.log(res.data.data)
                setLanguages(res.data.data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const onChange = (value) => {
        const temp = [...languageIds]
        const index = temp.findIndex(val => { return val === value })
        if (index === -1)
            temp.push(value)
        else
            temp.splice(index, 1)
        setLanguageIds(temp)
    }

    return (
        <Row>
            {languages.map(element =>
                <Col key={element._id} className="my-2" md="4">
                    <div className="custom-control custom-checkbox mb-1">
                        <input
                            className="custom-control-input"
                            id={element._id}
                            type="checkbox"
                            value={element._id}
                            defaultChecked={languageIds.findIndex(val => { return val === element._id }) !== -1 ? true : false}
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <label className="custom-control-label" htmlFor={element._id}>
                            {element.name}
                        </label>
                    </div>
                </Col>
            )}
        </Row>
    )
}

export default EventLanguages