import { useHistory } from "react-router-dom";
import { UseAuth } from "routes/useAuth";
import API from "utils/API"
import { showToast } from "./Toast"

const EventSave = (eventId) => {
    const useAuth = UseAuth()
    const navigation = useHistory()

    const saveEvent = () => {
        if(!useAuth.auth)
            return navigation.push('/auth')

        API.post('savedEvents/addSavedEvent', {eventId: eventId.eventId}, { headers: { Authorization: "Bearer " + useAuth.auth.accessToken } }).then(res => {
            // console.log(res.data.data)
            showToast(res.data.message, res.data.status.toLowerCase())
        }).catch(err => {
            // console.log(err.response.data)
            showToast(err.response.data.message, err.response.data.status.toLowerCase())
        })
    }

    return(
        <span onClick={() => saveEvent()} className="color-hievent ml-2 mr-sm-3" style={{ cursor: 'pointer' }}><i className="fa fa-bookmark fa-lg" /> Simpan</span>
    )
}

export default EventSave;