import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Cities from "components/Partials/Cities";
import SuccessImage from "components/Partials/SuccessImage";
import { showToast } from "components/Partials/Toast";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Progress,
  Button,
  Spinner,
} from "reactstrap";
import API from "utils/API";
import { alphanumeric } from "utils/helpers";
import { useMediaQuery } from "react-responsive";

const StepI = ({ props }) => {
  return (
    <Form role="form">
      <FormGroup>
        <InputGroup className="input-group-alternative mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-single-02" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Username"
            type="text"
            value={props.username}
            onChange={(event) =>
              props.setUsername(alphanumeric(event.target.value))
            }
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email"
            type="email"
            autoComplete="new-email"
            value={props.email}
            onChange={(event) => props.setEmail(event.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type="password"
            autoComplete="new-password"
            value={props.password}
            onChange={(event) => props.setPassword(event.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Konfirmasi Password"
            type="password"
            autoComplete="new-password"
            value={props.confirmPassword}
            onChange={(event) => props.setConfirmPassword(event.target.value)}
          />
        </InputGroup>
      </FormGroup>
    </Form>
  );
};

const StepII = ({ props }) => {
  return (
    <Form role="form">
      <FormGroup>
        <InputGroup className="input-group-alternative mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-single-02" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Nama Lengkap"
            type="text"
            value={props.name}
            onChange={(event) => props.setName(event.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative mb-3 bg-white">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-square-pin" />
            </InputGroupText>
          </InputGroupAddon>
          <Cities cityId={props.cityId} setCityId={props.setCityId} />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative mb-3 bg-white">
          <InputGroupAddon addonType="prepend" style={{ borderRadius: 0 }}>
            <InputGroupText>
              <i className="ni ni-mobile-button" />
            </InputGroupText>
          </InputGroupAddon>
          <PhoneInput
            className="form-control"
            defaultCountry="ID"
            international
            countryCallingCodeEditable={false}
            placeholder="Nomor Whatsapp"
            value={props.phone}
            onChange={(val) => props.setPhone(val)}
          />
        </InputGroup>
      </FormGroup>
    </Form>
  );
};

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [name, setName] = useState("");
  const [cityId, setCityId] = useState("");
  const [phone, setPhone] = useState("");

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const isMdScrenn = useMediaQuery({ query: "(min-width: 768px)" });

  const onRegister = () => {
    if (password !== confirmPassword) {
      showToast("Password doesn't match", "error");
      return;
    }

    setLoading(true);
    const data = {
      email,
      username,
      password,
      name,
      cityId,
      phone,
      continueUrl: process.env.REACT_APP_currentURL,
    };

    API.post("auths/register", data, {})
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setStep(3);
        // res.data.message
        showToast(
          "Pendaftaran akun berhasil, silahkan login :)",
          res.data.status.toLowerCase()
        );
      })
      .catch((err) => {
        console.log("Data Error : ", err);
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onNext = () => {
    if (step === 1) {
      if (password !== confirmPassword)
        showToast("Password doesn't match", "error");
      else setStep(2);
    } else onRegister();
  };

  return (
    <>
      <Col xl="9" lg="10" md="11">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {step <= 2 ? (
              <div className="text-center text-muted mb-4">
                <h1>Daftar</h1>
                <div>
                  Daftar untuk mendapatkan pengalaman mengikuti event terbaik
                </div>
              </div>
            ) : (
              <div className="text-center text-muted mb-4">
                <h1>Daftar Berhasil</h1>
                <div>Selamat kamu berhasil mendaftar di HiEvents</div>
                <div>Nikmati beragam event menarik terupdate</div>
              </div>
            )}

            {isMdScrenn ? (
              <Progress className="mb-4" value={step === 1 ? 50 : 100} />
            ) : null}

            {step === 1 ? (
              <StepI
                props={{
                  username,
                  setUsername,
                  email,
                  setEmail,
                  password,
                  setPassword,
                  confirmPassword,
                  setConfirmPassword,
                }}
              />
            ) : step === 2 ? (
              <>
                <div className="text-center mb-3">
                  <small className="text-danger">
                    *Pastikan Nama Lengkap diisi dengan Benar, karena akan
                    mempengaruhi pada saat Absensi Event.(Pastikan penggunaan
                    Huruf kapital dalam nama lengkap sesuai).
                  </small>
                </div>
                <StepII
                  props={{ name, setName, phone, setPhone, cityId, setCityId }}
                />
              </>
            ) : (
              <>
                <SuccessImage />
                <div className="text-center text-muted">
                  Silakan cek email (inbox atau spam) untuk mengaktifkan akun,
                  dengan cara klik link aktivasi yang telah dikirim
                </div>
              </>
            )}

            {step < 3 ? (
              <div className="mt-5 d-flex flex-row">
                {step === 2 ? (
                  <Button
                    onClick={() => setStep(1)}
                    className="w-100 text-white bg-hievent"
                    color="bg-hievent"
                    type="button"
                  >
                    Kembali
                  </Button>
                ) : null}

                <Button
                  onClick={() => onNext()}
                  disabled={loading}
                  className="w-100 text-white bg-hievent"
                  color="bg-hievent"
                  type="button"
                >
                  {loading ? <Spinner size="sm" /> : null}{" "}
                  {step === 1 ? "Berikutnya" : "Register"}
                </Button>
              </div>
            ) : null}

            <div className="text-center mt-4">
              <small className="mr-1">Sudah punya akun?</small>
              <Link to="/auth/login">
                <small>Masuk</small>
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
