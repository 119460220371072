import { jsPDF } from "jspdf";

export const CertificatePreview = (
  event,
  id = "certificate",
  name = "certificate-preview"
) => {
  var doc = new jsPDF({
    orientation: "landscape",
    // unit: "px",
    format: [297, 217],
  });
  var elementHTML = document.getElementById(id);
  const htmlHeight = elementHTML.clientHeight;
  const pdfHeight =
    (htmlHeight / elementHTML.clientWidth) * doc.internal.pageSize.getWidth();
  doc.html(elementHTML, {
    callback: function (doc) {
      doc.save(name + ".pdf");
    },
    // x: 0,
    // y: 0,
    x: 5,
    y: 3.5,
    // width: 204,
    // windowWidth: 840,
    width: 287.5,
    // width: doc.internal.pageSize.getWidth(),
    windowWidth: 840,
  });

  event.preventDefault();
};
