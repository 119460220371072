import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";

import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Media
} from "reactstrap";
import API from "utils/API";

const WebinarPilihan = () => {
    const [eventContexts, setEventContexts] = useState([])
    const [gambar] = useState([
        { name: 'pengembangan-diri', color: 'bg-warning', image: require("../../assets/img/theme/context_pengembangan.svg").default },
        { name: 'aktivisme-dan-pembahasan-isu', color: 'bg-danger', image: require("../../assets/img/theme/context_isu.svg").default },
        { name: 'kesehatan-dan-kebugaran', color: 'bg-info', image: require("../../assets/img/theme/context_kesehatan.svg").default },
        { name: 'bisnis-dan-keuangan', color: 'bg-success', image: require("../../assets/img/theme/context_bisnis.svg").default },
        { name: 'pendidikan', color: 'bg-purple', image: require("../../assets/img/theme/context_pendidikan.svg").default },
        { name: 'teknologi', color: 'bg-blue', image: require("../../assets/img/theme/context_teknologi.svg").default },
    ])

    const navigate = useHistory()

    useEffect(() => {
        getEventTypes()
    }, [])

    const getEventTypes = () => {
        API.get('eventContexts/getEventContexts', {})
            .then(res => {
                // console.log(res.data.data)
                setEventContexts(res.data.data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const getImage = (data) => {
        const dataImage = gambar.find(element => element.name.includes(data.slug))

        return dataImage ? {image : dataImage.image, color: dataImage.color} : {image: gambar[0].image, color: gambar[0].color}
    }

    return (
        <>
            {/* Page content */}
            <Container className="mt-5" fluid>
                <h2 className="text-center color-hievent">Webinar Pilihan</h2>
                <Row className="mt-4 justify-content-center">
                    {eventContexts.map((element, index) =>
                        index < 6 ?
                            <Col onClick={() => navigate.push("/events/pilihan=" + element.slug)}
                                key={element._id} className="mb-3" xl="4" md="6">
                                <Card style={{ cursor: 'pointer' }} className="shadow">
                                    <CardBody className="d-flex">
                                        <Media className="align-items-center">
                                            <Avatar className={`${getImage(element).color}`} name={element.name} src={getImage(element).image} round={true} size={45}/>
                                            <Media className="ml-3">
                                                <span className="mb-0 text-md font-weight-bold">
                                                    {element.name}
                                                </span>
                                            </Media>
                                        </Media>
                                    </CardBody>

                                    {/* <CardBody className="p-0 d-flex justify-content-center">
                                        <img className="p-3 p-xl-5" src={getImage(element)} style={{ height: 300, objectFit: 'cover' }} />
                                        <div className="bg-gradient-primary pt-2 pb-1 position-absolute bottom-0 left-0 right-0 mb-0 rounded-bottom border-0">
                                            <h3 className="text-white text-center">{element.name}</h3>
                                        </div>
                                    </CardBody> */}
                                </Card>
                            </Col>
                            : null
                    )}
                </Row>
            </Container>
        </>
    );
};

export default WebinarPilihan;
