import { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { showToast } from "components/Partials/Toast";
import moment from "moment";
import WalletIcon from "components/Partials/WalletIcon";
import { IoDownloadOutline } from "react-icons/io5";
import { formatToIDR } from "utils/helpers";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import NoDataImage from "components/Partials/NoDataImage";
import Loading from "components/Partials/Loading";
import SetUpBankAccount from "components/Modals/SetUpBankAccount";
import WithdrawProccess from "components/Modals/WithdrawProccess";

const Withdraw = () => {
  const [index, setIndex] = useState(1);
  const [eventOrganizer, setEventOrganizer] = useState({});
  const [banks, setBanks] = useState([]);
  const [bankAccount, setBankAccount] = useState({});
  const [bankName, setBankName] = useState({});
  const [loading, setLoading] = useState(false);
  const [incomingBalance, setIncomingBalance] = useState([]);
  const [outcomingBalance, setOutcomingBalance] = useState([]);
  const navigation = useHistory();
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  const useAuth = UseAuth();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const isSmallScreen = windowSize < 768;

  useEffect(() => {
    getEventOrganizer();
    getBankAccount();
    getAllWithdrawal();
    // getIncomingBalance();
    getBanks();
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [page]);

  const getEventOrganizer = () => {
    setLoading(true);
    API.get("eventOrganizers/getEventOrganizer", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        setLoading(false);
        setEventOrganizer(res.data.data);
        getIncomingBalance();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  const getBankAccount = () => {
    setLoading(true);
    API.get("/bankAccount", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        // console.log(res.data.data);
        setBankAccount(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getIncomingBalance = () => {
    API.get(
      "/payment/orderByUserId/" +
        eventOrganizer.userId +
        `?page=${page}&perPage=10`,
      {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      }
    )
      .then((res) => {
        // setLoading(false);
        // console.log(res.data.data);
        setIncomingBalance(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAllWithdrawal = () => {
    setLoading(true);
    API.get(`/withdrawal/getAllWithdrawalsByUserId?page=${page}&perPage=10`, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        // console.log(res.data.data);
        setOutcomingBalance(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getBanks = () => {
    API.get("/master/banks")
      .then((res) => {
        // console.log(res.data.data);
        // setBanks(res.data.data);
        setBanks(res.data.data);
      })
      .catch((err) => {
        showToast(err.response.data.message, "error");
      });
  };

  if (!useAuth.auth) {
    return <Redirect from="*" to="/auth" />;
  }

  if (loading) return <Loading />;

  return (
    <>
      {isSmallScreen ? (
        <>
          <div></div>
        </>
      ) : (
        <Container className="pt-8" fluid>
          <div
            style={{
              width: "100%",
            }}
          >
            <button
              className="color-hievent"
              style={{
                backgroundColor: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "fit-content",
                padding: 10,
              }}
              onClick={() => navigation.goBack()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
                  fill="#9870C5"
                />
              </svg>
              Kembali
            </button>
          </div>
          <Card className="shadow">
            <CardBody className="p-5">
              <div className="d-flex w-100 align-items-start justify-content-between">
                <div style={{ width: "30%" }} className="mt-4 mt-sm-0">
                  <div className="">
                    <div className="px-4">
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3 className="text-md font-weight-bold m-0 text-black w-100">
                          Jumlah Saldo
                        </h3>
                        <div className="w-100 d-flex align-items-center mt-4">
                          <WalletIcon />
                          <h3
                            style={{ color: "#9870C5" }}
                            className="text-lg font-weight-bold m-0 ml-3"
                          >
                            {formatToIDR(eventOrganizer.balance)}
                          </h3>
                        </div>

                        <Button
                          onClick={() =>
                            setIsOpenWithdrawModal(!isOpenWithdrawModal)
                          }
                          color="bg-hievent"
                          className=" w-100 text-lg text-white font-weight-bold bg-hievent mt-2 d-flex align-items-center justify-content-center py-2 rounded"
                        >
                          <IoDownloadOutline color="#ffffff" size={25} />
                          <h3 className="text-md font-weight-bold m-0 text-white">
                            Tarik Saldo
                          </h3>
                        </Button>
                        <WithdrawProccess
                          props={{
                            isOpen,
                            setIsOpen,
                            isOpenWithdrawModal,
                            setIsOpenWithdrawModal,
                            bankAccount,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mt-sm-0">
                  <div className="">
                    <div className="px-4">
                      <div className="d-flex flex-column align-items-center justify-content-end">
                        <div
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ color: "#6C63FF", cursor: "pointer" }}
                          className="text-md font-weight-bold m-0 text-black w-100 bg-white shadow-none"
                        >
                          Atur Rekening
                        </div>
                        <div className="w-100 d-flex align-items-center mt-4">
                          <h3 className="text-lg font-weight-bold m-0 text-black">
                            {bankAccount !== null
                              ? `${
                                  banks.find(
                                    (bank) => bank._id === bankAccount.bankId
                                  )?.name || null
                                } | ${bankAccount.accountNumber} | ${
                                  bankAccount.accountName
                                }`
                              : "Tidak ada rekening tujuan"}
                          </h3>
                        </div>
                        <SetUpBankAccount
                          props={{
                            isOpen,
                            setIsOpen,
                            bankAccount,
                            setBankAccount,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <div
            className="d-flex flex-row align-items-center mt-5 mx-4"
            style={{ overflowX: "auto" }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(1)}
              className={
                index === 1
                  ? "text-md font-weight-bold color-hievent"
                  : "text-md font-weight-bold"
              }
            >
              Saldo Masuk
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(2)}
              className={
                index === 2
                  ? "text-md font-weight-bold color-hievent mx-4"
                  : "text-md font-weight-bold mx-4"
              }
            >
              Saldo Keluar
            </span>
          </div>

          {index === 1 ? (
            <Row className="mt-4">
              {incomingBalance.length === 0 ? (
                <NoDataImage
                  type="event_eo"
                  message="Saat ini kamu belum ada riwayat saldo masuk"
                />
              ) : (
                <div className=" w-100 px-5">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderTop: "solid 1px #DADADA",
                          borderBottom: "solid 1px #DADADA",
                        }}
                      >
                        <th style={{ fontWeight: 600, padding: "10px 0px" }}>
                          Jumlah
                        </th>
                        <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                          Tgl Transaksi
                        </th>
                        <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                          Keterangan
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomingBalance.map((item) => (
                        <tr
                          style={{
                            borderBottom: "solid 1px #DADADA",
                            color: "#737373",
                          }}
                        >
                          <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                            {formatToIDR(item?.totalAmount)}
                          </td>
                          <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                            {moment(item.createdAt).format(
                              "DD MMM YYYY - HH : mm"
                            )}
                          </td>
                          <td
                            style={{
                              padding: "10px 0px",
                              fontWeight: 500,
                            }}
                          >
                            {`PAYOUT | ${item.bank.name} | ${item.payment.accountNumber}`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Row>
          ) : (
            <Row className="mt-4">
              {outcomingBalance.length === 0 ? (
                <NoDataImage
                  type="event_eo"
                  message="Saat ini kamu belum ada riwayat saldo keluar"
                />
              ) : (
                <div className=" w-100 px-5">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderTop: "solid 1px #DADADA",
                          borderBottom: "solid 1px #DADADA",
                        }}
                      >
                        <th style={{ fontWeight: 600, padding: "10px 0px" }}>
                          Jumlah
                        </th>
                        <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                          Tgl Transaksi
                        </th>
                        <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                          Keterangan
                        </th>
                        <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {outcomingBalance.map((item) => (
                        <tr
                          style={{
                            borderBottom: "solid 1px #DADADA",
                            color: "#737373",
                          }}
                        >
                          <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                            {formatToIDR(item?.totalAmount)}
                          </td>
                          <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                            {moment(item.createdAt).format(
                              "DD MMM YYYY - HH : mm"
                            )}
                          </td>
                          <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                            {`PAYOUT | ${item.bank.name} | ${item.bankAccount.accountNumber}`}
                          </td>
                          <td
                            style={{
                              padding: "10px 0px",
                              fontWeight: 500,
                              color:
                                item?.status === "approve"
                                  ? "#28DE25"
                                  : item?.status === "pending"
                                  ? "#FECA13"
                                  : "#FC0000",
                            }}
                          >
                            {item?.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Row>
          )}

          {Array.isArray(incomingBalance) &&
            Array.isArray(outcomingBalance) &&
            index > 0 &&
            index < 3 && (
              <Pagination listClassName="justify-content-start mt-3 px-4">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                {pageNumbers.map((pageNumber) => (
                  <PaginationItem
                    key={pageNumber}
                    className={page === pageNumber ? "active" : ""}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            )}
        </Container>
      )}
    </>
  );
};

export default Withdraw;
