import Event from "components/Items/Event";
// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import NoDataImage from "components/Partials/NoDataImage";
import { showToast } from "components/Partials/Toast";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
} from "reactstrap";
import API from "utils/API";

const AllEvent = ({match}) => {
  const [event, setEvent] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getEvent()
  }, [match.params.keyword_slug])

  const getEvent = () => {
    setloading(true)
    let key = match.params.keyword_slug.split('=')[0]
    let val = match.params.keyword_slug.split('=')[1]
    let search
    if (key === 'popular' || key === 'kategori')
      search = '?eventTypeSlug=' + val
    else if (key === 'pilihan')
      search = '?eventContextSlug=' + val

    API.get('events/getEvents' + search, {}).then(res => {
      // console.log(res.data.data)
      setloading(false)
      setEvent(res.data.data)
    }).catch(err => {
      // console.log(err.response.data)
      setloading(false)
      showToast(err.response.data.message, err.response.data.status.toLowerCase())
    })
  }

  if (loading)
    return <Loading />

  if (!loading && event.length === 0)
    return  <NoDataImage navigate="/cari-event" type="event_eo" message="Belum ada event" />

  return (
    <Container className="pt-8" fluid>
      {/* <Breadcumbs data={['Events']} /> */}

      {/* <div className="d-flex flex-row align-items-center justify-content-between mb-3">
        <Pagination
          listClassName="justify-content-end mb-0">
          <PaginationItem className="disabled">
            <PaginationLink
              href="#pablo"
              onClick={e => e.preventDefault()}
              tabIndex="-1"
            >
              <i className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="active">
            <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
              2 <span className="sr-only">(current)</span>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
              3
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
              <i className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>

        <div className="ml-auto d-flex flex-row align-items-center">
          <span className="text-primary font-weight-bold mr-2">Urutkan</span>
          <FormGroup className="m-0">
            <Input
              placeholder="Terbaru"
              type="text"
            />
          </FormGroup>
        </div>
      </div> */}

      <Row className="mt-4">
        {event.map(item =>
          <Event item={item} xl="4" lg="6" md="6" key={item._id} />
        )}
      </Row>

    </Container>
  );
};

export default AllEvent;
