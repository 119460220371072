import { useState } from "react";
import EventContexts from "components/Partials/EventContexts";
import EventContextsII from "components/Partials/EventContextsII";
import EventLocTypes from "components/Partials/EventLocTypes";
import EventPreconditions from "components/Partials/EventPreconditions";
import EventTypesII from "components/Partials/EventTypesII";

import {
    Button,
    Card,
    CardBody,
    Collapse,
    Form
} from "reactstrap";

const Filter = ({ props }) => {
    const [openKategori, setOpenKategori] = useState(true);
    const [openSubKategori, setOpenSubKategori] = useState(true);
    const [openJenjang, setOpenJenjang] = useState(true);
    const [openLokasi, setOpenLokasi] = useState(true);

    return (
        <Card className="shadow">
            <CardBody className="p-4">
                <h4 className="text-primary mb-3">Filter</h4>

                <Form>
                    <div className="d-flex flex-row align-items-center justify-content-center" onClick={() => setOpenKategori(!openKategori) }>
                        <h4 className="m-0">Jenis Event</h4>
                        <i className={openKategori ? "fa fa-caret-up fa-lg ml-auto" : "fa fa-caret-down fa-lg ml-auto"} />
                    </div>
                    <Collapse className="mt-2" isOpen={openKategori}>
                        <EventTypesII eventTypeIds={props.eventTypeIds} setEventTypeIds={props.setEventTypeIds} col="12" multiple={false} />
                    </Collapse>
                </Form>

                <hr className="mt-3 mb-3" />

                <Form>
                    <div className="d-flex flex-row align-items-center justify-content-center" onClick={() => setOpenSubKategori(!openSubKategori) }>
                        <h4 className="m-0">Konteks Event</h4>
                        <i className={openSubKategori ? "fa fa-caret-up fa-lg ml-auto" : "fa fa-caret-down fa-lg ml-auto"} />
                    </div>
                    <Collapse className="mt-2" isOpen={openSubKategori}>
                        <EventContexts eventContextId={props.eventContextIds} setEventContextId={props.setEventContextIds} />
                        {/* <EventContextsII eventContextIds={props.eventContextIds} setEventContextIds={props.setEventContextIds} col="12" multiple={false} /> */}
                    </Collapse>
                </Form>

                <hr className="mt-3 mb-3" />

                <Form>
                    <div className="d-flex flex-row align-items-center justify-content-center" onClick={() => setOpenJenjang(!openJenjang) }>
                        <h4 className="m-0">Jenjang</h4>
                        <i className={openJenjang ? "fa fa-caret-up fa-lg ml-auto" : "fa fa-caret-down fa-lg ml-auto"} />
                    </div>
                    <Collapse className="mt-2" isOpen={openJenjang}>
                        <EventPreconditions preconditionIds={props.preconditionIds} setPreconditionIds={props.setPreconditionIds} col="12" multiple={false} />
                    </Collapse>
                </Form>

                <hr className="mt-3 mb-3" />

                <Form>
                    <div className="d-flex flex-row align-items-center justify-content-center" onClick={() => setOpenLokasi(!openLokasi) }>
                        <h4 className="m-0">Lokasi</h4>
                        <i className={openLokasi ? "fa fa-caret-up fa-lg ml-auto" : "fa fa-caret-down fa-lg ml-auto"} />
                    </div>
                    <Collapse className="mt-2" isOpen={openLokasi}>
                        <EventLocTypes eventLocTypes={props.eventLocTypes} setEventLocTypes={props.setEventLocTypes} col="12" multiple={false} />
                    </Collapse>
                </Form>

            </CardBody>
        </Card>
    );
};

export default Filter;
