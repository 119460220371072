import {
  Badge,
  Button,
  Modal,
  InputGroupAddon,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { formatToIDR } from "utils/helpers";
import { useState, useEffect } from "react";
import WithdrawResult from "./WithdrawResult";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";
import API from "utils/API";
import moment from "moment";
const WithdrawProccess = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWithdrawSuccess, setIsWithdrawSuccess] = useState(null);
  const [dataEvent, setDataEvent] = useState([]);
  const [eventIdWithdraw, setEventIdWithdraw] = useState([]);
  const [totalWithdraw, setTotalWithDraw] = useState(0);
  const [withdraw, setWithdraw] = useState({});
  const useAuth = UseAuth();
  const [isLoading, setIsLoading] = useState(false);

  const getPaidEvents = () => {
    API.get("/eventBerbayar/getPaidEventsByUserId", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        // setBanks(res.data.data);
        setDataEvent(res.data.data);
      })
      .catch((err) => {
        showToast(err.response.data.message, "error");
      });
  };

  const handleChoise = (index, id, total) => {
    if (total !== 0) {
      setEventIdWithdraw((arr) => {
        const newArr = [...arr];

        if (newArr[index]) {
          newArr[index] = "";
          setTotalWithDraw((state) => state - total);
        } else {
          newArr[index] = id;
          setTotalWithDraw((state) => state + total);
        }
        return newArr;
      });
    }
  };

  const handleWithdraw = () => {
    setIsLoading(true);
    const data = {
      bankAccountId: props.bankAccount._id,
      eventIds: eventIdWithdraw.filter((item) => item !== ""),
    };
    // console.log(data);

    API.post("/withdrawal/createWithdrawal", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data.data[0]);
        setWithdraw(res.data.data[0]);
        setIsWithdrawSuccess(true);
        props.setIsOpen(false);
        setIsOpen(true);
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        setIsLoading(false);
        setIsWithdrawSuccess(false);
        setIsOpen(true);
        props.setIsOpen(false);
        showToast(err.response.data.message, "error");
      });
  };

  useEffect(() => {
    getPaidEvents();
  }, []);

  if (isWithdrawSuccess === null) {
    return (
      <Modal
        size="lg"
        className="modal-dialog-centered modal-info"
        contentClassName="bg-white"
        isOpen={props.isOpenWithdrawModal}
        toggle={() => props.setIsOpenWithdrawModal(!props.isOpenWithdrawModal)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() =>
              props.setIsOpenWithdrawModal(!props.isOpenWithdrawModal)
            }
          >
            <span
              aria-hidden={true}
              style={{
                color: "black",
              }}
            >
              ×
            </span>
          </button>
        </div>
        <h2
          style={{
            color: "#000000",
            fontWeight: 600,
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          Pilih saldo withdraw
        </h2>
        <div className="modal-body px-4 w-100">
          <div
            style={{
              width: "100%",
              height: "60vh",
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "30px 30px",
              gap: "15px",
            }}
          >
            {dataEvent.map((item, i) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={() =>
                      handleChoise(
                        i,
                        item._id,
                        item.totalIncome - item.totalWithdrawn
                      )
                    }
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                      border: "solid 2px #cad1d7",
                      backgroundColor:
                        item._id === eventIdWithdraw[i] ? "#9870C5" : "#ffffff",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      padding: "15px",
                      gap: "10px",
                      justifyContent: "center",
                      border: "solid 1px #C4C4C4",
                      borderRadius: "5px",
                      color: "black",
                    }}
                  >
                    <h2
                      style={{
                        color: "#000000",
                        width: "100%",
                        fontWeight: 600,
                        textAlign: "left",
                        fontSize: "15px",
                        margin: "0px",
                        borderBottom: "solid 1px #c4c4c4",
                        paddingBottom: "7px",
                      }}
                    >
                      {item.title}
                    </h2>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "13px",
                            width: "100%",
                            textAlign: "left",
                            color: "#737373",
                            fontWeight: "500",
                          }}
                          className="mb-0"
                        >
                          Tanggal event berakhir
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            textAlign: "left",
                            color: "black",
                            fontWeight: "500",
                          }}
                          className="mb-0 mt-1 text-black"
                        >
                          {moment(item.endDate).format("DD MMMM YYYY")}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: "13px",
                            width: "100%",
                            textAlign: "right",
                            color: "#737373",
                            fontWeight: "500",
                          }}
                          className="mb-0"
                        >
                          Total
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            textAlign: "right",
                            color: "black",
                            fontWeight: "600",
                          }}
                          className="mb-0 mt-1 text-black"
                        >
                          {formatToIDR(item.totalIncome - item.totalWithdrawn)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-between align-items-start w-100 pt-3">
            <div>
              <p
                style={{
                  fontSize: "13px",
                  width: "100%",
                  textAlign: "left",
                  color: "#737373",
                  fontWeight: "500",
                }}
                className="mb-0"
              >
                Saldo withdraw
              </p>
              <p
                style={{
                  fontSize: "18px",
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                  fontWeight: "600",
                }}
                className="mb-0 mt-1 text-black"
              >
                {formatToIDR(totalWithdraw)}
              </p>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                className=" bg-hievent w-100"
                color="bg-hievent"
                disabled={
                  eventIdWithdraw.length === 0 ||
                  eventIdWithdraw.every((eventID) => eventID === "") ||
                  isLoading
                }
                onClick={() => handleWithdraw()}
                style={{
                  color: "white",
                }}
              >
                {isLoading ? <Spinner size="sm" /> : "Tarik saldo"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <WithdrawResult
        props={{
          isOpen,
          setIsOpen,
          isWithdrawSuccess,
          setIsWithdrawSuccess,
          withdraw,
          setWithdraw,
        }}
      />
    );
  }
};

export default WithdrawProccess;
