import { useEffect, useState } from "react"
import API from "utils/API"
import Select from 'react-select';

const EventContexts = ({ eventContextId, setEventContextId }) => {
    const [eventContexts, setEventContexts] = useState([])

    useEffect(() => {
        getEventContexts()
    }, [])

    const getEventContexts = () => {
        API.get('eventContexts/getEventContexts', {})
            .then(res => {
                // console.log(res)
                const temp = []
                temp.push(
                    { label: 'Pilih Konteks Event', value: '' }
                )
                res.data.data.forEach(element => {
                    temp.push(
                        { label: element.name, value: element._id }
                    )
                })
                setEventContexts(temp)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    return (
        <Select
            styles={{
                menu: base => ({
                    ...base,
                    zIndex: 100
                })
            }}
            defaultValue={eventContexts.find(ell => ell.value === eventContextId)}
            value={eventContexts.find(ell => ell.value === eventContextId)}
            isSearchable={true}
            options={eventContexts}
            onChange={(event) => setEventContextId(event.value)}
            placeholder="Pilih konteks event"
        />
    )
}

export default EventContexts