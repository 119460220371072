import Event from "components/Items/Event";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import EOMedia from "components/Partials/EOMedia";
import NoDataImage from "components/Partials/NoDataImage";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { UseAuth } from "routes/useAuth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Media,
  CardHeader,
} from "reactstrap";
import API from "utils/API";

const Profil = (props) => {
  const [index, setIndex] = useState(1);
  const [eventOrganizer, setEventOrganizer] = useState({});
  const [follower, setFollower] = useState([]);
  const [eventShow, setEventShow] = useState([]);
  const [eventDone, setEventDone] = useState([]);

  useEffect(() => {
    getEventOrganizer();
  }, [props.match.params.slug]);

  const getEventOrganizer = () => {
    API.get(
      "eventOrganizers/getEventOrganizerBySlug/" + props.match.params.slug,
      {}
    )
      .then((res) => {
        // console.log(res.data.data)
        setEventOrganizer(res.data.data);
        getEventShow();
        getEventDone();
        getFollower();
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getFollower = (_id) => {
    API.get("follows/getCountFollower/" + props.match.params.slug, {})
      .then((res) => {
        // console.log(res.data.data)
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getEventShow = () => {
    API.get("events/getEvents?status=show&slug=" + props.match.params.slug, {})
      .then((res) => {
        // console.log(res.data.data)
        setEventShow(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getEventDone = () => {
    API.get("events/getEvents?status=done&slug=" + props.match.params.slug, {})
      .then((res) => {
        console.log(res.data.data)
        setEventDone(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const useAuth = UseAuth();
  const navigation = useHistory();

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const isSmallScreen = windowSize < 768;

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              padding: "20px",
            }}
          >
            <div>
              <button
                outline
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  flexDirection: "row",
                  fontSize: "13px",
                  gap: "5px",
                  color: "black",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  padding: "10px",
                }}
                onClick={() => navigation.push("/eo-diikuti")}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
                    fill="#404040"
                  />
                </svg>
                Event Organizer
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              <Media className="align-items-center">
                <Avatar
                  className="logo-eo"
                  name={eventOrganizer.name}
                  src={eventOrganizer.logo ? eventOrganizer.logo.url : ""}
                  round={true}
                />
                <Media className=" d-flex flex-column ml-2">
                  <span className="m-0 text-sm text-primary font-weight-bold">
                    {eventOrganizer.name}
                  </span>
                  <div className="d-flex flex-row" style={{ width: "70%" }}>
                    <Button
                      color="bg-hievent"
                      className="mt-2 py-1 px-3 text-white text-sm bg-hievent "
                    >
                      Ikuti
                    </Button>
                  </div>
                </Media>

                <Media className=" d-flex flex-row ml-3 mr-2 mb-4">
                  <EOMedia eventOrganizer={eventOrganizer} />
                </Media>
              </Media>
            </div>

            <div
              className="d-flex flex-row align-items-center p-3 justify-content-center"
              style={{
                margin: "20px 2px",
                backgroundColor: "#9870C5",
                borderRadius: "8px",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="mb-1">
                  <i className="fa fa-users fa-lg" style={{ color: "white" }} />
                  <span className="mx-2" style={{ color: "white" }}>
                    Jumlah Pengikut
                  </span>
                </div>

                <div className="mb-1" style={{ color: "white" }}>
                  <span className="text-l text-primary font-weight-bold bg-white">
                    {follower}
                  </span>
                </div>

                {follower.length === 0 ? (
                  <span
                    className="text-sm font-weight-bold"
                    style={{ color: "white" }}
                  >
                    Belum ada Pengikut
                  </span>
                ) : null}
              </div>
            </div>

            <div
              style={{
                border: "3px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="color-hievent mt-1 mb-2">Tentang</div>
              <div className="text-sm">{eventOrganizer.about}</div>
            </div>
            <Container
              className="mt-4 mb-5"
              style={{
                marginTop: "10px",
                marginRight: "5px",
                marginLeft: "5px",
                minHeight: 200,
                maxHeight: 300,
              }}
            >
              <div
                className="d-flex flex-row align-items-center mb-3"
                style={{ overflowX: "auto" }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    borderBottom: index === 1 ? "2px solid #9870C5" : "none",
                  }}
                  onClick={() => setIndex(1)}
                  className={
                    index === 1
                      ? "text-md color-hievent font-weight-bold"
                      : "text-md font-weight-bold"
                  }
                >
                  Akan diselenggarakan
                </span>

                <span
                  style={{
                    cursor: "pointer",
                    borderBottom: index === 2 ? "2px solid #9870C5" : "none",
                  }}
                  onClick={() => setIndex(2)}
                  className={
                    index === 2
                      ? "text-md color-hievent font-weight-bold ml-4"
                      : "text-md font-weight-bold ml-4"
                  }
                >
                  Telah diselenggarakan
                </span>
              </div>

              {index === 1 ? (
                <Row>
                  {eventShow.length === 0 ? (
                    <NoDataImage
                      type="event_eo"
                      message="Saat ini kamu belum ada event yang sedang berlangsung"
                    />
                  ) : (
                    eventShow.map((item) => (
                      <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                    ))
                  )}
                </Row>
              ) : (
                <Row>
                  {eventDone.length === 0 ? (
                    <NoDataImage
                      type="event_eo"
                      message="Saat ini kamu belum ada event yang sudah berakhir"
                    />
                  ) : (
                    eventDone.map((item) => (
                      <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                    ))
                  )}
                </Row>
              )}
            </Container>
          </div>
          <UserBottomBarMobile />
        </>
      ) : (
        <>
          <Container className="pt-8" fluid>
            <Card className="shadow">
              <CardBody className="p-5">
                <Row>
                  <Col md="8">
                    <Media className="align-items-center">
                      <Avatar
                        className="logo-eo"
                        name={eventOrganizer.name}
                        src={eventOrganizer.logo ? eventOrganizer.logo.url : ""}
                        round={true}
                      />
                      <Media className="ml-3 d-flex flex-column">
                        <span className="m-0 text-lg text-primary font-weight-bold">
                          {eventOrganizer.name}
                        </span>
                        <div className="d-flex flex-row">
                          <EOMedia eventOrganizer={eventOrganizer} />
                        </div>
                      </Media>
                    </Media>
                  </Col>
                  <Col md="4" className="mt-4 mt-sm-0">
                    <Card className="bg-secondary">
                      <CardBody className="px-6">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <i className="fa fa-users fa-lg" />
                          <span className="text-sm mt-1">Jumlah Pengikut</span>
                          <span className="text-lg font-weight-bold mt-3">
                            {follower}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <h3 className="color-hievent mt-4">Tentang</h3>
                <span className="text-sm">{eventOrganizer.about}</span>
              </CardBody>
            </Card>

            <div
              className="d-flex flex-row align-items-center mt-5 ml-4"
              style={{ overflowX: "auto" }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIndex(1)}
                className={
                  index === 1
                    ? "text-md color-hievent font-weight-bold"
                    : "text-md font-weight-bold"
                }
              >
                Akan diselenggarakan
              </span>

              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIndex(2)}
                className={
                  index === 2
                    ? "text-md color-hievent font-weight-bold ml-4"
                    : "text-md font-weight-bold ml-4"
                }
              >
                Telah diselenggarakan
              </span>
            </div>

            {index === 1 ? (
              <Row className="mt-4">
                {eventShow.length === 0 ? (
                  <NoDataImage
                    type="event_eo"
                    message="Saat ini kamu belum ada event yang sedang berlangsung"
                  />
                ) : (
                  eventShow.map((item) => (
                    <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                  ))
                )}
              </Row>
            ) : (
              <Row className="mt-4">
                {eventDone.length === 0 ? (
                  <NoDataImage
                    type="event_eo"
                    message="Saat ini kamu belum ada event yang sudah berakhir"
                  />
                ) : (
                  eventDone.map((item) => (
                    <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                  ))
                )}
              </Row>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Profil;
