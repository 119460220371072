import { showToast } from "components/Partials/Toast";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import img from "../../assets/img/theme/success_register_event.svg"

import {
    Button,
    Input,
    Modal
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const Private = ({ className, style }) => {
    const [input1, setInput1] = useState('')
    const [input2, setInput2] = useState('')
    const [input3, setInput3] = useState('')
    const [input4, setInput4] = useState('')
    const [input5, setInput5] = useState('')

    const [isOpen, setIsOpen] = useState(false)

    const useAuth = UseAuth()
    const navigate = useHistory()

    const input1Ref = useRef(null)
    const input2Ref = useRef(null)
    const input3Ref = useRef(null)
    const input4Ref = useRef(null)
    const input5Ref = useRef(null)

    useEffect(() => {
        input1Ref.current?.focus()
    }, [])

    const checkCode = () => {
        const code = input1 + input2 + input3 + input4 + input5;
        if (!useAuth.auth)
            return navigate.push("/auth")

        if (code === '')
            return showToast('Silakan masukkan kode event', 'error')

        API.get('events/getEventByGeneratedEventCode/' + code, {
            headers: { 'Authorization': "Bearer " + useAuth.auth.accessToken }
        }).then(res => {
            // console.log(res.data)
            setIsOpen(false);
            navigate.push("/event/" + res.data.data.generatedEventCode);
        }).catch(err => {
            console.log(err.response.data)
            showToast(err.response.data.message, err.response.data.status.toLowerCase())
        })
    }

    const onInputChange = (action, text) => {
        const val = text.substring(0, 1)
        if (action === 1) {
            setInput1(val)
            input2Ref.current.focus();
        } else if (action === 2) {
            setInput2(val)
            input3Ref.current.focus();
        } else if (action === 3) {
            setInput3(val)
            input4Ref.current.focus();
        } else if (action === 4) {
            setInput4(val)
            input4Ref.current.focus();
        } else if (action === 5) {
            setInput5(val)
        }

    }

    return (
        <>
            <Link to="/"
                className={className}
                style={style}
                onClick={() => setIsOpen(!isOpen)}>
                Private
            </Link>
            <Modal
                className="modal-dialog-centered modal-info"
                contentClassName="bg-gradient-purple"
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <div className="modal-header">
                    <h6 className="modal-title">
                        Event private!
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body py-0">
                    <div className="py-3 text-center">
                        <div className="d-flex align-items-center justify-content-center">
                            <img className="result-image" src={img} alt="hievents" />
                        </div>
                        {/* <i className="ni ni-bell-55 ni-2x" /> */}
                        <h4 className="text-white my-4">Masukkan kode event yang kamu punya</h4>
                        {/* <p>Masukkan kode event yang kamu punya</p> */}
                        <div className="d-flex flex-row">
                            <Input
                                ref={input1Ref}
                                className="text-center"
                                type="number"
                                value={input1}
                                onChange={(event) => onInputChange(1, event.target.value)}
                            />
                            <Input
                                ref={input2Ref}
                                className="mx-2 text-center"
                                type="number"
                                value={input2}
                                onChange={(event) => onInputChange(2, event.target.value)}
                            />
                            <Input
                                ref={input3Ref}
                                className="text-center"
                                type="number"
                                value={input3}
                                onChange={(event) => onInputChange(3, event.target.value)}
                            />
                            <Input
                                ref={input4Ref}
                                className="mx-2 text-center"
                                type="number"
                                value={input4}
                                onChange={(event) => onInputChange(4, event.target.value)}
                            />
                            <Input
                                ref={input5Ref}
                                className="text-center"
                                type="number"
                                value={input5}
                                onChange={(event) => onInputChange(5, event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        onClick={() => checkCode()}
                        className="btn-white" color="default" type="button">
                        Cari Event
                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        Batal
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Private;
