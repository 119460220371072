import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, FormGroup, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import { MdKeyboardArrowLeft } from "react-icons/md";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";

const UbahPassword = () => {
  const [passwordLama, setPasswordLama] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
  });
  const [showLoading, setShowLoading] = useState(false);

  const useAuth = UseAuth();
  const navigation = useHistory();

  const updatePassword = () => {
    setShowLoading(true);
    const data = {
      password: passwordLama,
      confirmPassword: passwordBaru,
    };

    API.post("users/changePassword", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setShowLoading(false);
        console.log(res.data);
        showToast("Password Anda Telah Diubah");
      })
      .catch((err) => {
        // console.log("Data Error : ", err.response.data.data.sessionId);
        setShowLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  return (
    <div
      style={{
        padding: "10px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <button
        outline
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          flexDirection: "row",
          fontSize: "14px",
          gap: "5px",
          color: "black",
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: "10px",
        }}
        onClick={() => navigation.push("/profil")}
      >
        <MdKeyboardArrowLeft size={20} />
        Ubah Password
      </button>
      <Row>
        <Col md="12">
          <FormGroup>
            <label className="text-sm">Password Lama</label>
            <div className="position-relative">
              <Input
                style={{
                  border: "2px solid #ced4da",
                  borderRadius: "10px",
                }}
                placeholder="Password Lama"
                type={showPassword.old ? "text" : "password"}
                value={passwordLama}
                onChange={(e) => setPasswordLama(e.target.value)}
              />
              <div
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setShowPassword({ ...showPassword, old: !showPassword.old })
                }
              >
                <FontAwesomeIcon icon={showPassword.old ? faEyeSlash : faEye} />
              </div>
            </div>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <label className="text-sm">Password Baru</label>
            <div className="position-relative">
              <Input
                style={{
                  border: "2px solid #ced4da",
                  borderRadius: "10px",
                }}
                placeholder="Password Baru"
                type={showPassword.new ? "text" : "password"}
                value={passwordBaru}
                onChange={(e) => setPasswordBaru(e.target.value)}
              />
              <div
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setShowPassword({ ...showPassword, new: !showPassword.new })
                }
              >
                <FontAwesomeIcon icon={showPassword.new ? faEyeSlash : faEye} />
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Button
        color="bg-hievent"
        className="mt-3 w-100 text-white bg-hievent"
        onClick={updatePassword}
      >
        {showLoading ? <Spinner size="sm" /> : "Simpan"}
      </Button>
      <UserBottomBarMobile />
    </div>
  );
};

export default UbahPassword;
