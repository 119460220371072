import Header from "components/Headers/Header";
import AllEvent from "../all-event/Index";
import WebinarPilihan from "../webinar-pilihan/Index";
import EventPopuler from "../event-populer/Index";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import ActivationGoogle from "components/Modals/ActivationGoogle";

import HeaderMobile from "components/Headers/HeaderMobile";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import Advertisement from "components/Mobile/dashboard/Advertisement";
import EventCategory from "components/Mobile/dashboard/EventCategory";

const Index = () => {
  const user = useSelector((state) => state.userReducer.userState);
  // console.log("data dashboard : ", user);
  const [isOpen, setIsOpen] = useState(true);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowSize < 768;

  const popular = [
    { title: "Webinar Popular", url: "eventTypeSlug=webinar" },
    { title: "Seminar Popular", url: "eventTypeSlug=seminar" },
  ];
  return (
    <>
      {user.source === "google" && user.username === undefined ? (
        <ActivationGoogle props={{ isOpen, setIsOpen }} />
      ) : null}

      {isSmallScreen ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <HeaderMobile />
          <UserBottomBarMobile />
          <Advertisement />
          <EventCategory />
          <div className="mt-3 p-4 ">
            <h3
              className="text-start color-hievent"
              style={{
                fontSize: 18,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Event Populer
            </h3>
            <div
              style={{
                overflowY: "auto",
                height: "auto",
                paddingBottom: "20%",
              }}
            >
              {popular.length === 0 ? (
                <p>notfound</p>
              ) : (
                popular.map((element, index) => (
                  <EventPopuler data={element} key={index} />
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header />
          <AllEvent />
          <WebinarPilihan />
          {popular.map((element, index) => (
            <EventPopuler data={element} key={index} />
          ))}
        </>
      )}
    </>
  );
};

export default Index;
