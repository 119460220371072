import { showToast } from "components/Partials/Toast";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Modal, Spinner } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const AttendEvent = ({ event, USER }) => {
  const [name, setName] = useState(USER.name);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const attendEvent = () => {
    setLoading(true);
    const data = {
      eventId: event._id,
      name,
    };
    API.post("eventRegistrations/attendEvent", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        setLoading(false);
        showToast(res.data.message, res.data.status.toLowerCase());
        setIsOpen(!isOpen);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        disabled={
          moment().isBefore(event.startDate) ||
          moment().isAfter(event.deadlineAttendance)
        }
        className="btn btn-md w-100 text-white bg-hievent"
        color="bg-hievent"
      >
        Absen Sekarang
      </Button>
      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-purple"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title">Absensi Event</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fa fa-user fa-2x" />
            {/* <h4 className="heading mt-4">Masukkan kode event yang kamu punya</h4> */}
            <p>
              Masukkan nama kamu dan klik tombol "Absen" untuk melakukan absensi
            </p>
            <div className="d-flex flex-row">
              <Input
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => attendEvent()}
            className="btn-white"
            color="default"
            type="button"
          >
            {loading ? <Spinner size="sm" /> : null} Absen
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendEvent);
