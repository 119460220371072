import { Container } from "reactstrap"
import img from "../../assets/img/theme/not_found.svg"

const NotFound = () => {
    return (
        <Container className="mt-5 py-8" fluid>
            <div className="d-flex flex-column align-items-center justify-content-center mt-8">
                <img className="result-image" src={img} alt="hievents"/>
                <h4 className="mt-4">Halaman tidak ditemukan</h4>
            </div>
        </Container>
    )
}

export default NotFound