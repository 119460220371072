import axios from "axios";
// import { useHistory } from 'react-router-dom';

let API;

API = axios.create({
  baseURL: process.env.REACT_APP_baseURL,
});

API.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// const Logout = () => {
//     const accessToken = window.localStorage.getItem('hievent-at');
//     API.get('auths/logout', {
//         headers: { 'Authorization': "Bearer " + accessToken }
//     }).then(res => {
//         console.log(res.data)
//         window.localStorage.setItem('hievent-at', "");
//         // window.localStorage.setItem('hievent-sid', null);
//     }).catch(err => {
//         console.log(err)
//     })
// }

export default API;
