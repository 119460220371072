import React, { useState } from "react";
import { motion } from "framer-motion";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Button, Card, CardBody, Collapse, Form } from "reactstrap";
import EventContexts from "components/Partials/EventContexts";
import EventContextsII from "components/Partials/EventContextsII";
import EventLocTypes from "components/Partials/EventLocTypes";
import EventPreconditions from "components/Partials/EventPreconditions";
import EventTypesII from "components/Partials/EventTypesII";

const FilterMobile = ({ props }) => {
  const [openKategori, setOpenKategori] = useState(true);
  const [openSubKategori, setOpenSubKategori] = useState(true);
  const [openJenjang, setOpenJenjang] = useState(true);
  const [openLokasi, setOpenLokasi] = useState(true);
  console.log(props);
  const menuMotion = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: {
        duration: 0.3,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.4,
        duration: 0.3,
        ease: [0.12, 0, 0.39, 1],
      },
    },
  };

  const menuItemsMotion = {
    initial: {
      y: "30vh",
      transition: {
        duration: 0.3,
        ease: [0.37, 0, 0.63, 1],
      },
    },
    open: {
      y: 0,
      transition: {
        ease: [0, 0.55, 0.45, 1],
        duration: 0.5,
      },
    },
  };

  const containerMotion = {
    initial: {
      transition: {
        staggerChildren: 0.09,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.09,
        staggerDirection: 1,
      },
    },
  };

  return (
    <motion.div
      variants={menuMotion}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{
        position: "fixed",
        zIndex: 100,
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            padding: "0 10px",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "black",
              fontSize: 14,
              fontWeight: 400,
            }}
            onClick={() => props.setIsFilterOpen(false)}
          >
            <MdKeyboardArrowLeft size={20} />
            <span>Filter</span>
          </button>
        </div>
        <motion.ul
          variants={containerMotion}
          initial="initial"
          animate="open"
          exit="initial"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 20px",
            height: "100%",
          }}
        >
          <li
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <h1
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "black",
              }}
            >
              Kategori
            </h1>
            <div
              className="d-flex flex-row align-items-center justify-content-center"
              onClick={() => setOpenKategori(!openKategori)}
              style={{
                padding: "15px 0px",
                marginX: "10px",
                borderBottom: "1px solid #DDDDDD",
                cursor: "pointer",
                color: "#737373",
              }}
            >
              <h4
                className="m-0"
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Pilih Kategori Event
              </h4>
              <i
                className={
                  openKategori
                    ? "fa fa-caret-up fa-lg ml-auto"
                    : "fa fa-caret-down fa-lg ml-auto"
                }
              />
            </div>
            <Collapse className="mt-2" isOpen={openKategori}>
              <EventTypesII
                eventTypeIds={props.eventTypeIds}
                setEventTypeIds={props.setEventTypeIds}
                col="12"
                multiple={false}
              />
            </Collapse>
          </li>

          <li
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <h1
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "black",
              }}
            >
              Sub Kategori
            </h1>
            <div
              className="d-flex flex-row align-items-center justify-content-center"
              onClick={() => setOpenSubKategori(!openSubKategori)}
              style={{
                padding: "15px 0px",
                marginX: "10px",
                borderBottom: "1px solid #DDDDDD",
                cursor: "pointer",
              }}
            >
              <h4
                className="m-0"
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Pilih Sub Kotegori Event
              </h4>
              <i
                className={
                  openSubKategori
                    ? "fa fa-caret-up fa-lg ml-auto"
                    : "fa fa-caret-down fa-lg ml-auto"
                }
              />
            </div>
            <Collapse className="mt-2" isOpen={openSubKategori}>
              <EventContexts
                eventContextId={props.eventContextIds}
                setEventContextId={props.setEventContextIds}
              />
              {/* <EventContextsII eventContextIds={props.eventContextIds} setEventContextIds={props.setEventContextIds} col="12" multiple={false} /> */}
            </Collapse>
          </li>

          <li
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <h1
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "black",
              }}
            >
              Jenjang Event
            </h1>
            <div
              className="d-flex flex-row align-items-center justify-content-center"
              onClick={() => setOpenJenjang(!openJenjang)}
              style={{
                padding: "15px 0px",
                marginX: "10px",
                borderBottom: "1px solid #DDDDDD",
                cursor: "pointer",
              }}
            >
              <h4
                className="m-0"
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Pilih Jenis Peserta Event
              </h4>
              <i
                className={
                  openJenjang
                    ? "fa fa-caret-up fa-lg ml-auto"
                    : "fa fa-caret-down fa-lg ml-auto"
                }
              />
            </div>
            <Collapse className="mt-2" isOpen={openJenjang}>
              <EventPreconditions
                preconditionIds={props.preconditionIds}
                setPreconditionIds={props.setPreconditionIds}
                col="12"
                multiple={false}
              />
            </Collapse>
          </li>

          <li
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <h1
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "black",
              }}
            >
              Lokasi
            </h1>
            <div
              className="d-flex flex-row align-items-center justify-content-center"
              onClick={() => setOpenLokasi(!openLokasi)}
              style={{
                padding: "15px 0px",
                marginX: "10px",
                borderBottom: "1px solid #DDDDDD",
                cursor: "pointer",
              }}
            >
              <h4
                className="m-0"
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Pilih Lokasi Event
              </h4>
              <i
                className={
                  openLokasi
                    ? "fa fa-caret-up fa-lg ml-auto"
                    : "fa fa-caret-down fa-lg ml-auto"
                }
              />
            </div>
            <Collapse className="mt-2" isOpen={openLokasi}>
              <EventLocTypes
                eventLocTypes={props.eventLocTypes}
                setEventLocTypes={props.setEventLocTypes}
                col="12"
                multiple={false}
              />
            </Collapse>
          </li>
        </motion.ul>
      </div>
    </motion.div>
  );
};

export default FilterMobile;
