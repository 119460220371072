import { useState } from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

import {
  Button,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
import { connect } from "react-redux";
import Private from "components/Modals/Private";
import Avatar from "react-avatar";
import login from "../../assets/img/theme/login.svg";
import { showToast } from "components/Partials/Toast";

const UserSidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.category === "side")
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const useAuth = UseAuth();
  const navigation = useHistory();

  const onLogout = () => {
    API.get("auths/logout", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        useAuth.logout();
      })
      .catch((err) => {
        // console.log(err)
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onBuatEvent = () => {
    closeCollapse();
    !useAuth.auth
      ? navigation.push("/auth")
      : props.EO._id
      ? navigation.push("/buat-event")
      : navigation.push("/register-event-organizer");
  };

  let imgUser = login;
  if (useAuth.auth) {
    if (props.USER._id) {
      if (props.USER.photo) {
        imgUser = props.USER.photo.url;
      } else {
        imgUser = null;
      }
    }
  }

  return (
    <Navbar
      className="navbar-vertical fixed navbar-dark d-block d-sm-none"
      expand="md"
      style={{ background: "white" }}
      // style={{ background: 'linear-gradient(to right, rgb(151,111,197), rgb(103,210,169))' }}
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon bg-hievent" />
        </button>

        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <Avatar src={logo.imgSrc} round={false} size="30" />
          </NavbarBrand>
        ) : null}

        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle
              nav
              onClick={() =>
                props.USER.name ? null : navigation.push("/auth")
              }
            >
              <Media className="align-items-center">
                <Avatar
                  className="logo-eo"
                  name={props.USER.name ? props.USER.name : null}
                  round={props.USER.name ? true : false}
                  src={imgUser}
                  size={props.USER.name ? "35" : "27"}
                />
              </Media>
            </DropdownToggle>
            {props.USER.name ? (
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/profil" tag={Link}>
                  <i className="ni ni-circle-08" />
                  <span>Profil Saya</span>
                </DropdownItem>
                {props.EO._id ? (
                  <DropdownItem to="/event-organizer" tag={Link}>
                    <i className="fa fa-users fa-lg" />
                    <span>Event Organizer Saya</span>
                  </DropdownItem>
                ) : null}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-single-02" />
                                    <span>EO Langganan</span>
                                </DropdownItem> */}
                <DropdownItem to="/" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Contact Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={() => onLogout()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            ) : null}
          </UncontrolledDropdown>
        </Nav>

        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img src={logo.imgSrc} alt="hievents" />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img src={logo.imgSrc} alt="hievents" />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <h6 className="navbar-heading text-muted">Menu</h6>

          <Nav navbar>{createLinks(routes)}</Nav>

          <Nav navbar>
            <NavItem>
              <div
                className="ml-3"
                style={{ marginTop: 12 }}
                onClick={closeCollapse}
              >
                <i className="fa fa-lock" />
                <Private
                  style={{ color: "#212529", fontSize: 14, marginLeft: 22 }}
                />
              </div>
            </NavItem>
          </Nav>

          <hr className="my-3" />

          <Nav className="mb-md-3" navbar>
            {/* <NavItem>
                            <NavLink
                                to="/"
                            ><i className="ni ni-cart" />
                                Keranjang
                            </NavLink>
                        </NavItem> */}
            <NavItem>
              <NavLink to="/event-disimpan" tag={Link} onClick={closeCollapse}>
                <i className="ni ni-favourite-28" />
                Event Disimpan Testing
              </NavLink>
            </NavItem>
            {/* <NavItem>
                            <NavLink
                                to="/notifikasi"
                                tag={Link}
                                onClick={closeCollapse}>
                                    <i className="ni ni-bell-55" />Notifikasi
                            </NavLink>
                        </NavItem> */}
          </Nav>

          <Nav className="mb-md-3 mt-3" navbar>
            <NavItem>
              <Button
                onClick={() => onBuatEvent()}
                className="ml-3"
                color="success"
                type="button"
              >
                Buat Event
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

UserSidebar.defaultProps = {
  routes: [{}],
};

UserSidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);
