import { CertificatePreview } from "components/Partials/CertificatePreview";
import moment from "moment";
import { Button, Card, CardBody, Row, Col, Media } from "reactstrap";
import { capitalizeFirstLetter } from "utils/helpers";
import QRCode from "qrcode";
import logo from "../../assets/img/brand/brand-color.png";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Sertifikat = ({ item, xl, lg, md }) => {
  const [qrcode, setQrCode] = useState("");
  let linkCode = process.env.REACT_APP_currentURL + "/sertifikat/" + item._id;
  const isSmallScreen = useMediaQuery({ maxWidth: 639 });

  // For Mobile Screen
  const navigate = useHistory();

  useEffect(() => {
    QRCode.toDataURL(linkCode)
      .then((url) => {
        setQrCode(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Col className="mb-6" xl={xl} lg={lg} md={md}>
      <Card className="shadow">
        <CardBody className="p-0">
          <Row>
            {item.event.certifiedEvent ? (
              <Col
                md="12"
                lg="12"
                xl="12"
                className="d-none align-items-center justify-content-center"
              >
                <div id={"certificate-" + item._id} className="p-0 h-100">
                  <img
                    className="w-100 h-100 rounded-lg"
                    style={{ objectFit: "cover" }}
                    src={item.event.certificateFile.url}
                    alt="hievents"
                    crossOrigin="anonymous"
                  />
                  <h1
                    className="w-100 text-center display-3 certificate-name"
                    style={{ fontWeight: "bolder" }}
                  >
                    {item.user.name}
                  </h1>
                  {/* <div className="w-100 text-center certificate-logo">
                    <img
                      className="certificate-logo-img"
                      src={logo}
                      alt="hievents"
                    />
                  </div> */}
                  <div className="w-100 certificate-code">
                    <img src={qrcode} className="qrcode"></img>
                  </div>
                </div>
              </Col>
            ) : null}
            <Col
              md="12"
              lg="12"
              xl="12"
              className="px-5 py-3"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                isSmallScreen
                  ? navigate.push("/ruang-sertifikat/" + item.event?.slug)
                  : null
              }
            >
              <Media className="align-items-center mb-1">
                <span className="avatar avatar-lg rounded-circle">
                  <img
                    src={item.event.eventOrganizer.logo.url}
                    alt="hievents"
                  />
                </span>
                {/* <Avatar className="logo-eo" name={event.eventOrganizer?.name} src={event.eventOrganizer?.logo?.url} round={true} size={45} /> */}
                <Media className="ml-2">
                  <span className="mb-0 text-lg text-success font-weight-bold">
                    {item.event.eventOrganizer.name}
                  </span>
                </Media>
              </Media>
              <h3 className="m-0">{item.event.title}</h3>
              <h3 className="text-success m-0">
                {capitalizeFirstLetter(item.event.paidStatus)}
              </h3>
              <Row>
                <Col xs="6">
                  <small className="text-sm">Tanggal Event</small>
                </Col>
                <Col xs="6">
                  <small className="text-sm">
                    {moment(item.event.startDate).format("DD MMM YYYY HH:mm")}
                  </small>
                </Col>
              </Row>
              <Button
                disabled={!item.event.certifiedEvent}
                onClick={(event) =>
                  CertificatePreview(
                    event,
                    "certificate-" + item._id,
                    "sertifikat-" + item.event.slug
                  )
                }
                className="btn btn-md mt-3 py-2 rounded-pill w-100"
                color={item.event.certifiedEvent ? "primary" : "danger"}
              >
                {item.event.certifiedEvent
                  ? "Download Sertifikat"
                  : "Event ini tidak bersertifikat"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Sertifikat;
