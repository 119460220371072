import React from "react";
import "./toggleLanguage.css";

const ToggleLanguage = () => {
  return (
    <div className="switch">
      <input
        id="language-toggle"
        className="check-toggle check-toggle-round-flat"
        type="checkbox"
      />
      <label for="language-toggle" />
      <span className="on">ID</span>
      <span className="off">EN</span>
    </div>
  );
};

export default ToggleLanguage;
