import { useEffect, useState } from "react"
import { Input } from "reactstrap"
import API from "utils/API"

const EventTypes = ({ eventTypeId, setEventTypeId }) => {
    const [eventTypes, setEventTypes] = useState([])

    useEffect(() => {
        getEventTypes()
    }, [])

    const getEventTypes = () => {
        API.get('eventTypes/getEventTypes', {})
            .then(res => {
                // console.log(res)
                const temp = []
                temp.push(
                    <option key="default-none-event-type" value="">
                        Pilih jenis event
                    </option>)
                res.data.data.forEach(element => {
                    temp.push(
                        <option key={element._id} value={element._id}>
                            {element.name}
                        </option>
                    )
                })
                setEventTypes(temp)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    return (
        <Input
            className="mr-2"
            type="select"
            value={eventTypeId}
            onChange={(event) => {
                setEventTypeId(event.target.value)
            }}>
            {eventTypes}
        </Input>
    )
}

export default EventTypes