import { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage("hievent-at", "");
  // const [userId, setUserId] = useLocalStorage("hievent-uid", null);
  const navigate = useHistory();
  
  const login = (data) => {

    const directPath = window.localStorage.getItem('direct-path') === null ? "" : window.localStorage.getItem('direct-path');
    setAccessToken(data.accessToken);
    // setUserId(data.sessionId)
    directPath !== "" && directPath !== undefined ? navigate.replace(directPath) : navigate.replace("/");
    window.localStorage.setItem("direct-path","")
  };

  const logout = () => {
    setAccessToken("");
    // setUserId("");
    navigate.replace("/auth");
  };

  const auth = accessToken ? { accessToken } : null;

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UseAuth = () => {
  return useContext(AuthContext);
};
