// import Breadcumbs from "components/Partials/Breadcumbs";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col, Media, Container } from "reactstrap";

const Item = ({ item, xl, lg, md }) => {
  const navigate = useHistory();

  return (
    <Col
      onClick={() =>
        navigate.push("/event-organizer/" + item.eventOrganizerId?.slug)
      }
      className="mb-3"
      xl={xl}
      lg={lg}
      md={md}
    >
      <div key={item._id} className="flex-column align-items-center">
        <Avatar
          size="60"
          src={item.eventOrganizerId?.logo?.url}
          name={item.eventOrganizerId?.name}
          round={true}
        />
        <Media className="mt-1 flex-column">
          <span className="mb-0 text-sm font-weight-bold">
            {item.eventOrganizerId?.name}
          </span>
          <p className="mb-0 text-xs">
            {item.eventOrganizerId?.about.split(" ").slice(0, 10).join(" ")}...
          </p>
        </Media>
      </div>
    </Col>
  );
};

const Index = ({ FOLLOWING }) => {
  return (
    <>
      {/* Page content */}
      <Container className="pt-8" fluid>
        {/* <h2 className="color-hievent">Event Organizer yang diikuti</h2> */}
        {/* <Breadcumbs data={['Following']} /> */}
        <Row className="p-4">
          {FOLLOWING.map((item) => (
            <Item item={item} xl="3" lg="3" md="4" key={item._id} />
          ))}
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
