import { showToast } from "components/Partials/Toast";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
    Button,
    Card,
    CardBody,
    Col,
    Spinner,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import API from "utils/API";

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [captcha, setCaptcha] = useState('')
    const [loading, setLoading] = useState(false)

    const recaptchaRef = useRef(null);

    const onSend = () => {
        if(!captcha){
            showToast('Captcha tidak valid!', 'error')
            return
        }

        setLoading(true)
        const data = {
            email, captcha
        }

        API.post('auths/forgotPassword', data)
            .then(res => {
                // console.log(res)
                setLoading(false)
                setEmail('')
                setCaptcha('')
                recaptchaRef.current.reset()
                showToast(res.data.message, res.data.status.toLowerCase())
            })
            .catch(err => {
                // console.log(err.response)
                setLoading(false)
                showToast(err.response.data.message, err.response.data.status.toLowerCase())
            })
    }

    function onChange(value) {
        setCaptcha(value)
    }

    return (
        <>
            <Col xl="9" lg="10" md="11">
                <Card className="bg-secondary shadow border-0 my-0 my-lg-8">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <h1>Lupa Password</h1>
                            <div>Masukkan email kamu yang sudah terdaftar</div>
                        </div>

                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={email} onChange={(event) => setEmail(event.target.value)}
                                    />
                                </InputGroup>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_siteKeyCaptcha}
                                    onChange={onChange}
                                />
                            </FormGroup>

                            <div className="text-center">
                                <Button
                                    disabled={loading}
                                    onClick={() => onSend()}
                                    className="my-4 w-100 text-white bg-hievent" color="bg-hievent" type="button">
                                    {loading ? <Spinner size="sm" /> : null}
                                    {' '}Kirim
                                </Button>
                            </div>
                        </Form>

                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ForgotPassword;
