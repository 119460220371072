const EOMedia = ({ eventOrganizer }) => {
  return (
    <>
      {eventOrganizer.instagram ? (
        <a className="mr-2" role="button">
          <i
            className="fab fa-instagram fa-lg color-hievent"
            onClick={() =>
              window.open(
                "https://www.instagram.com/" + eventOrganizer.instagram,
                "_blank"
              )
            }
            style={{ fontSize: '30px' }} 
          />
        </a>
      ) : null}
      {eventOrganizer.youtube ? (
        <a className="mr-2" role="button">
          <i
            className="fab fa-youtube fa-lg color-hievent"
            onClick={() =>
              window.open("https://" + eventOrganizer?.youtube, "_blank")
            }
            style={{ fontSize: '30px' }} 
          />
        </a>
      ) : null}
      {eventOrganizer.whatsapp ? (
        <a className="mr-2" role="button">
          <i
            className="fab fa-whatsapp fa-lg color-hievent"
            onClick={() =>
              window.open("https://wa.me/" + eventOrganizer?.whatsapp, "_blank")
            }
            style={{ fontSize: '30px' }}
          />
        </a>
      ) : null}
      {eventOrganizer.tiktok ? (
        <a className= "mr-2" role="button">
          <i
            className="fab fa-tiktok fa-md color-hievent"
            onClick={() =>
              window.open(
                "https://www.tiktok.com/" + eventOrganizer?.tiktok,
                "_blank"
              )
            }
            style={{ fontSize: '30px' }}
          />
        </a>
      ) : null}
    </>
  );
};

export default EOMedia;
