import {
    Badge,
    Button,
    Modal,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import img from "../../assets/img/theme/success_register_event.svg"

const SuccessRegister = ({ props }) => {

    const navigation = useHistory()

    return (
        <Modal
            className="modal-dialog-centered modal-info"
            contentClassName="bg-gradient-purple"
            isOpen={props.isOpen}
            toggle={() => props.setIsOpen(!props.isOpen)}
        >
            <div className="modal-header">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => props.setIsOpen(!props.isOpen)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body p-0">
                <div className="text-center mt--3">
                    <h2 className="mb-0 text-white">Selamat</h2>
                    <h2 className="text-white">Kamu berhasil mendaftar event!</h2>
                    <div className="d-flex align-items-center justify-content-center py-4">
                        <img className="result-image" src={img} alt="hievents"/>
                    </div>
                    <h2 className="text-white">{props.event?.title}</h2>
                    <h4 className="text-white">Tanggal dimulai {moment(props.event?.startDate).format("DD MMM YYYY HH:mm")} WIB</h4>

                    <h5 className="mt-4 text-white">Untuk mengakses event, silakan masuk ke menu <Badge style={{ cursor: 'pointer' }} onClick={() => navigation.push('/event-terdaftar')} className="text-primary" color="light" pill>Event Terdaftar</Badge></h5>

                    {props.event.groupLink === '' || props.event.groupLink === undefined ?
                        null :
                        <h5 className="text-white">Silakan join group event <Badge style={{ cursor: 'pointer' }} onClick={() => props.accessGroupLink()} className="text-primary" color="light" pill>Join Group</Badge></h5>
                    }
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    onClick={() => (props.setIsOpen(!props.isOpen), navigation.push('/cari-event'))}
                    className="btn-white w-100" color="default" type="button">Kembali Cari Event
                </Button>
            </div>
        </Modal>
    );
};

export default SuccessRegister;
