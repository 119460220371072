import React from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import advertisement_1 from "../../../assets/img/theme/Advertisement-1.png";

const items = [
  {
    src: advertisement_1,
    altText: "Slide 1",
    caption: "Slide 1",
    header: "Slide 1 Header",
  },
];

const Advertisement = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        style={{
          borderRadius: "10px",
        }}
      >
        <img
          src={item.src}
          alt={item.altText}
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "100%",
            objectPosition: "center",
            objectFit: "cover",
          }}
        />
      </CarouselItem>
    );
  });
  return (
    <div
      style={{
        position: "relative",
        padding: "10px 20px",
        marginBottom: "30px",
        borderRadius: "10px",
      }}
    >
      <style>
        {`.custom-tag {
            max-width: 100%;
            height: 150px;
            border-radius: "10px",
            }
            .carousel-indicators {
                position: absolute;
                bottom: -20px;
                transform: translateY(100%);
            }
            .carousel-indicators li {
                border-radius: 100%;
                width: 10px;
                height: 10px;
                background-color: #ffffff;
                border: solid 2px #9870C5;
            }
            .carousel-indicators .active {
                background-color: #9870C5; 
            }`}
      </style>
      <Carousel
        activeIndex={activeIndex}
        // slide={false}
        interval={1000}
        next={next}
        previous={previous}
        style={{
          borderRadius: "10px",
        }}
      >
        {slides}
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
      </Carousel>
    </div>
  );
};

export default Advertisement;
