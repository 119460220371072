import img from "../../assets/img/theme/success.svg"

const SuccessImage = () => {
    return (
        <div className="d-flex align-items-center justify-content-center py-4">
            <img className="result-image" src={img} alt="hievents" />
        </div>
    )
}

export default SuccessImage