import Event from "components/Items/Event";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Container,
    Row,
} from "reactstrap";
import API from "utils/API";

const EventPopuler = ({ data }) => {
    const [event, setEvent] = useState([]);

    const navigate = useHistory()

    useEffect(() => {
        getEvent()
    }, [])

    const getEvent = () => {
        API.get('events/getEvents?' + data.url, {}).then(res => {
            // console.log(res.data.data)
            setEvent(res.data.data)
        }).catch(err => {
            console.log(err.response.data)
        })
    }

    if (event.length === 0)
        return null

    return (
        <>
            {/* Page content */}
            <Container className="mt-5" fluid>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <h2 className="ml-3 mr-auto color-hievent m-0">{data.title}</h2>
                    <h4 onClick={() => navigate.push("/events/popular=" + data.url.replace('eventTypeSlug=', ''))} style={{ cursor: 'pointer' }} className="bg-hievent rounded px-3 py-2 m-0 text-white">Lihat semua</h4>
                </div>
                <Row className="mt-3">
                    {event.map((item, index) =>
                        index < 6 ?
                            <Event item={item} xl="6" lg="6" md="6" key={item._id} />
                            : null
                    )}
                </Row>
            </Container>
        </>
    );
};

export default EventPopuler;
