import { useEffect, useState } from "react"
import Select from 'react-select';
import API from "utils/API"

const Cities = ({ cityId, setCityId, placeholder }) => {
    const [cities, setCities] = useState([])

    useEffect(() => {
        getCities()
    }, [])

    const getCities = () => {
        API.get('cities/getCities', {})
            .then(res => {
                // console.log(res)
                const temp = []
                res.data.data.forEach(element => {
                    temp.push(
                        { label: element.name, value: element._id }
                    )
                })
                setCities(temp)
            })
            .catch(err => {
                console.log("Error Cities : ", err.response);
            })
    }

    return (
        <Select
            styles={{
                menu: base => ({
                    ...base,
                    zIndex: 100
                })
            }}
            className='form-control p-0 h-100'
            defaultValue={cities.find(ell => ell.value === cityId)}
            value={cities.find(ell => ell.value === cityId)}
            isSearchable={true}
            options={cities}
            onChange={(event) => setCityId(event.value)}
            placeholder= {placeholder ? placeholder : "Kota domisili"}
        />
    )
}

export default Cities