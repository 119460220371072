/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import img from "../../assets/img/theme/event_private.png";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import { showToast } from "components/Partials/Toast";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const index = () => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");

  const useAuth = UseAuth();
  const navigate = useHistory();

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);

  useEffect(() => {
    input1Ref.current?.focus();
  }, []);

  const checkCode = () => {
    const code = input1 + input2 + input3 + input4 + input5;
    if (!useAuth.auth) return navigate.push("/auth");

    if (code === "" || code.length < 5)
      return showToast("Silakan masukkan kode event", "error");

    API.get("events/getEventByGeneratedEventCode/" + code, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        navigate.push("/event/" + res.data.data.generatedEventCode);
      })
      .catch((err) => {
        console.log(err.response.data);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onInputChange = (action, text) => {
    const val = text.substring(0, 1);
    if (action === 1) {
      setInput1(val);
      input2Ref.current.focus();
    } else if (action === 2) {
      setInput2(val);
      input3Ref.current.focus();
    } else if (action === 3) {
      setInput3(val);
      input4Ref.current.focus();
    } else if (action === 4) {
      setInput4(val);
      input4Ref.current.focus();
    } else if (action === 5) {
      setInput5(val);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 20px",
        paddingBottom: "20vh",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Button Kembali */}
      <div
        style={{
          width: "100%",
          padding: "20px 10px",
        }}
      >
        <Link
          style={{
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "4px",
          }}
          to="/"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#404040"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
            />
          </svg>
          <span
            style={{
              color: "#000000",
            }}
          >
            Event Private
          </span>
        </Link>
      </div>

      {/* Image */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          backgroundColor: "white",
        }}
      >
        <div
          className="py-3 text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <img className="result-image" src={img} alt="hievents" />
          </div>
          {/* <i className="ni ni-bell-55 ni-2x" /> */}
          <h4
            className="text-black my-4"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Masukkan kode Event Untuk Bergabung Pada Event Private
          </h4>
          {/* <p>Masukkan kode event yang kamu punya</p> */}
          <div className="d-flex flex-row">
            <Input
              ref={input1Ref}
              className="text-center"
              type="number"
              value={input1}
              onChange={(event) => onInputChange(1, event.target.value)}
            />
            <Input
              ref={input2Ref}
              className="mx-2 text-center"
              type="number"
              value={input2}
              onChange={(event) => onInputChange(2, event.target.value)}
            />
            <Input
              ref={input3Ref}
              className="text-center"
              type="number"
              value={input3}
              onChange={(event) => onInputChange(3, event.target.value)}
            />
            <Input
              ref={input4Ref}
              className="mx-2 text-center"
              type="number"
              value={input4}
              onChange={(event) => onInputChange(4, event.target.value)}
            />
            <Input
              ref={input5Ref}
              className="text-center"
              type="number"
              value={input5}
              onChange={(event) => onInputChange(5, event.target.value)}
            />
          </div>
          <button
            onClick={() => checkCode()}
            className="bg-hievent"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: "5px",
              color: "white",
              width: "100%",
              backgroundColor: "#9870C5",
              height: "40px",
              borderRadius: "5px",
              fonwWeight: 600,
              border: "none",
            }}
          >
            Lanjut
          </button>
        </div>
      </div>
      <UserBottomBarMobile />
    </div>
  );
};

export default index;
