import { Helmet } from "react-helmet"

const MetaTags = ({ url, title, image, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description} />
            
            {/* Facebook, Whatsapp meta tags */}
            <meta
                name="og:url"
                content={url} />
            <meta
                name="og:title"
                content={title} />
            <meta
                name="og:description"
                content={description} />
            <meta
                name="og:image"
                content={image} />
            <meta
                name="og:image:width"
                content="100" />
            <meta
                name="og:image:height"
                content="100" />
                
            {/* Twitter meta tags */}
            <meta
                name="twitter:url"
                content={url} />
            <meta
                name="twitter:title"
                content={title} />
            <meta
                name="twitter:description"
                content={description} />
            <meta
                name="twitter:image"
                content={image} />
            <meta
                name="twitter:image:width"
                content="100" />
            <meta
                name="twitter:image:height"
                content="100" />
        </Helmet>
    )
}

export default MetaTags