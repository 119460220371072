import {
  Badge,
  Button,
  Modal,
  InputGroupAddon,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { formatToIDR } from "utils/helpers";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import SuccessIcon from "components/Partials/SuccessIcon";
import moment from "moment";
import ErrorIcon from "components/Partials/ErrorIcon";
const WithdrawResult = ({ props }) => {
  const navigation = useHistory();
  return (
    <Modal
      size="lg"
      className="modal-dialog-centered modal-info"
      contentClassName="bg-white"
      isOpen={props.isOpen}
      toggle={() => {
        props.setIsWithdrawSuccess(null);
        props.setIsOpen(!props.isOpen);
      }}
    >
      {!props.isWithdrawSuccess && (
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              props.setIsWithdrawSuccess(null);
              props.setIsOpen(!props.isOpen);
            }}
          >
            <span
              aria-hidden={true}
              style={{
                color: "black",
              }}
            >
              ×
            </span>
          </button>
        </div>
      )}
      <div className="modal-body px-4 w-100">
        <div
          style={{
            width: "100%",
            overflowX: "hidden",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "30px 30px",
            gap: "15px",
          }}
        >
          {props.isWithdrawSuccess ? <SuccessIcon /> : <ErrorIcon />}
          {props.isWithdrawSuccess ? (
            <h2
              style={{
                color: "#707070",
                fontWeight: 700,
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              Selamat Kamu Berhasil Melakukan Penarikan Saldo !
            </h2>
          ) : (
            <h2
              style={{
                color: "#707070",
                fontWeight: 700,
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              Maaf, Penarikan Gagal
              <br />
              Silahkan Coba Lagi
            </h2>
          )}
          <div
            style={{
              width: "65%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px",
                borderTop: "solid 1px #C4C4C4",
                borderBottom: "solid 1px #C4C4C4",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <small style={{ color: "#000000" }} className="text-sm">
                  Nama
                </small>
              </div>
              <small style={{ color: "#737373" }} className="text-sm">
                {props?.withdraw.user.name}
              </small>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px",
                borderBottom: "solid 1px #C4C4C4",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <small style={{ color: "#000000" }} className="text-sm">
                  Keterangan
                </small>
              </div>
              <small style={{ color: "#737373" }} className="text-sm">
                {`PAYOUT | ${props?.withdraw.bank.name} | ${props?.withdraw.bankAccount.accountNumber}`}
              </small>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px",
                borderBottom: "solid 1px #C4C4C4",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <small style={{ color: "#000000" }} className="text-sm">
                  Jumlah
                </small>
              </div>
              <small style={{ color: "#737373" }} className="text-sm">
                {formatToIDR(props?.withdraw.totalAmount)}
              </small>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px",
                borderBottom: "solid 1px #C4C4C4",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <small style={{ color: "#000000" }} className="text-sm">
                  Tanggal
                </small>
              </div>
              <small style={{ color: "#737373" }} className="text-sm">
                {moment(props?.withdraw.createdAt).format(
                  "DD MMMM YYYY - HH : mm"
                )}
              </small>
            </div>
          </div>
          {props.isWithdrawSuccess ? (
            <small style={{ color: "#000000" }} className="text-sm text-center">
              Untuk melihat detail penarikan saldo, silahkan masuk
              <br />
              ke menu{" "}
              <Link style={{ color: "#8E82C0", fontWeight: "600" }}>
                Saldo keluar
              </Link>
            </small>
          ) : null}

          <div style={{ width: "30%", marginTop: "20px" }}>
            <Button
              onClick={() => navigation.replace("/event-organizer")}
              className=" bg-hievent w-100 text-white"
              color="bg-hievent"
            >
              Kembali
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawResult;
