const ErrorIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="92.8577"
        cy="15.6443"
        rx="7.14286"
        ry="7.66871"
        fill="#FC0000"
      />
      <ellipse
        cx="78.0006"
        cy="4.60123"
        rx="4.28571"
        ry="4.60123"
        fill="#FC0000"
      />
      <ellipse
        cx="7.14314"
        cy="53.0675"
        rx="4.28571"
        ry="4.60123"
        fill="#FC0000"
      />
      <ellipse
        cx="7.14286"
        cy="72.6995"
        rx="7.14286"
        ry="7.66871"
        fill="#FC0000"
      />
      <ellipse
        cx="67.7134"
        cy="95.3986"
        rx="4.28571"
        ry="4.60123"
        fill="#FC0000"
      />
      <ellipse
        cx="53.7154"
        cy="48.4661"
        rx="34.2857"
        ry="36.8098"
        fill="#FC0000"
      />
      <path
        d="M43.1744 63.9356C41.7619 65.3548 39.4718 65.3548 38.0594 63.9356C36.6469 62.5164 36.6469 60.2153 38.0594 58.7961L48.8849 47.9188L38.2209 37.2039C36.8085 35.7847 36.8085 33.4836 38.2209 32.0644C39.6334 30.6452 41.9235 30.6452 43.336 32.0644L54 42.7793L64.664 32.0644C66.0765 30.6452 68.3666 30.6452 69.7791 32.0644C71.1915 33.4836 71.1915 35.7847 69.7791 37.2039L59.1151 47.9188L69.9406 58.7961C71.3531 60.2153 71.3531 62.5164 69.9406 63.9356C68.5282 65.3548 66.2381 65.3548 64.8256 63.9356L54 53.0583L43.1744 63.9356Z"
        fill="white"
      />
    </svg>
  );
};

export default ErrorIcon;
