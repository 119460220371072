import { useState } from "react"
import { Col, Row } from "reactstrap"

const EventLocTypes = ({ eventLocTypes, setEventLocTypes, col = "4", multiple = true }) => {
    const [eventLocs, setEventLocs] = useState([
        { _id: 'online', name: 'Online' },
        { _id: 'offline', name: 'Offline' },
    ])

    const onChange = (value) => {
        const temp = [...eventLocTypes]
        const index = temp.findIndex(val => { return val === value })
        if (index === -1)
            temp.push(value)
        else
            temp.splice(index, 1)

        if (multiple)
            setEventLocTypes(temp)
        else {
            if (index === -1)
                setEventLocTypes([value])
            else
                setEventLocTypes([])
        }
    }

    return (
        <Row>
            {eventLocs.map(element =>
                <Col key={element._id} className={col === "4" ? "my-2" : ""} md={col}>
                    <div className="custom-control custom-checkbox mb-1">
                        <input
                            className="custom-control-input"
                            id={element._id}
                            type="checkbox"
                            value={element._id}
                            checked={eventLocTypes.findIndex(val => { return val === element._id }) !== -1 ? true : false}
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <label className="custom-control-label" htmlFor={element._id}>
                            {element.name}
                        </label>
                    </div>
                </Col>
            )}
        </Row>
    )
}

export default EventLocTypes