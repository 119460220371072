import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";

const Header = ({ USER, EO }) => {

  const navigation = useHistory()

  return (
    <>
      <div className="header bg-dark pb-8 pt-9" style={{ backgroundImage: `url(${require("../../assets/img/theme/bg_header.png")})` }}>
        <div className="bg-dark position-absolute bottom-0 left-0 right-0 top-0" style={{ opacity: 0.8 }} />
        <Container fluid>
          <div className="header-body px-3 p-lg-0">
            {/* Card stats */}
            {/* style={{ fontSize: '5vw' }} */}
            <Row>
              <Col lg="1" className="d-flex flex-column">
              </Col>
              <Col lg="5" className="d-flex flex-column">
                <h1 className="text-white display-3">Tempatnya event seru dan menarik.</h1>
                <h1 className="text-white display-3">Daftar dan iklankan event.</h1>
                <h1 className="color-hievent display-2">Gratis 100%</h1>
                <Button className="mt-4 w-50 text-white bg-hievent" color="bg-hievent" type="button"
                  onClick={() => !USER._id ? navigation.push('/auth') : EO._id ? navigation.push("/event-organizer") : navigation.push("/register-event-organizer")}>
                  Buat event kamu sekarang
                </Button>
              </Col>
              <Col lg="6" className="d-flex mt-5 mt-lg-0 align-items-right justify-content-center">
                <img style={{ height: 360, width: '100%' }} className="logo-eo" src={require("../../assets/img/theme/header.svg").default} alt="hievents" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: 'SET_USER', value: value }),
    SET_EO: (value) => dispatch({ type: 'SET_EO', value: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
