import { useEffect, useState } from "react";
import Select from "react-select";
import API from "utils/API";
import { showToast } from "./Toast";

const Banks = ({ bankId, setBankId, placeholder }) => {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = () => {
    API.get("/master/banks")
      .then((res) => {
        setBanks(res.data.data);
      })
      .catch((err) => {
        showToast(err.response.data.message, "error");
      });
  };

  return (
    <Select
      styles={{
        menu: (base) => ({
          ...base,
          zIndex: 100,
        }),
      }}
      className="form-control p-0 h-100"
      defaultValue={banks.find((ell) => ell._id === bankId)}
      value={banks.find((ell) => ell._id === bankId)}
      isSearchable={true}
      options={banks}
      onChange={(event) => setBankId(event._id)}
      placeholder={placeholder ? placeholder : "Pilih bank"}
    />
  );
};

export default Banks;
