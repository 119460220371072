const SuccessIcon = () => {
  return (
    <div>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="53.7154"
          cy="48.4661"
          rx="34.2857"
          ry="36.8098"
          fill="#5BE462"
        />
        <path
          d="M68.0011 38.0366L50.8583 58.282L39.4297 47.2391"
          stroke="white"
          stroke-width="10"
          stroke-linecap="round"
        />
        <ellipse
          cx="92.8577"
          cy="15.6443"
          rx="7.14286"
          ry="7.66871"
          fill="#5BE462"
        />
        <ellipse
          cx="78.0006"
          cy="4.60123"
          rx="4.28571"
          ry="4.60123"
          fill="#5BE462"
        />
        <ellipse
          cx="7.14314"
          cy="53.0675"
          rx="4.28571"
          ry="4.60123"
          fill="#5BE462"
        />
        <ellipse
          cx="7.14286"
          cy="72.6995"
          rx="7.14286"
          ry="7.66871"
          fill="#5BE462"
        />
        <ellipse
          cx="67.7134"
          cy="95.3986"
          rx="4.28571"
          ry="4.60123"
          fill="#5BE462"
        />
      </svg>
    </div>
  );
};

export default SuccessIcon;
