import React from "react";
import { BsChatSquareTextFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const FloatingButtonFAQ = () => {
  return (
    <div
      className="position-fixed"
      style={{
        bottom: 100,
        right: 20,
        zIndex: 1000,
      }}
    >
      <Link to="/faq">
        <Button
          className="d-flex align-items-center p-2 p-md-3 gap-2 gap-md-3"
          style={{
            backgroundColor: "#FECA13",
            border: "none",
          }}
        >
          <BsChatSquareTextFill size={30} />
          <span className="d-md-block">Bantuan</span>
        </Button>
      </Link>
    </div>
  );
};

export default FloatingButtonFAQ;
