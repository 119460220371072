import {
  Badge,
  Button,
  Modal,
  InputGroupAddon,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import { useState, useEffect } from "react";
import { showToast } from "components/Partials/Toast";
import Banks from "components/Partials/Banks";
import API from "utils/API";
const SetUpBankAccount = ({ props }) => {
  const [bank, setBank] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const useAuth = UseAuth();

  const onCreateBankAccount = () => {
    setLoading(true);
    const data = {
      accountName: name,
      accountNumber: bankNumber,
      bankId: bank,
    };
    API.post("/bankAccount/createBankAccount", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        setLoading(false);
        props.setBankAccount(res.data.data);
        setBank("");
        setBankNumber("");
        setName("");
        showToast(res.data.message, res.data.status.toLowerCase());
        props.setIsOpen(!props.isOpen);
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onEditBankAccount = () => {
    setLoading(true);
    const data = {
      accountName: name,
      accountNumber: bankNumber,
      bankId: bank,
    };
    API.put("/bankAccount/updateBankAccount", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        props.setBankAccount(res.data.data);
        setBank("");
        setBankNumber("");
        setName("");
        setLoading(false);
        // setEventOrganizer(res.data.data);
        showToast(res.data.message, res.data.status.toLowerCase());
        props.setIsOpen(!props.isOpen);
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
        console.log(err.response.data);
      });
  };

  const handleClick = () => {
    if (props.bankAccount === null) {
      onCreateBankAccount();
    } else {
      onEditBankAccount();
    }
  };

  return (
    <Modal
      className="modal-dialog-centered modal-info"
      contentClassName="bg-white"
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setIsOpen(!props.isOpen)}
        >
          <span
            aria-hidden={true}
            style={{
              color: "black",
            }}
          >
            ×
          </span>
        </button>
      </div>
      <h2
        style={{
          color: "#000000",
          fontWeight: 700,
          textAlign: "center",
          fontSize: "24px",
        }}
      >
        Atur rekening
      </h2>
      <div className="modal-body p-0">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 30px",
            gap: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                width: "100%",
                textAlign: "left",
                color: "black",
                fontWeight: "500",
              }}
              className="mb-0 text-black"
            >
              Pilih bank anda
            </p>
            <InputGroup
              style={{ width: "100%" }}
              className="input-group-alternative bg-white"
            >
              <InputGroupAddon addonType="prepend"></InputGroupAddon>
              <Banks bankId={bank} setBankId={setBank} />
            </InputGroup>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                width: "100%",
                textAlign: "left",
                color: "black",
                fontWeight: "500",
              }}
              className="mb-0 text-black"
            >
              No rekening anda
            </p>
            <Input
              style={{
                width: "100%",
                color: "#737373",
              }}
              value={bankNumber}
              onChange={(e) => {
                const inputValue = e.target.value;
                const validate = inputValue.replace(/[^0-9]/g, "");
                setBankNumber(validate);
              }}
              type="text"
              placeholder="Masukkan no rekening anda"
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                width: "100%",
                textAlign: "left",
                color: "black",
                fontWeight: "500",
              }}
              className="mb-0 text-black"
            >
              Atas nama
            </p>
            <Input
              style={{
                width: "100%",
                color: "#737373",
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Masukkan nama anda"
            />
          </div>
          <Button
            className=" bg-hievent"
            color="bg-hievent"
            disabled={bank === "" || name === "" || bankNumber === ""}
            onClick={() => handleClick()}
            style={{
              width: "100%",
              border: "solid 1px black",
              color: "white",
              marginTop: "20px",
            }}
          >
            {loading ? <Spinner size="sm" /> : "Simpan"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SetUpBankAccount;
