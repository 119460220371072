import { useState } from "react";
import { Modal } from "reactstrap";
import SuccessImage from "./SuccessImage";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const EventShare = ({ event }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span
        onClick={() => setIsOpen(!isOpen)}
        className="ml-1 ml-sm-auto color-hievent mr-1 mr-sm-3"
        style={{ cursor: "pointer" }}
      >
        <i className="fa fa-share fa-lg" /> Bagikan
      </span>
      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-purple"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title">
            Bagikan event ke ke platform media sosial kamu
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="text-dark" aria-hidden={true}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <SuccessImage />
            <div>
              Bagikan event ke rekan, teman atau keluargamu melalui platform
              media sosial yang tersedia dibawah.
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center pb-5 px-3">
          <WhatsappShareButton
            title={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <WhatsappIcon size={42} />
          </WhatsappShareButton>

          <FacebookShareButton
            quote={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <FacebookIcon size={42} />
          </FacebookShareButton>

          <LineShareButton
            title={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <LineIcon size={42} />
          </LineShareButton>

          <TelegramShareButton
            title={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <TelegramIcon size={42} />
          </TelegramShareButton>

          <TwitterShareButton
            title={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <TwitterIcon size={42} />
          </TwitterShareButton>

          <LinkedinShareButton
            title={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <LinkedinIcon size={42} />
          </LinkedinShareButton>

          <EmailShareButton
            subject={event.title}
            url={process.env.REACT_APP_currentURL + "/event/" + event.slug}
          >
            <EmailIcon size={42} />
          </EmailShareButton>
        </div>
      </Modal>
    </>
  );
};

export default EventShare;
