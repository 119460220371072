import SuccessImage from "components/Partials/SuccessImage";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Spinner
} from "reactstrap";
import API from "utils/API";

const Activation = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})

    const navigate = useHistory()
    const code = new URLSearchParams(useLocation().search).get("code")

    useEffect(() => {
        onActivation()
    }, [])

    const onActivation = () => {
        setLoading(true)
        const data = {
            code: code,
        }

        API.post('auths/confirmEmail', data)
            .then(res => {
                // console.log(res)
                setLoading(false)
                setMessage(res.data)
            })
            .catch(err => {
                console.log(err.response)
                setLoading(false)
                setMessage(err.response.data)
            })
    }

    return (
        <>
            <Col xl="9" lg="10" md="11">
                <Card className="bg-secondary shadow border-0 my-0 my-lg-8">
                    <CardBody className="d-flex flex-column px-lg-5 py-lg-5 align-items-center">
                        <div className="text-center text-muted mb-4">
                            <h1>Aktivasi Akun</h1>
                            <div>Waktunya untuk kamu bergabung dengan event-event seru</div>
                        </div>

                        {!message.code ?
                            <Spinner color="primary" size="lg" />
                            : null}

                        {message.code === 200 ?
                            <>
                                <SuccessImage />
                                <h3 className="text-primary">{message.message}</h3>
                            </>
                            : null}

                        {message.code === 200 ?
                            <Button
                                onClick={() => navigate.replace('/auth')}
                                className="my-4 w-100 text-white bg-hievent" color="bg-hievent" type="button">
                                Masuk
                            </Button>
                            : null}

                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6"></Col>
                    <Col className="text-right" xs="6">
                        <Link to="/auth/register">
                            <small>Daftar akun baru</small>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Activation;
