import "../../assets/style/contact-us.css";
import { useMediaQuery } from "react-responsive";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";

const ContactCard = ({content}) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 640 });
    return (
        <div className=" cardcontact">
            <div className="iconcontact">
                <content.icon size={ isSmallScreen ? 20 : 30} color={"#ffffff"}/>
            </div>
            <div className="text-container">
                <h2 className="subtitle">{content.subTitle}</h2>
                <p className="content">{content.content}</p>
            </div>
            {isSmallScreen ? (<UserBottomBarMobile />) : null}
            
        </div>
    );
}

export default ContactCard;