import React from "react";
import { connect } from "react-redux";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Row, Col } from "reactstrap";

import routes from "../routes/routes.js";
import { useMediaQuery } from "react-responsive";
import FloatingButtonFAQ from "components/Partials/FloatingButtonFAQ.js";

const Auth = ({ USER }) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const navigation = useHistory();

  const isMdScrenn = useMediaQuery({ query: "(max-width: 768px)" });

  const clearAllCookies = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      // console.log("Cookie : ", name);
    }
  };

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    clearAllCookies();
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
        className="main-content"
        ref={mainContent}
        style={{
          height: "100%",
        }}
      >
        <FloatingButtonFAQ />
        <div className="bg-white">
          {isMdScrenn ? (
            <Row className="justify-content-center m-0">
              <Col
                className="py-6 py-md-9"
                xl="6"
                lg="7"
                md="12"
                sm="12"
                style={{
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Container className="pb-5">
                  <Row className="justify-content-center">
                    <Switch>
                      {getRoutes(routes)}
                      {USER._id ? (
                        <Redirect from="*" to="/" />
                      ) : (
                        <Redirect from="*" to="/auth/login" />
                      )}
                    </Switch>
                  </Row>
                </Container>
              </Col>
            </Row>
          ) : (
            <Row className="justify-content-center m-0">
              <Col
                className="bg-gradient-primary py-6 py-sm-9 py-xl-8"
                xl="6"
                lg="5"
                md="12"
                sm="12"
              >
                <Container className="d-flex justify-content-center">
                  <Col xl="8">
                    <img
                      onClick={() => navigation.push("/")}
                      role="button"
                      className="ml--2"
                      width={200}
                      src={require("../assets/img/brand/brand.png")}
                      alt="hievents"
                    />
                    <h1 className="display-2 text-white mt-3">
                      Let's join to find the event you like
                    </h1>
                    <p className="text-lead text-light">
                      Find various event ranging from seminars, competitions,
                      concerts, parties and more
                    </p>
                  </Col>
                </Container>
              </Col>

              <Col className="py-6 py-md-9" xl="6" lg="7" md="12" sm="12">
                <Container className="pb-5">
                  <Row className="justify-content-center">
                    <Switch>
                      {getRoutes(routes)}
                      {USER._id ? (
                        <Redirect from="*" to="/" />
                      ) : (
                        <Redirect from="*" to="/auth/login" />
                      )}
                    </Switch>
                  </Row>
                </Container>
              </Col>
            </Row>
          )}
        </div>
      </div>

      <ToastContainer />

      {/* <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-primary py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  <p className="text-lead text-light">
                    Use these awesome forms to login or create new account in
                    your project for free.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div> 
      <AuthFooter /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
