import "../../assets/style/prerequisite.css";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Input, Button, Row, Col, Spinner } from "reactstrap";
import API from "utils/API";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";
import SuccessRegister from "components/Modals/SuccessRegister";
import PaidProgress from "components/Modals/PaidProgress";
const Prasyarat = () => {
  const useAuth = UseAuth();
  const navigation = useHistory();
  const defaultImage = require("../../assets/img/theme/upload.svg").default;
  const location = useLocation();
  const havePrerequisite = true;
  const { prerequisite, eventId, event } = location.state;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [indexChoise, setIndexChoise] = useState(null);
  const [showPaidProgress, setShowPaidProgress] = useState(false);
  const [prerequisiteId, setPrerequisiteId] = useState(() => {
    const newPrequisiteId = prerequisite
      .filter((item) => item.prerequisiteType !== "file_upload")
      .map((item) => item._id);

    const newPrequisiteIdFile = prerequisite
      .filter((item) => item.prerequisiteType === "file_upload")
      .map((item) => item._id);

    const result = [...newPrequisiteId, ...newPrequisiteIdFile];
    return result;
  });
  const [answer, setAnswer] = useState(
    prerequisite
      .filter((item) => item.prerequisiteType !== "file_upload")
      .map((item) => {
        if (item.prerequisiteType === "checkbox") {
          return [];
        }
        return "";
      })
  );
  const [image, setImage] = useState(
    prerequisite
      .filter((item) => item.prerequisiteType === "file_upload")
      .map(() => defaultImage)
  );

  const prerequisiteIdFile = prerequisite
    .filter((item) => item.prerequisiteType === "file_upload")
    .map((item) => item._id);

  const updateImage = (index, image) => {
    // setLoading(true);
    setImage((arr) => {
      const newArr = [...arr];
      newArr[index] = image;
      return newArr;
    });
  };

  const handleChoice = (i, j, choice) => {
    setAnswer((prevAnswers) => {
      const newArr = [...prevAnswers];

      if (prerequisite[i].prerequisiteType === "multiple_choice") {
        newArr[i] = choice;
      } else if (prerequisite[i].prerequisiteType === "checkbox") {
        if (!Array.isArray(newArr[i])) {
          newArr[i] = [];
        }

        if (newArr[i].includes(choice)) {
          newArr[i] = newArr[i].filter((item) => item !== choice);
        } else {
          newArr[i].push(choice);
        }
      }

      return newArr;
    });
    setIndexChoise(j);
  };

  const isFormValid = () => {
    const isAnswerComplete = answer.every((ans, index) => {
      if (prerequisite[index].prerequisiteType === "checkbox") {
        return ans.length > 0;
      }
      return ans !== "";
    });

    const hasFileUpload = prerequisite.some(
      (item) => item.prerequisiteType === "file_upload"
    );

    // Validasi gambar hanya jika ada prerequisite bertipe file_upload
    const isImageComplete =
      !hasFileUpload || image.every((img) => img !== defaultImage);

    if (isAnswerComplete && isImageComplete) {
      if (event.paidStatus === "paid") {
        setIsOpen(true);
        setShowPaidProgress(true);
      } else {
        handleSubmit();
      }
    } else {
      showToast("Form Tidak Boleh Kosong", "error");
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    const data = new FormData();
    if (prerequisiteId)
      prerequisiteId.forEach((item, index) => {
        data.append(`prerequisiteId[]`, item);
      });

    if (answer.length > 0)
      answer.forEach((item, index) => {
        data.append(`answer[]`, item);
      });
    else data.append("answer[]", []);

    const files = image.filter((img) => img !== defaultImage);
    if (files.length > 0) {
      files.forEach((item, index) => {
        data.append(`prerequisiteAnswerFile[]`, item[0]);
      });
    }

    API.post(`/prerequisite/createPrerequisiteAnswer/${eventId}`, data, {
      headers: {
        Authorization: "Bearer " + useAuth.auth.accessToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        registerEvent();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const registerEvent = () => {
    setLoading(true);
    if (!useAuth.auth) return navigation.push("/auth");

    const data = {
      eventId: eventId,
    };
    API.post("eventRegistrations/registerEvent", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        setLoading(false);
        setIsOpen(true);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const accessGroupLink = () => {
    if (event.groupLink.search("https") === 0)
      window.open(event.groupLink, "_blank");
    else window.open("https://" + event.groupLink, "_blank");
  };

  useEffect(() => {
    if (!useAuth.auth) return navigation.push("/auth");
  }, []);

  return (
    <div className="container-page-prerequisite">
      <div
        style={{
          width: "100%",
          padding: "0vw 2.5vw",
        }}
      >
        <button
          className="color-hievent"
          style={{
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "fit-content",
            padding: 10,
          }}
          onClick={() => navigation.goBack()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
              fill="#9870C5"
            />
          </svg>
          Kembali
        </button>
      </div>
      {showPaidProgress && (
        <PaidProgress
          props={{ isOpen, setIsOpen, event, handleSubmit, havePrerequisite }}
        />
      )}
      <h1 className="header">Silahkan Mengisi prasyarat event</h1>
      <div className="container-prerequisite">
        {prerequisite.map((item, i) => {
          return (
            <div className="item-prerequisite">
              <h1
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "left",
                }}
              >
                {item.title}
              </h1>
              {item.prerequisiteType === "short_answer" && (
                <Input
                  value={answer[i]}
                  onChange={(e) =>
                    setAnswer((arr) => {
                      const newArr = [...arr];
                      newArr[i] = e.target.value;
                      return newArr;
                    })
                  }
                  type="text"
                  placeholder="Short answer"
                />
              )}
              {item.prerequisiteType === "paragraf" && (
                <Input
                  style={{
                    height: "15vh",
                  }}
                  type="textarea"
                  placeholder="Paragraf"
                  value={answer[i]}
                  onChange={(e) =>
                    setAnswer((arr) => {
                      const newArr = [...arr];
                      newArr[i] = e.target.value;
                      return newArr;
                    })
                  }
                />
              )}

              {(item.prerequisiteType === "multiple_choice" ||
                item.prerequisiteType === "checkbox") &&
                item.choiceAnswer.map((choice, j) => (
                  <div
                    onClick={() => handleChoice(i, j, choice)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      paddingBottom: "14px",
                    }}
                    key={j}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius:
                          item.prerequisiteType === "multiple_choice"
                            ? "100%"
                            : "0%",
                        border: "solid 1px #cad1d7",
                        backgroundColor: answer[i]?.includes(choice)
                          ? "#9870C5"
                          : "#ffffff",
                      }}
                    ></div>
                    <h1
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        width: "95%",
                        margin: "0px",
                        textAlign: "left",
                      }}
                    >
                      {choice}
                    </h1>
                  </div>
                ))}

              {item.prerequisiteType === "file_upload" &&
                prerequisiteIdFile.map((prerequisiteFile, k) => (
                  <Col md="12">
                    <Row>
                      <Col md="4">
                        <img
                          className="img-fluid w-100 h-100 rounded-lg"
                          src={
                            typeof image[k] === "object"
                              ? URL.createObjectURL(image[k][0])
                              : image[k]
                          }
                          alt="hievents"
                        />
                      </Col>

                      <Col md="4">
                        <div className="d-flex flex-column p-3">
                          <span className="font-weight-bold">
                            Besar file maksimum
                          </span>
                          <span>10MB</span>

                          <span className="font-weight-bold mt-2">
                            Type file
                          </span>
                          <span>PNG, JPG, JPEG</span>
                        </div>

                        <label
                          htmlFor="browse-image"
                          className="btn btn-sm p-2 btn-primary mt-4"
                        >
                          {loading ? <Spinner size="sm" /> : null} Browse File
                        </label>
                        <input
                          hidden
                          type="file"
                          accept="image/png, image/jpeg"
                          id="browse-image"
                          className="form-control"
                          name="prerequisiteAnswerFile"
                          onChange={(e) => updateImage(k, e.target.files)}
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "30%",
          marginTop: "5vh",
        }}
      >
        <Button
          style={{
            width: "100%",
          }}
          onClick={() => isFormValid()}
          className="btn btn-md text-white bg-hievent"
          color="bg-hievent"
        >
          {loading ? <Spinner size="sm" /> : "Submit"}
        </Button>
        {!showPaidProgress && (
          <SuccessRegister
            props={{ isOpen, setIsOpen, event, accessGroupLink }}
          />
        )}
      </div>
    </div>
  );
};

export default Prasyarat;
