import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import API from "utils/API"

const EventBenefits = ({ benefitIds, setBenefitIds }) => {
    const [benefits, seBenefits] = useState([])

    useEffect(() => {
        getBenefits()
    }, [])

    const getBenefits = () => {
        API.get('benefits/getBenefits', {})
            .then(res => {
                // console.log(res.data.data)
                seBenefits(res.data.data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const onChange = (value) => {
        const temp = [...benefitIds]
        const index = temp.findIndex(val => {return val === value})
        if (index === -1)
            temp.push(value)
        else
            temp.splice(index, 1)
        setBenefitIds(temp)
    }

    return (
        <Row>
            {benefits.map(element =>
                <Col key={element._id} className="my-2" md="4">
                    <div className="custom-control custom-checkbox mb-1">
                        <input
                            className="custom-control-input"
                            id={element._id}
                            type="checkbox"
                            value={element._id}
                            defaultChecked={benefitIds.findIndex(val => {return val === element._id}) !== -1 ? true : false }
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <label className="custom-control-label" htmlFor={element._id}>
                            {element.name}
                        </label>
                    </div>
                </Col>
            )}
        </Row>
    )
}

export default EventBenefits