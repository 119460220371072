import React, { useEffect } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { MdOutlineHome, MdSearch } from "react-icons/md";
import { IoMdCalendar } from "react-icons/io";
import { FaRegUser, FaUsers } from "react-icons/fa6";
import { BsCalendarPlus } from "react-icons/bs";

import "./UserBottomBarMobile.css";
import { UseAuth } from "routes/useAuth";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import API from "utils/API";
import login from "../../assets/img/theme/login-white.svg";

const UserBottomBarMobile = ({ USER, SET_USER, EO, SET_EO }) => {
  const navigate = useHistory();
  const useAuth = UseAuth();

  const pathname = window.location.pathname.split("/")[1];

  useEffect(() => {
    getEventOrganizer();
  }, []);

  const getEventOrganizer = () => {
    if (useAuth.auth) {
      API.get("eventOrganizers/getEventOrganizer", {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      }).then((res) => {
        // console.log(res.data.data)
        SET_EO(res.data.data);
      });
    }
    // .catch(err => {
    //   console.log(err.response.data)
    // })
  };

  let imgUser = login;
  if (useAuth.auth) {
    if (USER._id) {
      if (USER.photo) {
        imgUser = USER.photo.url;
      } else {
        imgUser = null;
      }
    }
  }

  return (
    <div className="main-bottom-bar">
      <div
        className="button-bottom-bar"
        style={{
          color: pathname === "" ? "#9870C5" : "#C4C4C4",
        }}
        onClick={() => navigate.push("/")}
      >
        <MdOutlineHome size={20} />
        <span className="text">Beranda</span>
      </div>

      {/* Icon Search */}
      <div
        className="button-bottom-bar"
        onClick={() => navigate.push("/cari-event")}
        style={{
          color: pathname.includes("cari-event") ? "#9870C5" : "#C4C4C4",
        }}
      >
        <MdSearch size={20} />
        <span className="text">CariEvent</span>
      </div>
      <div
        className="button-bottom-bar"
        onClick={() => {
          !useAuth.auth
            ? navigate.push("/auth")
            : EO._id
            ? navigate.push("/buat-event")
            : navigate.push("/register-event-organizer");
        }}
      >
        <div className="bg-hievent center-button-bottom-bar mb-6">
          {EO._id ? (
            <BsCalendarPlus size={20} color="white" />
          ) : (
            <FaUsers size={20} color="white" />
          )}
        </div>
        <span
          className="text"
          style={{
            marginTop: "40%",
            color:
              pathname.includes("buat-event") ||
              pathname.includes("register-event-organizer")
                ? "#9870C5"
                : "#C4C4C4",
          }}
        >
          {useAuth.auth ? (!EO._id ? "Daftar EO" : "Buat Event") : "Masuk"}
        </span>
      </div>
      <div
        className="button-bottom-bar"
        onClick={() => {
          useAuth.auth
            ? navigate.push("/event-terdaftar")
            : navigate.push("/auth/login");
        }}
        style={{
          color: pathname.includes("event-terdaftar") ? "#9870C5" : "#C4C4C4",
        }}
      >
        <IoMdCalendar size={20} />
        <span className="text">EventTerdaftar</span>
      </div>
      <div
        className="button-bottom-bar"
        onClick={() => {
          useAuth.auth
            ? navigate.push("/profil")
            : navigate.push("/auth/login");
        }}
        style={{
          color: pathname.includes("profil") ? "#9870C5" : "#C4C4C4",
        }}
      >
        {USER.name ? (
          <Avatar
            className={USER.photo ? "logo-eo bg-white" : "logo-eo bg-hievent"}
            name={USER.name ? USER.name : null}
            round={USER.name ? true : false}
            src={imgUser}
            size={USER.name ? "20" : "20"}
          />
        ) : (
          <FaRegUser />
        )}
        <span className="text">Profil</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    FOLLOWING: state.followingReducer.followingState,
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBottomBarMobile);
