// import Breadcumbs from "components/Partials/Breadcumbs";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col, Media, Container } from "reactstrap";

const Item = ({ item, xl, lg, md }) => {
  const navigate = useHistory();

  return (
    <Col className="d-flex flex-row" style={{ background: "white" }}>
      <div key={item._id} className="d-flex align-items-center ">
        <Avatar
          size="60"
          src={item.eventOrganizerId?.logo?.url}
          name={item.eventOrganizerId?.name}
          round={true}
        />
        <Col className="ml-1">
          <Media className="mt-1">
            <span className="mb-0 text-sm font-weight-bold">
              {item.eventOrganizerId?.name}
            </span>
          </Media>
        <button
            onClick={() =>
              navigate.push("/event-organizer/" + item.eventOrganizerId?.slug)
            }
            className="text-sm font-weight-bold mt-0 btn btn-transparent"
            style={{ color: "black", border: "none", background: "none", outline: "none" }}
          >
            Lihat Profil
        </button>

        </Col>
      </div>
    </Col>
  );
};

const FollowingList = ({ FOLLOWING }) => {
  return (
    <>
      {/* Page content */}
      <Container className="pt-0 pl-0 pr-1">
        {/* <h2 className="color-hievent">Event Organizer yang diikuti</h2> */}
        {/* <Breadcumbs data={['Following']} /> */}
        <Row className="p-0">
          {FOLLOWING.map((item, index) => (
            <Col
              xl="3"
              lg="3"
              md="4"
              key={item._id}
              style={{ marginBottom: "1rem" }}
            >
              {" "}
              {/* Menggunakan style untuk memberikan margin bottom */}
              <Item item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    FOLLOWING: state.followingReducer.followingState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowingList);
