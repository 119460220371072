import {
    DropdownItem
} from "reactstrap";

import moment from "moment";

const Notifikasi = ({item}) => {
    return (
        <div className="dropdown-menu-arrow">
            <DropdownItem divider />
            <div className="d-flex flex-row align-items-center py-2">
                <i className="ni ni-check-bold" />
                <div className="d-flex flex-column ml-3 mr-auto">
                    <span>{item.title}</span>
                    <small>{item.description}</small>
                </div>
                <small className="text-gray">{moment(item.createdAt).format('D MMM')}</small>
            </div>
            {/* <DropdownItem divider /> */}
        </div>
    );
};

export default Notifikasi;
